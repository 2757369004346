import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
	EmailAuthProvider,
	browserLocalPersistence,
	createUserWithEmailAndPassword,
	reauthenticateWithCredential,
	setPersistence,
	signInWithEmailAndPassword,
	signOut,
	updateEmail,
	updatePassword,
} from "firebase/auth";
import {
	addDoc,
	collection,
	doc,
	getDoc,
	query,
	setDoc,
	updateDoc,
	where,
} from "firebase/firestore";
import { auth, db } from "../../config/firebase";
import { fetchBranches } from "./branchReducer";
import { fetchTransactionsMensual } from "./transactionsReducer";
import { fetchCompany, fetchSettings, fetchUserData } from "./userReducer";

const initialState = {
	companyId: null,
	userId: null,
	isLoggingIn: false,
	isLogginOut: false,
	isAuthenticated: false,
	isFetching: false,
	error: null,
	isSignUpError: false,
	isSignedUp: false,
	isSigningUp: false,
};

export const signupCompany = createAsyncThunk(
	"auth/signupCompany",
	async ({ companyData, userId }, { dispatch, rejectWithValue }) => {
		try {
			const docRef = await addDoc(collection(db, "companies"), companyData);
			if (docRef.id) {
				await updateDoc(doc(db, "companies", docRef.id), {
					id: docRef.id,
				});
				await updateDoc(doc(db, "users", userId), {
					companyId: docRef.id,
				});
				return docRef.id;
			} else {
				return rejectWithValue("Wrong Info Provided");
			}
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const signupComercio = createAsyncThunk(
	"auth/signupComercio",
	async ({ comercioData, userId }, { rejectWithValue }) => {
		try {
			const docRef = await addDoc(collection(db, "comercios"), {
				...comercioData,
				imageURLs: [],
			});

			await updateDoc(doc(db, "users", userId), {
				companyId: docRef.id,
			});

			// if (docRef.id) {
			// 	const branch = await addDoc(collection(db, "branches"), {
			// 		branchDirection: comercioData.branchDirection || "",
			// 		companyId: docRef.id,
			// 		branchID: "",
			// 		branchName:
			// 			comercioData.comercioName + `(${comercioData.coordinates.name})` ||
			// 			"",
			// 		branchPhone: comercioData.comercioPhone || "",
			// 		carrusel: [],
			// 		currencySymbol: "$",
			// 		description: comercioData.description || "",
			// 		hours: {},
			// 		promo: {},
			// 		ceoName: comercioData.ceoName || "",
			// 		coordinates: comercioData.coordinates.geopoint || "",
			// 		geohash,
			// 		comercioCategories: comercioData.comercioCategories || "",
			// 		comercioEmail: comercioData.comercioEmail || "",
			// 		comercioLogo: comercioData.comercioLogo || "",
			// 		comercioMarker: "",
			// 		comercioName: comercioData.comercioName || "",
			// 		comercioReference: "",
			// 		tags: "",
			// 	});

			// 	await updateDoc(doc(db, "comercios", docRef.id), {
			// 		id: docRef.id,
			// 		branch: {
			// 			id: branch.id,
			// 			name:
			// 				comercioData.comercioName +
			// 					" " +
			// 					`(${comercioData.coordinates.name})` || "",
			// 		},
			// 	});

			return { companyId: docRef.id };
		} catch (err) {
			console.log(err);
			return rejectWithValue("Failed to add comercio data.", err);
		}
	}
);

export const loginUser = createAsyncThunk(
	"auth/loginUser",
	async ({ email, password }, { dispatch, rejectWithValue }) => {
		try {
			const authentication = await signInWithEmailAndPassword(
				auth,
				email,
				password
			);
			// .catch((error) => {
			// 	return rejectWithValue(error.message || "Failed to log in");
			// });
			const userId = authentication.user.uid;

			const resultAction = await dispatch(fetchUserData(userId));
			const type = resultAction.payload.userData.worksAt.type;
			await dispatch(fetchSettings());

			if (type !== "admin") {
				const companyId = resultAction.payload.userData.companyId;

				await dispatch(
					fetchTransactionsMensual({
						companyId,
						type,
					})
				);
				await dispatch(
					fetchCompany({
						companyId,
						type,
					})
				);

				if (type === "comercio") {
					dispatch(fetchBranches(companyId));
				}

				return { userId, companyId };
			} else {
				return { userId };
			}
		} catch (error) {
			return rejectWithValue(error.message || "Failed to log in");
		} finally {
			await setPersistence(auth, browserLocalPersistence);
		}
	}
);

export const signupUser = createAsyncThunk(
	"auth/signupUser",
	async ({ dataUser, employee = false }, { rejectWithValue }) => {
		try {
			console.log(dataUser);
			const { email, password } = dataUser;
			const userCred = await createUserWithEmailAndPassword(
				auth,
				email,
				password
			);
			const userId = userCred.user.uid;
			if (!userCred) {
				return rejectWithValue("Error Signing Up");
			}

			if (employee === true) {
				console.log("employee");
				const employeesDocRef = doc(db, "employees", userId);
				await setDoc(employeesDocRef, { ...dataUser, id: userId });
			} else {
				const userDocRef = doc(db, "users", userId);
				await setDoc(userDocRef, { ...dataUser, id: userId });
			}
			return userId;
		} catch (error) {
			console.log(error);
			return rejectWithValue(error.message);
		}
	}
);

export const logoutUser = createAsyncThunk(
	"auth/logoutUser",
	async (props, { dispatch, rejectWithValue }) => {
		try {
			await signOut(auth);
			dispatch(setLogoutState());
		} catch (error) {
			console.log(error);
			rejectWithValue(error);
		}
	}
);

export const verifyAuthentication = createAsyncThunk(
	"auth/verifyAuthentication",
	async (userAuth, { getState, dispatch, rejectWithValue }) => {
		try {
			// Assuming userAuth is an object and you want to check if it exists
			if (userAuth && userAuth.uid) {
				return userAuth.uid;
			}
		} catch (error) {
			return rejectWithValue(
				error.message || "Failed to verify authentication"
			);
		}
	}
);
export const updateCredentials = createAsyncThunk(
	"auth/updateCredentials",
	async (
		{ userId, newEmail, oldEmail, newPassword, oldPassword },
		{ rejectWithValue }
	) => {
		try {
			if (newEmail && newEmail !== oldEmail) {
				const emailRef = query(
					collection(db, "users"),
					where("email", "==", newEmail)
				);
				const snapshot = await getDoc(emailRef);
				if (snapshot.exists()) {
					// Email already exists, handle error here
					throw new Error("Email already in use");
				}
			}

			// Reauthenticate user with old credentials
			const credential = EmailAuthProvider.credential(oldEmail, oldPassword);
			await reauthenticateWithCredential(auth.currentUser, credential);

			// Update email in Firebase Authentication (if changed)
			if (newEmail && newEmail !== oldEmail) {
				await updateEmail(auth.currentUser, newEmail);
			}

			if (newEmail && newEmail !== oldEmail) {
				await updateDoc(doc(db, "users", userId), {
					email: newEmail,
				});
			}

			// Update password in Firebase Authentication (if changed)
			if (newPassword && newPassword !== oldPassword) {
				await updatePassword(auth.currentUser, newPassword);
			}

			return userId;
		} catch (error) {
			console.log("FAILED", error);
			return rejectWithValue(error);
		}
	}
);

const authSlice = createSlice({
	name: "auth",
	initialState,
	reducers: {
		setActiveState: (state) => {
			state.isAuthenticated = true;
		},
		setLogoutState: (state) => {
			Object.assign(state, {
				companyId: null,
				userId: null,
				isLoggingIn: false,
				isLogginOut: false,
				isAuthenticated: false,
				isFetching: false,
				error: null,
				isSignUpError: false,
				isSignedUp: false,
				isSigningUp: false,
			});
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(loginUser.pending, (state) => {
				state.isLoggingIn = true;
			})
			.addCase(loginUser.rejected, (state, action) => {
				state.isLoggingIn = false;
				state.error = action.payload;
			})
			.addCase(loginUser.fulfilled, (state, action) => {
				state.isLoggingIn = false;
				state.isAuthenticated = true;
				state.userId = action.payload.userId;
				state.companyId = action.payload.companyId;
				state.error = null;
			})
			.addCase(signupUser.pending, (state) => {
				state.isSigningUp = true;
				state.error = null;
			})
			.addCase(signupUser.fulfilled, (state, action) => {
				state.isSigningUp = false;
				state.userId = action.payload;
				state.error = null;
			})
			.addCase(signupUser.rejected, (state, action) => {
				state.isSigningUp = false;
				state.error = action.payload;
			})
			.addCase(signupComercio.pending, (state) => {
				state.isSigningUp = true;
			})
			.addCase(signupComercio.fulfilled, (state, action) => {
				state.isSigningUp = false;
				state.companyId = action.payload.companyId;
				state.error = null;
			})
			.addCase(signupComercio.rejected, (state, action) => {
				state.isSigningUp = false;
				state.error = action.error.message;
			})
			.addCase(signupCompany.pending, (state) => {
				state.isSigningUp = true;
			})
			.addCase(signupCompany.fulfilled, (state, action) => {
				state.isSigningUp = false;
				state.companyId = action.payload;
				state.error = null;
			})
			.addCase(signupCompany.rejected, (state, action) => {
				state.isSigningUp = false;
				state.error = action.error.message;
			})
			.addCase(verifyAuthentication.fulfilled, (state, action) => {
				state = { ...state, error: null };
			})
			.addCase(verifyAuthentication.rejected, (state, action) => {
				state.isAuthenticated = false;
				state.error = action.payload;
			})
			.addCase(updateCredentials.fulfilled, (state, action) => {
				state.error = null;
			})
			.addCase(updateCredentials.rejected, (state, action) => {
				state.isAuthenticated = false;
				state.error = action.payload;
			});
	},
});

export const { setActiveState, setLogoutState } = authSlice.actions;

export default authSlice.reducer;
