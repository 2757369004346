import React, { useState } from "react";
import TransactionsTable from "./transactions/TransactionsTable";
import { Box, Typography } from "@mui/material";
import TransactionFiles from "./transactions/TransactionFiles";

const Transactions = () => {
	const [select, setSelect] = useState("table");
	return (
		<Box m="20px">
			<TransactionsTable />
		</Box>
	);
};

export default Transactions;
