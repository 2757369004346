import {
	Box,
	Button,
	Container,
	Grid,
	Typography,
	useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { BarChartMui, Header } from "../../components";
import BiggerInfoCard from "../../components/boxes/BiggerInfoCard";
import InfoCard from "../../components/boxes/InfoCard";
import InfoRankCard from "../../components/boxes/InfoRankCard";
import CustomBarChart from "../../components/charts/TestChart";
import BranchSelector from "../../components/form/BranchSelect";
import { ModalACH } from "../../components/modals/ModalACH";

const today = new Date();
const currentDayOfWeek = today.getDay(); // Domingo = 0, Lunes = 1, ..., Sábado

let initialExpensesData = {
	domingo: currentDayOfWeek >= 0 ? 0 : null,
	lunes: currentDayOfWeek >= 1 ? 0 : null,
	martes: currentDayOfWeek >= 2 ? 0 : null,
	miércoles: currentDayOfWeek >= 3 ? 0 : null,
	jueves: currentDayOfWeek >= 4 ? 0 : null,
	viernes: currentDayOfWeek >= 5 ? 0 : null,
	sábado: currentDayOfWeek >= 6 ? 0 : null,
};

initialExpensesData = Object.keys(initialExpensesData).map((day) => ({
	day: day,
	Expenses: initialExpensesData[day],
}));

const DashboardCompanies = () => {
	const { transactionsMensual: transactions, data } = useSelector(
		(state) => state.transactions
	);
	const { company, data: user } = useSelector((state) => state.user);
	const [branchData, setBranchData] = useState("");
	const [currentBranch, setCurrentBranch] = useState(null);
	const [currentTotal, setCurrentTotal] = useState(
		data?.monthlySummary.currentMonthTotal || 0
	);
	const [open, setOpen] = useState(false);
	const type = user.worksAt.type;
	const formatter = new Intl.NumberFormat("en-US", {
		style: "currency",
		currency: "USD",
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	});
	const handleClose = () => {
		setOpen(false);
	};
	const isMobile = useMediaQuery((theme) => theme.breakpoints.down("tablet"));
	const groupedByMerchant = transactions.reduce((acc, transaction) => {
		const merchantName =
			transaction.comercioDetails.comercioName ||
			transaction.comercioInfo.comercioName;
		if (!acc[merchantName]) {
			acc[merchantName] = 0;
		}
		acc[merchantName] += parseInt(transaction.amount);
		return acc;
	}, {});

	const groupedByCompany = transactions.reduce((acc, transaction) => {
		const companyName = "Banco General S.A";
		if (!acc[companyName]) {
			acc[companyName] = 0;
		}
		acc[companyName] += parseInt(transaction.amount);
		return acc;
	}, {});

	const dataMerchants = Object.keys(groupedByMerchant).map((merchant) => ({
		merchant,
		total: groupedByMerchant[merchant],
	}));

	const dataTopCompany = Object.keys(groupedByCompany).map((company) => ({
		company,
		total: groupedByCompany[company],
	}));

	dataTopCompany.sort((a, b) => b.total - a.total);

	dataMerchants.sort((a, b) => b.total - a.total);

	const defaultExpensesData = initialExpensesData;

	console.log(currentBranch?.id);

	const dailyExpensesData = currentBranch?.id
		? data.branchSummaries[currentBranch?.id]?.branchWeeklySummary
				?.dailyExpensesData || defaultExpensesData
		: data.weeklySummary.dailyExpensesData || defaultExpensesData;
	return (
		<Container
			sx={{
				display: "flex",
				flexDirection: "column",
				marginTop: "2rem",
			}}
		>
			<Box
				sx={{
					display: "flex",
					alignContent: "center",
					alignItems: "center",
					flexDirection: isMobile ? "column" : "row",
					gap: isMobile ? "1rem" : "20rem", // Adjust gap for mobile
					marginBottom: isMobile ? "1rem" : "", // Adjust marginBottom
				}}
			>
				<Header title="Dashboard" subtitle="Bienvenido!" />
				{type === "company" && (
					<Box>
						<Button onClick={() => setOpen(true)}>Deposita Creditos +</Button>
					</Box>
				)}
				{type === "comercio" && (
					<Box>
						<BranchSelector
							setCurrentBranch={setCurrentBranch}
							setBranchData={setBranchData}
							all={true}
						/>
					</Box>
				)}
			</Box>

			<Grid container alignItems="center" spacing={2} rowSpacing={5}>
				<Grid
					item
					justifyContent="center"
					alignItems={"center"}
					phone={12}
					tablet={10}
					desktop={5}
				>
					<Typography variant="h2">
						{type === "company"
							? "Creditos Disponibles"
							: "Ganancias esta semana"}
					</Typography>
					{type === "company" && (
						<Typography variant="h1" color="main">
							{formatter.format(company.totalCredits)}
						</Typography>
					)}
					{type === "comercio" &&
						(currentBranch?.id ? (
							<Typography variant="h1" color="main">
								{formatter.format(
									isNaN(
										data.branchSummaries[currentBranch?.id]?.branchWeeklySummary
											.weeklyTotalExpenses
									) ||
										!data.branchSummaries[currentBranch?.id]
											?.branchWeeklySummary.weeklyTotalExpenses
										? 0
										: data.branchSummaries[currentBranch?.id]
												?.branchWeeklySummary.weeklyTotalExpenses
								)}
							</Typography>
						) : (
							<Typography variant="h1" color="main">
								{formatter.format(
									company.totalCredits ||
										isNaN(data.weeklySummary.weeklyTotalExpenses)
										? 0
										: data.weeklySummary.weeklyTotalExpenses
								)}
							</Typography>
						))}
				</Grid>
				<Grid item phone={12} tablet={12} desktop={7}>
					<BarChartMui
						dailyExpensesData={dailyExpensesData}
						percentageChange={data.weeklySummary.percentageDifference}
					/>
				</Grid>
				<Grid
					item
					container
					justifyContent="center"
					sx={{ alignItems: "start" }}
					spacing={2}
					phone={12}
					tablet={12}
					desktop={12}
				>
					<Grid item phone={12} tablet={3} desktop={4}>
						<InfoCard
							totalSales={formatter.format(
								currentBranch?.id && type === "comercio"
									? data.branchSummaries[currentBranch?.id]
											?.branchMonthlySummary.currentMonthTotal || 0
									: parseFloat(currentTotal)
							)}
							// subtitle={`${
							// 	data?.monthlySummary.percentageDifference > 0
							// 		? "Crecimiento"
							// 		: "Decremento"
							// } del ${data?.monthlySummary.percentageDifference?.toFixed(
							// 	2
							// )}% desde el ultimo mes`}
							title={
								type === "company"
									? "Creditos usados el ultimo mes"
									: "Ganancias totales el ultimo mes"
							}
							data={
								data?.monthlySummary.percentageDifference > 0
									? [
											{ name: "2021-01-01", value: 1000 },
											{ name: "2021-01-02", value: 2500 },
											{ name: "2021-01-03", value: 1750 },
										]
									: [
											{ name: "2021-01-01", value: 2000 },
											{ name: "2021-01-02", value: 1800 },
											{ name: "2021-01-03", value: 1500 },
										]
							}
							gradientKey={1}
							percentageChange={data?.monthlySummary.percentageDifference}
						/>
					</Grid>
					<Grid item phone={12} tablet={3} desktop={4}>
						<InfoCard
							gradientKey={2}
							totalSales={
								currentBranch?.id
									? data.branchSummaries[currentBranch?.id]
											?.branchMonthlySummary.count || 0
									: data?.monthlySummary.count
							}
							percentageChange={data?.monthlySummary.countDifference}
							data={[
								{ name: "2021-01-01", value: 1000 },
								{ name: "2021-01-02", value: 1500 },
								{ name: "2021-01-03", value: 1350 },
							]}
							// subtitle={`Crecimiento del ${
							// 	data?.monthlySummary.countDifference?.toFixed(2) || 0
							// }% desde el ultimo mes`}
							title={
								type === "company"
									? "Uso de sus colaboradores"
									: "Trafico en su establecimiento"
							}
						/>
					</Grid>
					<Grid item phone={12} tablet={3} desktop={4}>
						<InfoRankCard />
					</Grid>
				</Grid>
				<Grid
					item
					container
					justifyContent="center"
					sx={{ alignItems: "center" }}
					spacing={2}
					phone={12}
					tablet={12}
					desktop={12}
				>
					<Grid
						item
						phone={12}
						tablet={12}
						desktop={type === "company" ? 8 : 12}
					>
						<CustomBarChart
							data={type === "company" ? dataMerchants : dataTopCompany}
							dataKeyY={"total"}
							dataKeyX={type === "company" ? "merchant" : "company"}
							title={
								type === "company"
									? "Establecimientos mas vistados por tus colaboradores"
									: "Las empresas que te visitan mas frecuente"
							}
							subtitle={
								dataTopCompany[0] || dataMerchants[0]
									? `El mas visitdado a sido ${
											type === "company"
												? dataMerchants[0].merchant
												: dataTopCompany[0]?.company
										}`
									: "No hay suficiente data disponible"
							}
						/>
					</Grid>
					{type === "company" && (
						<Grid item phone={12} tablet={3} desktop={4}>
							<BiggerInfoCard
								totalSales={"Categoria mas cosumida: Alimentacion"}
								percentageChange={10.2}
								title={"Distribucion de categorias"}
								data={[
									{ name: "Alimentacion", value: 2500 },
									{ name: "Salud", value: 1500 },
									{ name: "Transporte", value: 1750 },
									{ name: "Educacion", value: 350 },
								]}
							/>
						</Grid>
					)}
				</Grid>
			</Grid>
			<ModalACH open={open} handleClose={handleClose} />
		</Container>
	);
};

export default DashboardCompanies;
