import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { collection, query, where, getDocs } from "firebase/firestore";

import { db } from "../../config/firebase";

import { getTransactionSummary } from "../../app/utils/companies/creditUtils";
import axios from "axios";

const initialState = {
	isFetching: false,
	error: null,
	transactions: [],
	transactionsMensual: [],
	transferencias: [],
};
export const fetchTransactions = createAsyncThunk(
	"transactions/fetch",
	async (
		{ companyId, createdAtStart, createdAtEnd, type },
		{ rejectWithValue }
	) => {
		const formData = new FormData();

		formData.append("companyId", companyId.companyId || companyId);
		formData.append("createdAtStart", createdAtStart);
		formData.append("createdAtEnd", createdAtEnd);

		try {
			let res = await axios.post(
				"https://api.nexo.com.pa/findTransactions/v1/",
				formData
			);

			const transactions = res.data;

			const summary = getTransactionSummary(transactions, type);

			return { data: transactions, summary };
		} catch (err) {
			console.error("ERROR RETRIEVING DOCS:", err);
			return rejectWithValue(err);
		}
	}
);

function obtenerInicioYFinDelMes() {
	const fechaActual = new Date();
	const primerDiaDelMes = new Date(
		fechaActual.getFullYear(),
		fechaActual.getMonth(),
		1
	);
	const ultimoDiaDelMes = new Date(
		fechaActual.getFullYear(),
		fechaActual.getMonth() + 1,
		0
	);

	// Función de ayuda para formatear la fecha en el formato deseado
	const formatearFecha = (fecha) => {
		const año = fecha.getFullYear();
		// Mes: getMonth() devuelve un valor entre 0 y 11, así que sumamos 1 para obtener el mes correcto y
		// usamos padStart para asegurarnos de que siempre tenga dos dígitos
		const mes = (fecha.getMonth() + 1).toString().padStart(2, "0");
		// Día: getDate() devuelve el día del mes y usamos padStart por la misma razón que con el mes
		const dia = fecha.getDate().toString().padStart(2, "0");
		return `${año}-${mes}-${dia} 00:00:00`;
	};

	return {
		inicioDelMes: formatearFecha(primerDiaDelMes),
		finDelMes: formatearFecha(ultimoDiaDelMes),
	};
}

export const fetchTransactionsMensual = createAsyncThunk(
	"transactions/fetchSemana",
	async (
		{ companyId, createdAtStart, createdAtEnd, type },
		{ rejectWithValue }
	) => {
		const formData = new FormData();
		const { inicioDelMes, finDelMes } = obtenerInicioYFinDelMes();

		formData.append("companyId", companyId.companyId || companyId);
		formData.append("createdAtStart", inicioDelMes);
		formData.append("createdAtEnd", finDelMes);

		try {
			let res = await axios.post(
				"https://api.nexo.com.pa/findTransactions/v1/",
				formData
			);

			const transactions = res.data;

			const summary = getTransactionSummary(transactions, type);

			return { data: transactions, summary };
		} catch (err) {
			console.error("ERROR RETRIEVING DOCS:", err);
			return rejectWithValue(err);
		}
	}
);

export const fetchBranchTransactions = createAsyncThunk(
	"transactions/fetchTransactionsMensual",
	async (branchId, { rejectWithValue }) => {
		try {
			let transactionsRef = collection(db, "transactions");

			if (branchId) {
				transactionsRef = query(
					transactionsRef,
					where("comercioDetails.comercioId", "==", branchId)
				);
			}

			const querySnapshot = await getDocs(transactionsRef);
			const data = querySnapshot.docs.map((doc) => ({
				id: doc.id,
				...doc.data(),
			}));

			if (data) {
				return data;
			} else {
				return;
			}
		} catch (err) {
			console.error("ERROR RETRIEVING DOCS:", err);
			return rejectWithValue(err);
		}
	}
);

export const fetchTransferencias = createAsyncThunk(
	"transactions/transferencias",
	async () => {
		try {
			let transferenciasRef = collection(db, "transferencias");
			transferenciasRef = query(
				transferenciasRef,
				where("state", "==", "espera")
			);
			const querySnapshot = await getDocs(transferenciasRef);
			const data = querySnapshot.docs.map((doc) => ({
				id: doc.id,
				...doc.data(),
			}));

			return data;
		} catch (err) {
			console.error("ERROR RETRIEVING DOCS:", err);
		}
	}
);

const transactionSlice = createSlice({
	name: "transactions",
	initialState,
	reducers: {
		clearTransactions: (state) => {
			Object.assign(state, {
				isFetching: false,
				error: null,
				transactions: {},
			});
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchTransactions.pending, (state) => {
				state.isFetching = true;
				state.error = null;
			})
			.addCase(fetchTransactions.fulfilled, (state, action) => {
				state.isFetching = false;
				state.transactions = action.payload.data;
				state.data = action.payload.summary;
				state.error = null;
			})
			.addCase(fetchTransactions.rejected, (state, action) => {
				state.isFetching = false;
				state.error = action.error.message;
			})
			.addCase(fetchTransactionsMensual.pending, (state) => {
				state.isFetching = true;
				state.error = null;
			})
			.addCase(fetchTransactionsMensual.fulfilled, (state, action) => {
				state.isFetching = false;
				state.transactionsMensual = action.payload.data;
				state.data = action.payload.summary;
				state.error = null;
			})
			.addCase(fetchTransactionsMensual.rejected, (state, action) => {
				state.isFetching = false;
				state.error = action.error.message;
			})
			.addCase(fetchTransferencias.pending, (state) => {
				state.isFetching = true;
				state.error = null;
			})
			.addCase(fetchTransferencias.fulfilled, (state, action) => {
				state.isFetching = false;
				state.transferencias = action.payload;
				state.error = null;
			})
			.addCase(fetchTransferencias.rejected, (state, action) => {
				state.isFetching = false;
				state.error = action.error.message;
			});
	},
});

export const { clearTransactions } = transactionSlice.actions;
export default transactionSlice.reducer;
