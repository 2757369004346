import React from "react";
import { Button, Container, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const StepSuccess = () => {
	const navigate = useNavigate();
	const handleClick = (e) => {
		e.preventDefault();
		navigate("/login");
	};
	return (
		<Container
			sx={{
				display: "flex",
				flexDirection: "column",
				gap: "24px",
				alignItems: "center",
			}}
		>
			<>
				<Typography variant="h1"> Gracias por Registrarte</Typography>
				<Typography variant="h5">
					Te contactaremos con mas informacion a tu email
				</Typography>
			</>

			<Button onClick={(e) => handleClick(e)}>"Completar Registro"</Button>
		</Container>
	);
};

export default StepSuccess;
