import { createContext, useState, useMemo } from "react";
import { createTheme } from "@mui/material/styles";
import { outlinedInputClasses } from "@mui/material/OutlinedInput";

export const tokens = (mode) => ({
	...(mode === "dark"
		? {
				redAccent: {
					900: "#3C0A07",
					800: "#6A150F",
					700: "#982016",
					600: "#CC2C1E",
					500: "#FF3357",
					400: "#FF5D6E",
					300: "#FF8598",
					200: "#FFADC3",
					100: "#F6F6F5",
				},
				primary: {
					100: "#FFFFFF",
					200: "#FFFFFF",
					300: "#FFFFFF",
					400: "#FFFFFF",
					500: "#FFFFFF",
					600: "#CCCCCC",
					700: "#999999",
					800: "#666666",
					900: "#333333",
				},
				whiteAccent: {
					100: "#FAF9F9",
					200: "#F6F6F5",
					300: "#F2F2F1",
					400: "#EDECEB",
					500: "#e6e8e6",
					600: "#BAB8B8",
					700: "#8D8B8B",
					800: "#605D5D",
					900: "#333131",
				},
				white: {
					100: "rgba(255,255,255)",
					200: "rgba(255,255,255, 0.9)",
					300: "rgba(255,255,255,0.8)",
					400: "rgba(255,255,255,0.7)",
					500: "rgba(255,255,255,0.6)",
					600: "rgba(255,255,255, 0.5)",
					700: "rgba(255,255,255)",
					800: "rgba(255,255,255)",
					900: "rgba(255,255,255,0.1)",
				},
				grey: {
					100: "#f6f6f5",
					200: "#EDECEB",
					300: "#E3E2E2",
					400: "#D9D8D8",
					500: "#ced0ce",
					600: "#A7A6A6",
					700: "#7E7D7D",
					800: "#555353",
					900: "#2c2b2b",
				},
			}
		: {
				redAccent: {
					100: "#3C0A07",
					200: "#6A150F",
					300: "#982016",
					400: "#CC2C1E",
					500: "#FF3357",
					600: "#FF5D6E",
					700: "#FF8598",
					800: "#FFADC3",
					900: "#F6F6F5",
				},
				primary: {
					100: "#FFFFFF",
					200: "#FFFFFF",
					300: "#FFFFFF",
					400: "#FFFFFF",
					500: "#FFFFFF",
					600: "#CCCCCC",
					700: "#999999",
					800: "#666666",
					900: "#333333",
				},
				whiteAccent: {
					100: "#333131",
					200: "#605D5D",
					300: "#8D8B8B",
					400: "#BAB8B8",
					500: "#ced0ce",
					600: "#D9D8D8",
					700: "#E3E2E2",
					800: "#EDECEB",
					900: "#f6f6f5",
				},
				white: {
					100: "rgba(0,0,0,0.1)",
					200: "rgba(255,255,255)",
					300: "rgba(255,255,255,0.5)",
					400: "rgba(255,255,255,0.6)",
					500: "rgba(255,255,255,0.7)",
					600: "rgba(255,255,255,0.8)",
					700: "rgba(255,255,255, 0.9)",
					800: "rgba(255,255,255)",
					900: "rgba(0,0,0,0.1)",
				},
				grey: {
					100: "#2c2b2b",
					200: "#555353",
					300: "#7E7D7D",
					400: "#A7A6A6",
					500: "#ced0ce",
					600: "#D9D8D8",
					700: "#E3E2E2",
					800: "#EDECEB",
					900: "#f6f6f5",
				},
			}),
});

export const themeSettings = (mode, buttonVariant) => {
	const colors = tokens(mode);
	return {
		components: {
			MuiSwitch: {
				styleOverrides: {
					switchBase: {
						color: "#ff6b6b", // Rojo claro para el knob cuando está desactivado
						"&.Mui-checked": {
							color: "#ff6b6b", // Rojo claro para el knob cuando está activado
						},
						"&.Mui-checked + .MuiSwitch-track": {
							backgroundColor: "#ff6b6b", // Rojo claro para la track cuando está activado
						},
					},
					track: {
						backgroundColor: "#e0e0e0",
					},
				},
			},
			MuiButton: {
				styleOverrides: {
					root: {
						fontSize: "12px",
						backgroundColor: colors.redAccent[500], // Your accent color for
						padding: "10px",
						color: "#fff", // White text color for buttons
						textTransform: "none", // No uppercase
						boxShadow:
							"0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)", // Subtle shadow for depth
						"&:hover": {
							backgroundColor: colors.redAccent[600], // Slightly darker on hover
							// Reset on hover's box-shadow if needed
						},
						"&:active": {
							boxShadow: "none", // No box-shadow when button is clicked
						},
						"&:focus": {
							boxShadow: `0 0 0 0.2rem ${colors.redAccent[200]}80`, // A focus ring with a translucent accent color
						},
					},
				},
			},
			MuiInputLabel: {
				styleOverrides: {
					root: {
						color: colors.grey[100],
					},
				},
			},
			MuiOutlinedInput: {
				styleOverrides: {
					root: {
						"@media (max-width: 650px)": {
							width: "15rem",
						},
						maxWidth: "300px",
						backgroundColor: "white",
						color: colors.grey[100],
						borderColor: colors.redAccent[500],
						[`&:hover .${outlinedInputClasses.notchedOutline}`]: {
							borderColor: "var(--TextField-brandBorderHoverColor)",
						},
						[`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
							borderColor: "var(--TextField-brandBorderFocusedColor)",
						},
					},
				},
			},
			MuiTextField: {
				styleOverrides: {
					root: {
						"& label.Mui-focused": {
							color: "grey",
						},
						"& .MuiInput-underline:after": {
							borderBottomColor: "#FF3357",
						},
						"& .MuiOutlinedInput-root": {
							"& fieldset": {
								borderColor: "rgba(0, 0, 0, 0.23)",
							},
							"&:hover fieldset": {
								borderColor: "rgba(0, 0, 0, 0.23)",
							},
							"&.Mui-focused fieldset": {
								borderColor: "#FF3357",
							},
						},
					},
				},
			},
		},
		breakpoints: {
			values: {
				phone: 0,
				tablet: 600,
				desktop: 960, // Medium devices (desktops)
				desktoplg: 1280, // Large devices (large desktops)
			},
		},
		palette: {
			mode: mode,
			...(mode === "dark"
				? {
						// palette values for dark mode
						primary: {
							main: colors.primary[900],
						},
						secondary: {
							main: colors.redAccent[500],
						},
						neutral: {
							dark: colors.grey[700],
							main: colors.grey[500],
							light: colors.grey[100],
						},
						background: {
							default: "#ffff",
						},
					}
				: {
						// palette values for light mode
						primary: {
							main: colors.primary[100],
						},
						secondary: {
							main: colors.redAccent[500],
						},
						neutral: {
							dark: colors.grey[700],
							main: colors.grey[500],
							light: colors.grey[100],
						},
						background: {
							default: "#ffff",
						},
					}),
		},

		typography: {
			fontFamily: ["Montserrat", "sans-serif"].join(","),
			fontSize: 12,
			h1: {
				fontFamily: ["Montserrat", "sans-serif"].join(","),
				fontSize: 38,
				fontWeight: 700,
				"@media (max-width: 650px)": {
					fontSize: 35,
				},
			},
			h2: {
				fontFamily: ["Montserrat", "sans-serif"].join(","),
				fontSize: 36,
				fontWeight: 500,
				"@media (max-width: 650px)": {
					fontSize: 28,
				},
			},
			h3: {
				fontFamily: ["Montserrat", "sans-serif"].join(","),
				fontSize: 24,
				fontWeight: 600,
				"@media (max-width: 650px)": {
					fontSize: 20,
				},
			},
			h4: {
				fontFamily: ["Montserrat", "sans-serif"].join(","),
				fontSize: 20,
				fontWeight: 500,
				"@media (max-width: 650px)": {
					fontSize: 18,
				},
			},
			h5: {
				fontFamily: ["Montserrat", "sans-serif"].join(","),
				fontSize: 16,
				fontWeight: 600,
				"@media (max-width: 650px)": {
					fontSize: 14,
				},
			},
			h6: {
				fontFamily: ["Montserrat", "sans-serif"].join(","),
				fontSize: 14,
				fontWeight: 500,
				"@media (max-width: 650px)": {
					fontSize: 12,
				},
			},
			p: {
				fontFamily: ["Montserrat", "sans-serif"].join(","),
				fontSize: 16,
				fontWeight: 400,
				"@media (max-width: 650px)": {
					fontSize: 14,
				},
			},
			a: {
				fontFamily: ["Montserrat", "sans-serif"].join(","),
				fontSize: 16,
				fontWeight: 400,
				cursor: "pointer",
				"@media (max-width: 650px)": {
					fontSize: 14,
				},
			},
		},
	};
};

export const ColorModeContext = createContext({
	toggleColorMode: () => {},
});

export const useMode = () => {
	const [mode, setMode] = useState("light");

	const colorMode = useMemo(
		() => ({
			toggleColorMode: () =>
				setMode((prev) => (prev === "light" ? "dark" : "light")),
		}),
		[]
	);

	const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);

	return [theme, colorMode];
};
