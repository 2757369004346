import {
	Box,
	Button,
	Grid,
	IconButton,
	InputAdornment,
	MenuItem,
	Modal,
	Select,
	TextField,
	Typography,
	Snackbar,
	Alert,
	CircularProgress,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import CloseIcon from "@mui/icons-material/Close";
import { geohashForLocation } from "geofire-common";
import { useDispatch, useSelector } from "react-redux";
import { signupUser } from "../../../store/reducers/authReducer";
import { addBranch } from "../../../store/reducers/branchReducer";
import Input from "../form/Input";
import MapWithAutocomplete from "../form/LocationInput";
import { PanamaPhoneNumberInput } from "../form/PanamaPhoneInput";
import SubCategroyInput from "../../../admin/components/inputs/SubcategoryInput";

export const AddBranchModal = ({ handleClose, onClose, isOpen }) => {
	const { control, handleSubmit, reset, setValue } = useForm();
	const dispatch = useDispatch();
	const { comercioName, comercioCurrency, comercioLogo } = useSelector(
		(state) => state.user.company
	);
	const formattedName = comercioName.toLowerCase().replace(/\s+/g, "");
	const { categories, subcategories } = useSelector(
		(state) => state.user.settings
	);
	const {
		error,
		companyId,
		settings,
		data: userData,
	} = useSelector((state) => state.user);
	const [coordinates, setCoordinates] = useState({
		array: "",
		geopoint: "",
		direction: "'Nombre de Direccion'",
	});

	const [openSnackbar, setOpenSnackbar] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState("");
	const [isLoading, setIsLoading] = useState(false);

	const [selectedCategory, setSelectedCategory] = useState("");
	const [selectedSubcategories, setselectedSubcategories] = useState([]);
	const handleCloseSnackbar = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}

		setOpenSnackbar(false);
	};
	useEffect(() => {
		const branchName =
			`${comercioName} | ${coordinates?.direction?.split(",")[0]}`.trim();
		setValue("branchName", branchName);
	}, [comercioName, coordinates, setValue]);
	const onSubmit = useCallback(
		async (data) => {
			setIsLoading(true);
			try {
				const geoHash = geohashForLocation(coordinates.array, 9);
				const branchData = {
					branchDirection: coordinates.direction,
					companyId,
					branchId: "",
					branchName: data.branchName || "",
					branchManager: data.branchManager || "",
					branchPhone: data.comercioPhone || "",
					carrusel: data.carrusel || [],
					coordinates: coordinates.geoPoint,
					currencySymbol: comercioCurrency || "",
					description: "",
					geohash: geoHash || "",
					hours: {},
					promo: {},
					reserveURL: data.reserveURL || "",
					comercioCategories: data.comercioCategories || "",
					comercioEmail: data.comercioEmail || "",
					comercioLogo: comercioLogo || "",
					comercioPhone: data.comercioPhone || "",
					comercioMarker: data.comercioMarker || "",
					comercioName: comercioName || "",
					specialHours: {},
					tags: selectedSubcategories,
					tipoComercio: data.category,
				};
				const actionResult = await dispatch(
					addBranch({ companyId, branchData })
				);

				if (addBranch.fulfilled.match(actionResult)) {
					const formattedEmail =
						data.comercioEmail + "@" + formattedName + ".pa";
					const dataUser = {
						...userData,
						email: formattedEmail,
						password: data.password,
						worksAt: {
							branchId: actionResult.payload,
						},
					};
					const addUser = await dispatch(
						signupUser({ dataUser, employee: false })
					);

					if (signupUser.fulfilled.match(addUser)) {
						setSnackbarMessage("Branch added successfully!");
						setOpenSnackbar(true);
						onClose();
					} else {
						throw new Error("User signup failed: " + addUser.error.message);
					}
				} else {
					throw new Error("Branch addition failed");
				}
			} catch (error) {
				console.error("Operation failed:", error);
				setSnackbarMessage(error.message || "Failed to add branch and user.");
				setOpenSnackbar(true);
			}
			setIsLoading(false);
		},
		[
			coordinates.array,
			coordinates.direction,
			coordinates.geoPoint,
			companyId,
			comercioCurrency,
			comercioLogo,
			comercioName,
			selectedSubcategories,
			dispatch,
			formattedName,
			onClose,
		]
	);

	return (
		<Modal
			open={isOpen}
			onClose={onClose}
			sx={{
				display: "flex",
				alignItems: "center",
				alignContent: "center",
				justifyContent: "center",
			}}
		>
			<Box
				sx={{
					position: "absolute",
					top: "50%",
					left: "50%",
					transform: "translate(-50%, -50%)",
					width: "80vw",
					height: "90vh",
					bgcolor: "background.paper",
					boxShadow: 24,
					p: 4,
					overflow: "auto",
					borderRadius: 3,
				}}
			>
				<IconButton
					aria-label="close"
					onClick={onClose}
					sx={{
						position: "absolute",
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
					}}
				>
					<CloseIcon />
				</IconButton>
				<Typography variant="h4" component="h2" sx={{ mb: 4 }}>
					Add New Branch
				</Typography>
				<form onSubmit={handleSubmit(onSubmit)}>
					<Grid container justifyContent="start" alignItems="start" spacing={3}>
						<Grid item phone={12}>
							<MapWithAutocomplete setCoordinates={setCoordinates} />
						</Grid>
						<Grid item phone={12} desktop={6}>
							<TextField
								fullWidth
								label="Branch Name"
								sx={{ width: "20rem" }}
								variant="outlined"
								{...control.register("branchName")}
							/>
						</Grid>
						<Grid item phone={12} desktop={6} tablet={12}>
							<Input
								width="20rem"
								title="Nombre del Gerente"
								type="text"
								name="managerName"
								control={control}
							/>
						</Grid>
						<Grid item phone={12} desktop={6} tablet={12}>
							<TextField
								label="Correo del comercio"
								variant="outlined"
								{...control.register("comercioEmail")}
								InputProps={{
									// Esta propiedad añade adornos al componente de entrada; en este caso, el sufijo fijo.
									endAdornment: (
										<InputAdornment position="end">
											@{formattedName}.pa
										</InputAdornment>
									),
								}}
								// Propiedad para hacer el TextField de ancho completo
								fullWidth
							/>
						</Grid>
						<Grid item phone={12} desktop={6} tablet={12}>
							<Input
								width="20rem"
								title="Contraseña de Ingreso"
								type="password"
								name="password"
								control={control}
							/>
						</Grid>

						<Grid item phone={12} desktop={6} tablet={12}>
							<PanamaPhoneNumberInput
								control={control}
								width="20rem"
								name="comercioPhone"
								label="Phone Number"
							/>
						</Grid>
						<Grid item phone={12} desktop={6}>
							<Select
								fullWidth
								defaultValue=""
								{...control.register("category", {
									onChange: (e) => {
										setSelectedCategory(e.target.value);
									},
								})}
								displayEmpty
								inputProps={{ "aria-label": "Without label" }}
							>
								<MenuItem disabled value="">
									<em>Select Business Type</em>
								</MenuItem>
								{settings.categories.map((category) => (
									<MenuItem key={category.id} value={category.id - 1}>
										{category.nombre}
									</MenuItem>
								))}
							</Select>
						</Grid>

						{subcategories[selectedCategory] && (
							<Grid item phone={12} desktop={6}>
								<SubCategroyInput
									category={selectedCategory}
									setSelectedSubcategories={setselectedSubcategories}
									selectedSubcategories={selectedSubcategories}
								/>
							</Grid>
						)}
						<Grid item phone={12} desktop={6}>
							<TextField
								fullWidth
								label="URL de Reservas"
								sx={{ width: "20rem" }}
								variant="outlined"
								{...control.register("reserveURL")}
							/>
						</Grid>
						<Grid item phone={12} sx={{ display: "flex", gap: 2 }}>
							<Button type="submit" variant="contained" disabled={isLoading}>
								Add Branch
							</Button>
							<Button onClick={onClose} variant="outlined">
								Cancel
							</Button>
						</Grid>
						<Grid item>{isLoading && <CircularProgress />}</Grid>
					</Grid>
				</form>
				<Snackbar
					open={openSnackbar}
					autoHideDuration={6000}
					onClose={handleCloseSnackbar}
				>
					<Alert
						onClose={handleCloseSnackbar}
						severity="success"
						sx={{ width: "100%" }}
					>
						{snackbarMessage}
					</Alert>
				</Snackbar>
			</Box>
		</Modal>
	);
};
