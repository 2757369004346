import React from "react";
import { useNavigate } from "react-router-dom";
import { importIcon } from "../../utils/importIcon";
import { tokens } from "../../../theme";
import { MenuItem } from "react-pro-sidebar";
import { Typography, useTheme } from "@mui/material";

const Item = ({ name, path, icon, selected, setSelected }) => {
	const theme = useTheme();
	const navigate = useNavigate();
	const colors = tokens(theme.palette.mode);
	const IconComponent = importIcon(icon);

	const handleClick = () => {
		setSelected(name);
		navigate(path);
	};

	return (
		<MenuItem
			active={selected === name}
			style={{
				color: colors.grey[200],
			}}
			icon={IconComponent && <IconComponent />}
			onClick={() => handleClick()}
		>
			<Typography>{name}</Typography>
		</MenuItem>
	);
};

export default Item;
