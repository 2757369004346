import SearchIcon from "@mui/icons-material/Search";
import {
	Box,
	CircularProgress,
	InputAdornment,
	TextField,
	useTheme,
} from "@mui/material";
import { DataGrid, GridOverlay, GridToolbar } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FetchTransactionsButton from "../../../../admin/components/buttons/FetchTransactionButton";
import { tokens } from "../../../../theme";
import { Header } from "../../../components";
import { AddTransactionModal } from "../../../components/modals/AddTransactionModal";

const CustomNoRowsOverlay = () => {
	return (
		<GridOverlay>
			<Box sx={{ mt: 3, textAlign: "center" }}>
				No hay transacciones disponibles.
			</Box>
		</GridOverlay>
	);
};

const TransactionsTable = () => {
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const [rowSelectionModel, setRowSelectionModel] = useState([]);
	const { transactions } = useSelector((state) => state.transactions);
	const {
		worksAt: { type },
		companyId,
	} = useSelector((state) => state.user.data);
	const dispatch = useDispatch();
	const [searchText, setSearchText] = useState("");
	const today = new Date();
	const lastWeek = new Date(today);
	lastWeek.setDate(lastWeek.getDate() - 7);

	const [startDate, setStartDate] = useState(
		lastWeek.toISOString().slice(0, 10)
	);
	const [endDate, setEndDate] = useState(today.toISOString().slice(0, 10));
	const [transacciones, setTransacciones] = useState([]);

	const handleSearch = (event) => {
		setSearchText(event.target.value);
	};

	useEffect(() => {
		setLoading(true);
		setTimeout(() => {
			const filteredTransactions = transactions.filter((transaction) => {
				const lowerCasedSearchText = searchText.toLowerCase();
				const userPayment = transaction.paymentInfo.userPayment.toLowerCase();
				const branchName = transaction.comercioDetails.branchName.toLowerCase();
				const createdAt = new Date(transaction.createdAt.seconds * 1000);

				return (
					(userPayment.includes(lowerCasedSearchText) ||
						branchName.includes(lowerCasedSearchText)) &&
					(!startDate || createdAt >= new Date(startDate)) &&
					(!endDate || createdAt <= new Date(endDate))
				);
			});

			setTransacciones(filteredTransactions);
		}, 1000);
	}, [endDate, searchText, startDate, transactions]);

	const columns = [
		{
			field: "amount",
			headerName: "Amount",
			width: 150,
			sortable: true,
			filterable: true,
			valueFormatter: (params) => params?.value?.toLocaleString(), // Formatear como número local, si es necesario
		},
		{
			field: "createdAt",
			headerName: "Fecha de Pago",
			width: 200,
			sortable: true,
			valueFormatter: (params) => {
				const createdAt = new Date(params?.value?.seconds * 1000); // Asumiendo que el valor es un objeto Timestamp de Firestore
				return (
					createdAt?.toLocaleDateString() +
					" " +
					createdAt?.toLocaleTimeString()
				); // Devuelve fecha y hora local
			},
		},

		{
			field: "isPaymentMade",
			headerName: "Payment Made",
			width: 150,
			sortable: true,
			filterable: true,
			valueFormatter: (params) => (params?.value ? "Yes" : "No"), // Convertir booleano a cadena
		},

		{
			field: "comercioDetails",
			headerName: "Nombre de Sucursal",
			width: 150,
			sortable: true,
			filterable: true,
			valueFormatter: (params) => params?.value?.branchName, // Directamente el nombre de la sucursal
		},
		type === "comercio" && {
			field: "paymentInfo",
			headerName: "Nombre de la Compañia",
			width: 250,
			sortable: true,
			filterable: true,
			valueFormatter: (params) => params?.value?.companyName, // Directamente el nombre del usuario
		},
	];

	const closeModal = () => {
		setIsModalOpen(false);
	};

	return (
		<>
			<Box
				sx={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
				}}
			>
				<Header
					title="Transacciones"
					subtitle="Dale un vistazo a tus transacciones"
				/>
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						gap: "5px",
					}}
				>
					<TextField
						placeholder="Fecha inicial"
						variant="outlined"
						size="small"
						type="date"
						value={startDate}
						onChange={(e) => setStartDate(e?.target?.value)}
						sx={{ margin: "2rem", width: "20rem" }}
					/>

					<TextField
						placeholder="Fecha final"
						variant="outlined"
						size="small"
						type="date"
						value={endDate}
						onChange={(e) => setEndDate(e.target.value)}
						sx={{ margin: "2rem", width: "20rem" }}
					/>
					<FetchTransactionsButton
						companyId={companyId}
						createdAtStart={startDate}
						createdAtEnd={endDate}
						type={type}
					/>
					{/* 					<Button onClick={openModal}>Add Transactions +</Button>
					<Button onClick={handleDelete}>Delete Transactions</Button> */}
				</Box>
			</Box>
			<>
				<TextField
					placeholder="Sucursal o Nombre del Cliente"
					variant="outlined"
					size="small"
					value={searchText}
					onChange={handleSearch}
					sx={{ margin: "2rem", width: "20rem" }}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<SearchIcon />
							</InputAdornment>
						),
					}}
				/>
			</>
			<div style={{ height: 400, width: "100%" }}>
				<DataGrid
					rows={transacciones}
					columns={columns}
					pageSize={20}
					checkboxSelection
					disableRowSelectionOnClick
					onRowSelectionModelChange={(newRowSelectionModel) => {
						setRowSelectionModel(newRowSelectionModel);
					}}
					slots={{
						toolbar: GridToolbar,
						noRowsOverlay: CustomNoRowsOverlay,
						loadingOverlay: CircularProgress,
					}}
					rowSelectionModel={rowSelectionModel}
					sx={{
						"& .MuiDataGrid-cellCheckbox": {
							color: "secondary.main", // Change as needed
						},
						"& .MuiCheckbox-root.Mui-checked": {
							color: "secondary.main", // Use theme color or specific color
						},
						"& .MuiDataGrid-cellCheckbox svg": {
							fill: "secondary.main", // For the checkbox icon itself
						},
					}}
					getRowId={(row) =>
						`${row.createdAt.nanoseconds}-${row.createdAt.seconds}`
					}
				/>
			</div>
			{isModalOpen && <AddTransactionModal closeModal={closeModal} />}
		</>
	);
};

export default TransactionsTable;
