import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { loginUser } from "../../../store/reducers/authReducer";
import { useForm } from "react-hook-form";
import { Button, Typography, Box } from "@mui/material";
import { Input, LoadingState } from "../../components";
import { useSelector, useDispatch } from "react-redux";

const Login = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [error, setError] = useState(null);
	const { isLoggingIn } = useSelector((state) => state.auth);

	const { handleSubmit, control, reset } = useForm();

	const onSubmit = async ({ email, password }) => {
		try {
			const res = await dispatch(loginUser({ email, password }));
			if (res.type === "auth/loginUser/rejected") {
				setError({
					type: "400",
					message: "Wrong credentials. Please try again.",
				});
			} else if (
				res.type !== "auth/loginUser/fulfilled" ||
				res.type !== "auth/loginUser/pending"
			) {
				if (res.payload.userId === process.env.REACT_APP_FIREBASE_ADMIN) {
					navigate("/admin/dashboard");
				} else {
					navigate(`/home/dashboard/${res.payload.companyId}`);
				}
			}
		} catch (error) {
			console.log(error);
			setError({
				type: "500", // or any appropriate error type
				message: "An error occurred. Please try again later.",
			});
		}
		reset({
			keepErrors: true,
		});
	};

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				justifyContent: "center",
				minHeight: "100vh",
				backgroundColor: "rgba(247,	56,	37, 0.05)",
			}}
		>
			<form
				style={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					backgroundColor: "white",
					boxShadow: "0px 0px 5px 1px rgba(0,0,0,0.1)",
					width: "30rem",
					padding: "2rem",
				}}
				onSubmit={handleSubmit(onSubmit)}
			>
				<img
					src="/assets/logonexo.png"
					alt="logo"
					style={{ maxWidth: "15rem" }}
				/>
				<Typography variant="p" mb="1rem">
					Login with email and password
				</Typography>
				<span style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
					<Input
						id="email-address"
						name="email"
						type="text"
						control={control}
						rules={{
							required: true,
							pattern: /^[\w.%+-ñ]+@[\w.-ñ]+\.[A-Za-z]{2,}$/i,
						}}
						width="15rem"
						placeholder="nombre@compañia.com"
					/>
					<Input
						width="15rem"
						id="password"
						name="password"
						type="password"
						control={control}
						placeholder="password"
						rules={{
							required: true,
						}}
					/>
				</span>
				<div style={{ display: "flex", justifyContent: "center" }}>
					{error?.message && <p>{error.message}</p>}
				</div>
				{isLoggingIn && (
					<div style={{ display: "flex", justifyContent: "center" }}>
						<LoadingState size="sm" />
					</div>
				)}
				<div
					style={{
						display: "flex",
						justifyContent: "center",
						margin: " 2.4rem 0",
					}}
				>
					<Button sx={{ width: "200px" }} type="submit">
						Login
					</Button>
				</div>
				<Link to="/signup">
					<Typography
						variant="a"
						sx={{ mt: "2rem", fontSize: "0.8rem", textDecoration: "underline" }}
					>
						Register
					</Typography>
				</Link>
			</form>
			<Link
				to={"/"}
				style={{
					position: "absolute",
					top: "0",
					left: 0,
					margin: "5rem",
					padding: "1rem 2rem",
					backgroundColor: "white",
					borderRadius: "50px",
					"&::hover": {
						backgroundColor: "black",
					},
				}}
			>
				Home
			</Link>
		</Box>
	);
};

export default Login;
