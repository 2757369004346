import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box, Button, Container, Paper, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import axios from "axios";
import {
	arrayUnion,
	collection,
	doc,
	getDocs,
	query,
	updateDoc,
	where,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { db } from "../../../config/firebase";
import BranchSelector from "../../components/form/BranchSelect";

const RootContainer = styled(Container)({
	marginTop: (theme) => theme.spacing(4),
});

const ImageContainer = styled("div")({
	position: "relative",
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	overflow: "hidden",
	marginTop: (theme) => theme.spacing(2),
});

const CarouselContainer = styled("div")({
	display: "flex",
	alignItems: "center",
	gap: "0.5rem",
	transition: "transform 0.5s ease-in-out",
	overflow: "hidden",
});

const ImagePaper = styled(Paper)({
	width: "auto",
	height: "auto",
	borderRadius: (theme) => theme.spacing(1),
});
const Image = styled("img")({
	width: "20rem",
	height: "15rem",
	objectFit: "cover",
	filter: "blur(3px)",
});

const ActiveImage = styled("img")({
	width: "25rem",
	height: "20rem",
	objectFit: "cover",
});

const PaginationDots = styled("div")({
	display: "flex",
	justifyContent: "center",
	marginTop: (theme) => theme.spacing(2),
	backgroundColor: "rgba(0,0,0,0.5)",
	padding: "1rem",
	borderRadius: "2rem",
});

const Dot = styled("span")(({ theme, active }) => ({
	height: theme.spacing(1.5),
	width: theme.spacing(1.5),
	borderRadius: "50%",
	backgroundColor: active
		? theme.palette.primary.main
		: theme.palette.grey[400],
	margin: `0 ${theme.spacing(0.5)}`,
	cursor: "pointer",
}));

const ImageUploader = () => {
	const [images, setImages] = useState([]);
	const [currentImageIndex, setCurrentImageIndex] = useState(0);
	const [error, setError] = useState("");
	const {
		company: { imagesURLs, comercioName },
		companyId,
	} = useSelector((state) => state.user);
	const [currentBranch, setCurrentBranch] = useState("Todas Las Sucursales");
	const [branchData, setBranchData] = useState({});
	const [imagesObject, setImagesObject] = useState([]);
	const handleImageChange = (e) => {
		const files = e.target.files;
		setImages((prevImages) => [...prevImages, ...files]);
	};

	const handleDelete = async (imageId) => {
		if (currentBranch !== "Todas Las Sucursales" && branchData.carrusel) {
			const imageToDelete = imagesObject.find((img) => img.id === imageId)?.url;
			if (!imageToDelete) {
				console.error("Image to delete not found");
				return;
			}
			const updatedImages = branchData.carrusel.filter(
				(url) => url !== imageToDelete
			);

			// Update Firestore
			const docRef = doc(db, "branches", currentBranch); // Ensure currentBranch is correctly set to the document ID
			try {
				await updateDoc(docRef, { carrusel: updatedImages });

				setBranchData((prevData) => ({
					...prevData,
					carrusel: updatedImages,
				}));

				setImagesObject(imagesObject.filter((img) => img.id !== imageId));
			} catch (error) {
				console.error("Error removing image: ", error);
			}
		}
	};

	const columns = [
		{ field: "id", headerName: "ID", width: 90 },
		{
			field: "url",
			headerName: "Imagen",
			width: 500,
			height: 500,
			renderCell: (params) => (
				<img src={params.value} alt="" style={{ width: 300, height: "auto" }} />
			),
		},
		{
			field: "actions",
			type: "actions",
			headerName: "Borrar Imagen",
			width: 200,
			getActions: (params) => [
				<GridActionsCellItem
					icon={<DeleteIcon />}
					label="Delete"
					onClick={() => handleDelete(params.id)}
				/>,
			],
		},
	];
	useEffect(() => {
		let newImagesObject = [];
		if (currentBranch !== "Todas Las Sucursales" && branchData.carrusel) {
			newImagesObject = branchData.carrusel?.map((url, index) => ({
				id: index + 1, // Adding 1 so the ID starts from 1 instead of 0
				url: url,
			}));
		} else {
			newImagesObject = imagesURLs?.map((url, index) => ({
				id: index + 1, // Adding 1 so the ID starts from 1 instead of 0
				url: url,
			}));
		}
		setImagesObject(newImagesObject); // Now this state update is controlled
	}, [branchData.carrusel, currentBranch, imagesURLs]);

	const handleImageUpload = async () => {
		const formData = new FormData();
		images.forEach((image) => {
			formData.append("imagenes[]", image);
		});

		// Update to match backend processing
		formData.append(
			"comercio",
			comercioName.trim().replace(/\W/g, "").toLowerCase()
		);

		try {
			const res = await axios.post(
				"https://api.nexo.com.pa/addslider/v1/",
				formData,
				{
					headers: {
						"Content-Type": "multipart/form-data",
					},
				}
			);

			// Assuming res.data is an array of image URLs
			if (currentBranch === "Todas Las Sucursales") {
				const col = collection(db, "branches");

				const q = query(col, where("companyId", "==", companyId));

				const querySnapshot = await getDocs(q);
				querySnapshot.forEach((docSnapshot) => {
					const branchDocRef = doc(db, "branches", docSnapshot.id);
					updateDoc(branchDocRef, {
						carrusel: arrayUnion(...res.data.map((image) => image.url)), //
					});
				});
				const allRef = doc(db, "comercios", companyId);
				updateDoc(allRef, {
					imageURLs: arrayUnion(...res.data.map((image) => image.url)),
				});
			} else {
				const ref = doc(db, "branches", currentBranch);
				updateDoc(ref, {
					carrusel: arrayUnion(...res.data.map((image) => image.url)),
				});
				const allRef = doc(db, "comercios", companyId);
				updateDoc(allRef, {
					imageURLs: arrayUnion(...res.data.map((image) => image.url)),
				});
			}

			const errors = res.data.filter((response) => response.error);
			if (errors?.length) {
				// Show the first error for simplicity
				alert(`Error: ${errors[0].error}`);
			} else {
				alert("Images uploaded successfully!");
				setImages([]); // Clear the selected images after upload
			}
		} catch (error) {
			console.error(
				"Error handling image upload:",
				error.response?.data || error.message
			);
			setError(
				`Error handling image upload: ${error.response?.data.error || error.message}`
			);
		}
	};

	const handlePrevClick = () => {
		setCurrentImageIndex(
			(prevIndex) => (prevIndex - 1 + images?.length) % images?.length
		);
	};

	const handleNextClick = () => {
		setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images?.length);
	};

	const handleDotClick = (index) => {
		setCurrentImageIndex(index);
	};
	function CustomNoRowsOverlay() {
		return (
			<Box
				sx={{
					height: "100%",
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
					gap: 2,
				}}
			>
				<Typography variant="subtitle1">No images found</Typography>
				<Typography variant="body2">Upload images to get started</Typography>
				<Button
					variant="contained"
					color="primary"
					startIcon={<AddIcon />}
					onClick={() => document.getElementById("image-upload").click()}
				>
					Upload Images
				</Button>
			</Box>
		);
	}

	return (
		<RootContainer>
			<BranchSelector
				setBranchData={setBranchData}
				setCurrentBranch={setCurrentBranch}
				all={true}
			/>
			<Typography variant="h4" mt="5rem" align="center" gutterBottom>
				Image Uploader
			</Typography>
			<form
				style={{
					display: "flex",
					flexDirection: "column",
					alignContent: "center",
					alignItems: "center",
					gap: "2rem",
					marginBottom: "5rem",
				}}
				encType="multipart/form-data"
			>
				<input
					accept="image/jpg,image/jpeg,image/png"
					style={{ display: "none" }}
					id="image-upload"
					type="file"
					multiple
					onChange={handleImageChange}
				/>
				<label htmlFor="image-upload">
					<Button
						variant="contained"
						color="primary"
						component="span"
						startIcon={<AddIcon />}
					>
						Add Images
					</Button>
				</label>
				<ImageContainer>
					{images[currentImageIndex] && (
						<CarouselContainer
							style={{
								marginBottom: "2rem",
							}}
						>
							{currentImageIndex - 1 >= 0 && (
								<ImagePaper>
									<Image
										src={URL.createObjectURL(
											images[
												currentImageIndex - 1 >= 0 ? currentImageIndex - 1 : 0
											]
										)}
										alt={`Image ${currentImageIndex}`}
									/>
								</ImagePaper>
							)}
							<ImagePaper>
								<ActiveImage
									src={URL.createObjectURL(images[currentImageIndex])}
									alt={`Image ${currentImageIndex + 1}`}
								/>
							</ImagePaper>
							{currentImageIndex + 1 < images?.length && (
								<ImagePaper>
									<Image
										src={URL.createObjectURL(
											images[
												currentImageIndex + 1 < images?.length
													? currentImageIndex + 1
													: images?.length
											]
										)}
										alt={`Image ${currentImageIndex + 2}`}
									/>
								</ImagePaper>
							)}
						</CarouselContainer>
					)}
					<div style={{ display: "flex", alignItems: "center", gap: "2rem" }}>
						<Button onClick={handlePrevClick} disabled={images?.length <= 1}>
							Prev
						</Button>
						<PaginationDots>
							{images?.map((_, index) => (
								<Dot
									key={index}
									active={index === currentImageIndex}
									onClick={() => handleDotClick(index)}
								/>
							))}
						</PaginationDots>
						<Button onClick={handleNextClick} disabled={images?.length <= 1}>
							Next
						</Button>
					</div>
				</ImageContainer>
				<Button
					variant="contained"
					color="primary"
					onClick={handleImageUpload}
					disabled={images?.length === 0}
				>
					Upload Images
				</Button>
				{error && (
					<Typography
						variant="body2"
						color="error"
						align="center"
						marginTop={2}
					>
						{error}
					</Typography>
				)}
			</form>
			<div style={{ height: 400, width: "100%" }}>
				<DataGrid
					rows={imagesObject || []}
					columns={columns}
					pageSize={5}
					rowsPerPageOptions={[5]}
					rowLength={100}
					checkboxSelection
					disableSelectionOnClick
					rowHeight={160}
					slots={{
						noRowsOverlay: CustomNoRowsOverlay,
					}}
					sx={{
						"& .MuiDataGrid-cellCheckbox": {
							color: "secondary.main",
						},
						"& .MuiCheckbox-root.Mui-checked": {
							color: "secondary.main",
						},
						"& .MuiDataGrid-cellCheckbox svg": {
							fill: "secondary.main",
						},
					}}
				/>
			</div>
		</RootContainer>
	);
};

export default ImageUploader;
