export const importIcon = (iconName) => {
	const icons = {
		HomeIcon: require("@mui/icons-material/Home").default,
		PersonIcon: require("@mui/icons-material/Person").default,
		AccountBalanceWalletIcon:
			require("@mui/icons-material/AccountBalanceWallet").default,
		SettingsIcon: require("@mui/icons-material/Settings").default,
		QRicon: require("@mui/icons-material/QrCode2").default,
	};
	return icons[iconName] || null;
};