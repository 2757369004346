import { collection, getDocs } from "firebase/firestore";
import { db } from "../../config/firebase";

async function fetchAllComercios() {
	let queryRef = collection(db, "comercios");

	const querySnapshot = await getDocs(queryRef);
	const comercios = querySnapshot.docs.map((doc) => ({
		id: doc.id,
		...doc.data(),
	}));
	return comercios;
}

export const getAllComercios = async () => {
	try {
		const comercios = await fetchAllComercios();
		return comercios;
	} catch (err) {
		console.error("ERROR RETRIEVING DOCS:", err);
	}
};
