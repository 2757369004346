import React, { useState } from "react";
import { Button, Container, Grid, useMediaQuery } from "@mui/material";
import { useForm } from "react-hook-form";
import { Input } from "../../../components";
import { useDispatch } from "react-redux";
import { signupUser } from "../../../../store/reducers/authReducer";

const StepOne = ({ type, prevStep, nextStep }) => {
	const { handleSubmit, control } = useForm();
	const isMobile = useMediaQuery((theme) => theme.breakpoints.down("tablet"));
	const dispatch = useDispatch();
	const [errorMessage, setErrorMessage] = useState();
	const [isLoading, setIsLoading] = useState(false);

	const onSubmit = async (data) => {
		setIsLoading(true);
		try {
			const userData = {
				...data,
				worksAt: {
					type,
					role: "admin",
				},
			};
			const res = await dispatch(
				signupUser({ dataUser: userData, employee: false })
			);
			if (res.type === "auth/signupUser/rejected") {
				setErrorMessage(res.payload);
				setIsLoading(false);
			} else {
				nextStep();
				setIsLoading(false);
			}
		} catch (err) {
			setErrorMessage(err);
		}
	};

	return (
		<Container
			sx={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				justifyContent: "center",
				gap: "2rem",
			}}
		>
			<form
				onSubmit={handleSubmit(onSubmit)}
				style={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				<Grid container justifyContent="center" spacing={2}>
					<Grid item>
						<Input
							width="15rem"
							title="First Name"
							type="text"
							name="firstName"
							control={control}
						/>
					</Grid>
					<Grid item>
						<Input
							width="15rem"
							title="Last Name"
							type="text"
							name="lastName"
							control={control}
						/>
					</Grid>
					<Grid item>
						<Input
							width="15rem"
							title="Email Address"
							type="text"
							name="email"
							control={control}
						/>
					</Grid>
					<Grid item>
						<Input
							width="15rem"
							title="Password"
							type="password"
							name="password"
							control={control}
						/>
					</Grid>
					<Grid item>
						<Input
							width="15rem"
							title="Numero de Telefono"
							type="tel"
							name="phone"
							control={control}
						/>
					</Grid>
				</Grid>
				<Container
					sx={{
						display: "flex",
						flexDirection: isMobile ? "column" : "row",
						alignItems: "center",
						justifyContent: "center",
						gap: "10px",
						marginTop: "20px",
						width: "100%",
					}}
				>
					<Button
						variant="contained"
						color="primary"
						onClick={() => prevStep()}
					>
						Previous
					</Button>
					<Button type="submit" variant="contained" color="primary">
						Next
					</Button>
					{isLoading && <p>Loading...</p>}
				</Container>
				{errorMessage && <p>{errorMessage}</p>}
			</form>
		</Container>
	);
};

export default StepOne;
