import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../config/firebase";

async function fetchEmployees(id) {
	let queryRef = collection(db, "employees");

	queryRef = query(queryRef, where("companyId", "==", id));

	const querySnapshot = await getDocs(queryRef);
	const employees = querySnapshot.docs.map((doc) => ({
		id: doc.id,
		...doc.data(),
	}));

	return employees;
}

export const getEmployees = async ({ params }) => {
	try {
		return fetchEmployees(params.id);
	} catch (err) {
		console.error("ERROR RETRIEVING DOCS:", err);
	}
};
