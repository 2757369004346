import React, { useEffect, useMemo, useState } from "react";
import {
	Container,
	Typography,
	Button,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Box,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { updateCompany } from "../../../../store/reducers/userReducer";
import { useSelector, useDispatch } from "react-redux";
import { useSimpleCollection } from "../../../hooks/crud/useCollection";
import { LoadingState } from "../../../components";

const ManageSectors = ({ count, companyId }) => {
	const { sectors } = useSelector((state) => state.user.company);
	const { documents } = useSimpleCollection("employees", [
		"companyId",
		"==",
		companyId,
	]);
	const employees = useMemo(() => documents, [documents]);
	const dispatch = useDispatch();
	const existingSectors = useSelector((state) => state.user.company.sectors);

	const [expandedPanel, setExpandedPanel] = useState(null);
	const [missingSectors, setMissingSectors] = useState({
		sector: "",
	});

	const handleClickRemove = (label) => {
		const updatedSectors = sectors.filter((sector) => sector.label !== label);
		dispatch(
			updateCompany({ companyId, newData: { sectors: updatedSectors } })
		);
	};

	const handleClickAdd = (label) => {
		const updatedSectors = [...sectors, { label, value: label.toLowerCase() }];
		dispatch(
			updateCompany({ companyId, newData: { sectors: updatedSectors } })
		);
	};

	useEffect(() => {
		if (employees && sectors.length !== 0) {
			const employeeSectors = employees.map((employee) => employee.sector);

			setMissingSectors(
				employeeSectors.filter(
					(sector) => !existingSectors.some((s) => s.label === sector)
				)
			);
			if (sectors.length === 0) {
				return (
					<Box>
						<LoadingState />
					</Box>
				);
			}
		}
	}, [employees, existingSectors, sectors]);

	return (
		<Container
			sx={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "start",
				gap: "1rem",
				marginTop: "15px",
			}}
		>
			<Accordion
				expanded={expandedPanel === "panel1"}
				onChange={() =>
					setExpandedPanel((prev) => (prev === "panel1" ? null : "panel1"))
				}
			>
				<AccordionSummary expandIcon={<ExpandMoreIcon />}>
					<Typography variant="h6">Existing Sectors</Typography>
				</AccordionSummary>
				<AccordionDetails>
					{sectors.map((companySector, i) => (
						<Container
							key={i}
							sx={{
								display: "flex",
								alignItems: "space-between",
								justifyContent: "space-between",
								textAlign: "left",
							}}
						>
							<Typography variant="h6">{count[companySector.label]}</Typography>
							<Typography variant="h6">{companySector.label}</Typography>
							{companySector.label && (
								<Button
									variant="outlined"
									color="secondary"
									sx={{
										padding: "2px",
									}}
									onClick={() => handleClickRemove(companySector.label)}
								>
									Remove
								</Button>
							)}
						</Container>
					))}
				</AccordionDetails>
			</Accordion>

			<Accordion
				expanded={expandedPanel === "panel2"}
				onChange={() =>
					setExpandedPanel((prev) => (prev === "panel2" ? null : "panel2"))
				}
			>
				<AccordionSummary expandIcon={<ExpandMoreIcon />}>
					<Typography variant="h6">Missing Sectors</Typography>
				</AccordionSummary>
				<AccordionDetails>
					{missingSectors.map((sector, i) => (
						<Container
							key={i}
							sx={{
								display: "flex",
								alignItems: "space-between",
								justifyContent: "space-between",
								textAlign: "left",
							}}
						>
							<Typography variant="h6">{sector}</Typography>
							{missingSectors && (
								<Button
									variant="outlined"
									color="primary"
									sx={{
										padding: "2px",
									}}
									onClick={() => handleClickAdd(sector)}
								>
									Add
								</Button>
							)}
						</Container>
					))}
				</AccordionDetails>
			</Accordion>
		</Container>
	);
};

export default ManageSectors;
