import React from "react";
import { forwardRef } from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";

const Alert = forwardRef(function Alert(props, ref) {
	return (
		<MuiAlert
			elevation={6}
			width="20px"
			ref={ref}
			variant="filled"
			{...props}
		/>
	);
});

const CustomizedSnackbar = ({ type, message, open, handleClose }) => {
	return (
		<Snackbar
			anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
			open={open}
			autoHideDuration={3000}
			onClose={handleClose}
			width={20}
		>
			<Alert onClose={handleClose} severity={type} sx={{ width: "100%" }}>
				{message}
			</Alert>
		</Snackbar>
	);
};
export default CustomizedSnackbar;
