import React, { useState } from "react";
import { useForm } from "react-hook-form";
import {
	Button,
	Container,
	Grid,
	Typography,
	useMediaQuery,
} from "@mui/material";
import { Input } from "../../../components";
import { useDispatch, useSelector } from "react-redux";
import { signupCompany } from "../../../../store/reducers/authReducer";
import LocationInput from "../../../components/form/LocationInput";
import { useNavigate } from "react-router-dom";

const StepTwoCompany = ({ nextStep, prevStep, setCompanySignUp }) => {
	const [coordinates, setCoordinates] = useState({});
	const { control, handleSubmit } = useForm();
	const [errorMessage, setErrorMessage] = useState(null);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);
	const { userId } = useSelector((state) => state.auth);
	const isMobile = useMediaQuery((theme) => theme.breakpoints.down("tablet"));

	const onSubmit = async (data) => {
		try {
			setLoading(true);

			const companyData = await {
				...data,
				allocationData: {},
				totalCredits: 0,
				coordinates,
			};
			const actionResult = await dispatch(
				signupCompany({ companyData, userId })
			);

			if (signupCompany.fulfilled.match(actionResult)) {
				nextStep();
			} else {
				setErrorMessage(actionResult.payload);
			}
		} catch (err) {
			console.error(err);
		} finally {
			setLoading(false);
		}
	};

	return (
		<Container
			sx={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				justifyContent: "center",
				gap: "2rem",
			}}
		>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Grid container justifyContent={"center"} spacing={2}>
					<Grid item>
						<Input
							width="15rem"
							title="Nombre de Compañia"
							type="text"
							name="companyName"
							control={control}
						/>
					</Grid>
					<Grid item>
						<Input
							width="15rem"
							title="RUC"
							type="text"
							name="ruc"
							control={control}
						/>
					</Grid>
					<Grid item>
						<Input
							width="15rem"
							title="Industria"
							type="text"
							name="industry"
							control={control}
						/>
					</Grid>
					<Grid item>
						<Input
							width="15rem"
							title="Nombre Completo del CEO"
							type="text"
							name="ceoName"
							control={control}
						/>
					</Grid>
					<Grid item>
						<Input
							width="15rem"
							title="Numero de Telefono"
							type="tel"
							name="companyPhone"
							control={control}
						/>
					</Grid>
					<Grid item>
						<LocationInput setCoordinates={setCoordinates} name="address" />
					</Grid>
				</Grid>
				<Container
					sx={{
						display: "flex",
						flexDirection: isMobile ? "column" : "row",
						alignItems: "center",
						justifyContent: "center",
						gap: "10px",
						marginTop: "20px",
						width: "100%",
					}}
				>
					<Button
						variant="contained"
						color="primary"
						onClick={() => navigate("/signup")}
					>
						Previous
					</Button>
					<Button type="submit" variant="contained" color="primary">
						Next
					</Button>
					{errorMessage && (
						<Typography color="error">{errorMessage}</Typography>
					)}
					{loading && <Typography color="error">loading</Typography>}
				</Container>
			</form>
		</Container>
	);
};

export default StepTwoCompany;
