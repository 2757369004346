import React from "react";
import { Button, Modal, Grid } from "@mui/material";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
	addEmployeeAsync,
	getCompany,
} from "../../../store/reducers/userReducer";
import Input, { SelectCreateField } from "../form/Input";
// import LoadingState from "../state/LoadingState";fcompa
// import { sendSignInLinkToEmail } from "firebase/auth";

export const AddEmployeesModal = ({ closeModal, companyId }) => {
	const { control, handleSubmit, reset } = useForm();
	const dispatch = useDispatch();
	const company = useSelector(getCompany);

	const onSubmit = async (data) => {
		try {
			const creditAmount = await company.allocationData[data.sector];
			const employeeData = {
				...data,
				companyId,
				credits: creditAmount,
			};

			const actionResult = await dispatch(addEmployeeAsync(employeeData));

			console.log(actionResult);
			if (addEmployeeAsync.fulfilled.match(actionResult)) {
				reset();
				closeModal();
			}
		} catch (error) {
			console.error("Error adding employee:", error);
		}
	};

	return (
		<Modal open onClose={closeModal} sx={{ width: "100%" }}>
			<form
				onSubmit={handleSubmit(onSubmit)}
				style={{
					color: "white",
					display: "flex",
					flexDirection: "column",
					gap: "2rem",
					margin: "200px 400px",
					alignItems: "center",
					alignContent: "center",
					justifyContent: "center",
					backgroundColor: "white",
					borderRadius: "12px",
					padding: "2rem",
				}}
			>
				<Grid container justifyContent="center" spacing={2}>
					<Grid item>
						<Input
							width="15rem"
							title="First Name"
							type="text"
							name="firstName"
							control={control}
						/>
					</Grid>
					<Grid item>
						<Input
							width="15rem"
							title="Last Name"
							type="text"
							name="lastName"
							control={control}
						/>
					</Grid>
					<Grid item>
						<Input
							width="15rem"
							title="Email address"
							type="email"
							name="email"
							control={control}
						/>
					</Grid>
				</Grid>
				<div
					style={{ display: "flex", justifyContent: "center", gap: "1.2rem" }}
				>
					<Button type="submit">Add Employee</Button>
					<Button onClick={closeModal}>Close</Button>
				</div>
			</form>
		</Modal>
	);
};

// export default AddEmployeesModal;
