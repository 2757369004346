import { collection, getDocs } from "firebase/firestore";
import { db } from "../../config/firebase";

async function fetchAllCompanies() {
	let queryRef = collection(db, "companies");

	const querySnapshot = await getDocs(queryRef);
	const companies = querySnapshot.docs.map((doc) => ({
		id: doc.id,
		...doc.data(),
	}));
	return companies;
}

export const getAllCompanies = async () => {
	try {
		const companies = await fetchAllCompanies();
		return companies;
	} catch (err) {
		console.error("ERROR RETRIEVING DOCS:", err);
	}
};
