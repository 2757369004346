import React from "react";
import { useState, useCallback } from "react";
import { Button, Modal, TextField } from "@mui/material";
import { db } from "../../../config/firebase";
import {
	collection,
	addDoc,
	updateDoc,
	Timestamp,
	doc,
} from "firebase/firestore";
import { useDispatch, useSelector } from "react-redux";
import { fetchTransactions } from "../../../store/reducers/transactionsReducer";
import BranchSelector from "../form/BranchSelect";

export const AddTransactionModal = ({ closeModal }) => {
	const [transactionData, setTransactionData] = useState({});
	const [currentBranch, setCurrentBranch] = useState({});
	const [branchData, setBranchData] = useState({});
	const { companyId, company } = useSelector((state) => state.user);
	const dispatch = useDispatch();

	const { comision, total, ventaNeta } = company.periodo?.[2024]?.enero.s1 || {
		comision: 0,
		total: 0,
		ventaNeta: 0,
	};
	const handleSubmit = useCallback(
		async (e) => {
			e.preventDefault();
			const ref = collection(db, "transactions");
			const date = new Date(transactionData.date + "T00:00:00");
			const timestamp = Timestamp.fromDate(date);
			try {
				await addDoc(ref, {
					amount: transactionData.amount,
					createdAt: timestamp,
					isPaymentMade: true,
					paymentAt: timestamp,
					paymentInfo: {
						companyId: "sCMe4YCJPt4x8U8tG5uH",
						paymentMethod: "nexo",
						userID: "4Lq6fC6D0mR896vPWnDvzcqhtkj1",
						userPayment: "Matthew Allen",
					},
					comercioDetails: {
						branchId: currentBranch,
						branchName: branchData.name,
						comercioId: companyId,
						comercioName: company.comercioName,
					},
					scannigAt: "false",
					scanningAt: timestamp,
					userId: "UFgbdTAEN6cdoV9vCijBYDtMSfT2",
					status: "complete",
				})
					.then(() => {
						updateDoc(doc(db, "comercios", companyId), {
							periodo: {
								2024: {
									enero: {
										s1: {
											comision:
												comision + parseInt(transactionData.amount) * 0.1,
											total: total + parseInt(transactionData.amount),
											ventaNeta:
												ventaNeta + parseInt(transactionData.amount) * 0.9,
										},
									},
								},
							},
						});
					})
					.then(() => {
						dispatch(fetchTransactions({ companyId, type: "comercio" }));
					});

				setTransactionData({
					amount: 0, // Reset to 0 after successful transaction
					date: "",
				});
				closeModal();
			} catch (error) {
				console.error("Error adding transaction:", error);
			}
		},
		[
			branchData.name,
			closeModal,
			comision,
			company.comercioName,
			companyId,
			currentBranch,
			dispatch,
			total,
			transactionData.amount,
			transactionData.date,
			ventaNeta,
		]
	);

	return (
		<Modal open onClose={closeModal} sx={{ width: "100%" }}>
			<form
				style={{
					color: "white",
					display: "flex",
					flexDirection: "column",
					gap: "2rem",
					alignItems: "center",
					alignContent: "center",
					justifyContent: "center",
					backgroundColor: "white",
					borderRadius: "12px",
					padding: "2rem",
				}}
				onSubmit={handleSubmit}
			>
				<BranchSelector
					setBranchData={setBranchData}
					setCurrentBranch={setCurrentBranch}
				/>
				<TextField
					label="Amount"
					amount="amount"
					type="amount"
					value={transactionData.amount}
					onChange={(e) =>
						setTransactionData({
							...transactionData,
							amount: e.target.value,
						})
					}
				/>
				<TextField
					label="date"
					amount="date"
					type="date"
					value={transactionData.date}
					onChange={(e) => {
						setTransactionData({
							...transactionData,
							date: e.target.value,
						});
					}}
				/>
				<div
					style={{ display: "flex", justifyContent: "center", gap: "1.2rem" }}
				>
					<Button onClick={handleSubmit}>Add Transaction</Button>
					<Button onClick={closeModal}>Close</Button>
				</div>
			</form>
		</Modal>
	);
};
