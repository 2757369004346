import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";

const BranchSelector = ({
	setCurrentBranch,
	borderRadius = "5px",
	all = false,
	fullWidth = false,
}) => {
	const branches = useSelector((state) => state.branches.data);

	const handleBranchChange = (e) => {
		const selectedBranch = e.target.value;
		if (
			selectedBranch?.id?.trim() ||
			selectedBranch.trim() !== "Todas Las Sucursales"
		) {
			setCurrentBranch(selectedBranch);
		} else {
			setCurrentBranch(null);
		}
	};

	return (
		<div>
			<FormControl fullWidth>
				<InputLabel id="branch-select-label">Select Branch</InputLabel>
				<Select
					fullWidth={fullWidth}
					labelId="branch-select-label"
					id="branch-select"
					defaultValue={all ? "Todas Las Sucursales" : ""}
					onChange={handleBranchChange}
					sx={{ borderRadius, width: "15rem" }}
					label="Select Branch"
				>
					{all && (
						<MenuItem value="Todas Las Sucursales" sx={{ fontWeight: "700" }}>
							Todas Las Sucursales
						</MenuItem>
					)}
					{branches?.map((branch) => (
						<MenuItem key={branch.id} value={branch}>
							{branch.branchName}
						</MenuItem>
					))}
				</Select>
			</FormControl>
		</div>
	);
};

export default BranchSelector;
