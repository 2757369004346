import React, { useEffect, useState } from "react";
import {
	Button,
	CircularProgress,
	List,
	ListItem,
	ListItemText,
} from "@mui/material";
import axios from "axios";
import { useSelector } from "react-redux";
import DateSelector from "../../../components/form/DateSelector";

const TransactionFiles = () => {
	const [loading, setLoading] = useState(false);
	const [excelFiles, setExcelFiles] = useState([]);
	const [date, setDate] = useState({});
	const { companyId } = useSelector((state) => state.user);

	const fetchExcelFiles = async () => {
		try {
			const response = await axios.get(
				`http://127.0.0.1:5001/nexo-pty/us-central1/app/excel?comercioId=${
					companyId || ""
				}`
			);
			setExcelFiles(response.data);
		} catch (error) {
			console.error("Error fetching Excel files:", error);
		}
	};
	const handleGenerateExcel = async () => {
		try {
			setLoading(true);
			await axios.post("http://127.0.0.1:5001/nexo-pty/us-central1/app/excel", {
				comercioId: companyId || "",
				month: date.start,
			});
			fetchExcelFiles();
		} catch (error) {
			console.error("Error generating Excel file:", error);
		}
		setLoading(false);
	};

	useEffect(() => {
		fetchExcelFiles();
	});

	return (
		<div>
			<div style={{ display: "flex", gap: "2rem", alignContent: "center" }}>
				<Button onClick={handleGenerateExcel}>
					Generate Excel for {"January"}
				</Button>
				<DateSelector date={date} setDate={setDate} />
			</div>
			{loading && <CircularProgress />}
			{excelFiles.length > 0 && (
				<List>
					{excelFiles.map((file, index) => (
						<ListItem key={index}>
							<ListItemText primary={file.month} />
							<Button
								component="a"
								href={file.downloadUrl}
								target="_blank"
								download={`${file.month}_transactions.xlsx`}
							>
								Download
							</Button>
						</ListItem>
					))}
				</List>
			)}
		</div>
	);
};

export default TransactionFiles;
