import React, { useState, useMemo, useEffect } from "react";
import axios from "axios";
import {
	Button,
	Card,
	CardContent,
	Typography,
	Box,
	CircularProgress,
} from "@mui/material";
import "./styling.css";

const ReviewCard = ({ review }) => {
	return (
		<Card style={{ marginBottom: "1rem" }}>
			<CardContent>
				<Typography variant="h6">{review.reviewTitle}</Typography>
				<Typography variant="subtitle2" color="textSecondary">
					Date Published: {review.datePublished}
				</Typography>
				<Typography variant="body1">{review.reviewBody}</Typography>
				<Typography variant="caption" color="textSecondary">
					Star Ratings:
				</Typography>
				<ul
					style={{
						padding: 0,
						margin: 0,
						listStyleType: "none", // Remove list item bullets
						display: "flex", // Make the list horizontal
						alignItems: "center",
					}}
				>
					{review.starRatings.map((star, index) => (
						<li
							key={index}
							style={{
								marginRight: "5px",
								fontSize: "1rem",
								height: "auto",
								color: "#FF3357",
							}}
							dangerouslySetInnerHTML={{ __html: star }}
						/>
					))}
				</ul>
			</CardContent>
		</Card>
	);
};

const ImporReviews = () => {
	const [scrapedData, setScrapedData] = useState(null);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [success, setSuccess] = useState(false);

	useEffect(() => {
		const storedData = localStorage.getItem("scrapedData");
		if (storedData) {
			setScrapedData(JSON.parse(storedData));
		}
	}, []);

	const handleClick = async () => {
		setLoading(true);
		try {
			const response = await axios.get(
				"http://127.0.0.1:5001/nexo-pty/us-central1/app/comercios/scraping"
			);

			if (response.data.success) {
				setScrapedData(response.data);
				setSuccess(true);
			} else {
				setError(response.data.message);
			}
		} catch (error) {
			console.error("Error scraping data:", error);
		} finally {
			setLoading(false);
		}
	};

	// Memoize the scraped data
	const memoizedScrapedData = useMemo(() => scrapedData, [scrapedData]);

	return (
		<div>
			<Typography mb="2rem" variant="h2">
				Importar Reseñas
			</Typography>
			<Button
				onClick={handleClick}
				sx={{
					backgroundColor: "white",
					color: "#CC2C1E",
					boxShadow: "0 0 0.5rem 0 rgba(0,0,0,0.5)",
					marginBottom: "2rem",
				}}
				endIcon={
					<img
						src="/assets/degustacompleto.png"
						alt="icon"
						style={{
							width: "5rem",
							height: "2rem",

							objectFit: "cover",
						}}
					/>
				}
			>
				Import Reviews From
			</Button>
			{loading && (
				<Box sx={{ display: "flex", alignItems: "center" }}>
					<Box sx={{ width: "100%", mr: 1 }}>
						<CircularProgress />
					</Box>
				</Box>
			)}
			{error && <div>{error}</div>}
			{/* Render or use the memoized data */}
			{memoizedScrapedData && (
				<div mt="2rem">
					<Typography variant="h3" gutterBottom>
						Scraped Data:
					</Typography>
					<div
						style={{
							maxHeight: 400,
							overflowY: "auto",
							margin: " 0  10rem",
							marginTop: "2rem",
						}}
					>
						{memoizedScrapedData.data.map((review, index) => (
							<ReviewCard key={index} review={review} />
						))}
					</div>
				</div>
			)}
			{success && <Button> Quiero Agregarlas </Button>}
		</div>
	);
};

export default ImporReviews;
