import React, { useState } from "react";
import { Box, Container, useTheme } from "@mui/material";
import { tokens } from "../../../../theme";
import { LoadingState } from "../../../components";
import { useImagePreloader } from "../../../hooks";
import StepOne from "./StepOne";
import StepTwoCompany from "../signup_companies/StepTwoCompany";
import StepTwoComercios from "../signup_comercios/StepTwoComercio";
import StepSuccess from "./StepSuccess";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import SignUpError from "./SignUpError";

const SignUpLayout = () => {
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);
	const { type } = useParams();
	const imageUrls = ["/assets/signupimage.jpeg", "/assets/logonexo.png"];
	const imagesLoaded = useImagePreloader(imageUrls);
	const [step, setStep] = useState(1);
	const { error } = useSelector((state) => state.auth);
	const navigate = useNavigate();

	const nextStep = () => {
		setStep((s) => s + 1);
	};

	const prevStep = () => {
		if (step === 1) {
			navigate(-1);
			setStep(step - 1);
		} else {
			setStep(step - 1);
		}
	};

	const renderForm = () => {
		switch (step) {
			case 2:
				return type === "company" ? (
					<StepTwoCompany nextStep={nextStep} />
				) : (
					<StepTwoComercios nextStep={nextStep} prevStep={prevStep} />
				);
			case 1:
				return <StepOne type={type} nextStep={nextStep} prevStep={prevStep} />;
			case 3:
				return <StepSuccess />;
			default:
				return null;
		}
	};

	return (
		<Box
			sx={{
				display: "flex",
				height: "auto",
				backgroundColor: colors.redAccent[900],
				alignItems: "center",
				justifyContent: "space-between",
			}}
		>
			{!imagesLoaded && <LoadingState size="lg" />}
			{imagesLoaded && (
				<Box
					sx={{
						display: "flex",
						height: "100vh",
						width: "100%",
						backgroundColor: colors.redAccent[900],
						alignItems: "center",
					}}
				>
					<Box
						sx={{
							height: "100%",
							width: "50%",
							display: {
								desktoplg: "block",
								desktop: "block",
								tablet: "none",
								phone: "none",
							},
						}}
					>
						<img
							src={
								type === "company"
									? "/assets/company-signup-image.jpg"
									: "/assets/signupimage.jpeg"
							}
							alt="logo"
							style={{
								height: "100%",
								width: "100%",
								objectFit: "cover",
							}}
							loading="lazy"
						/>
					</Box>
					<Container
						sx={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							justifyContent: "center",
							width: "50%",
							gap: "2rem",
						}}
					>
						<img
							src="/assets/logonexo.png"
							alt="logo"
							style={{
								height: "auto",
								maxWidth: "250px",
							}}
						/>

						{renderForm()}
					</Container>
				</Box>
			)}
		</Box>
	);
};

export default SignUpLayout;
