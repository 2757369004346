// src/components/UserSettingsForm.js
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { updateUser } from "../../../store/reducers/userReducer";
import { updateCredentials } from "../../../store/reducers/authReducer";
import { Box, Typography, useMediaQuery } from "@mui/material";

const UserSettingsForm = () => {
	const dispatch = useDispatch();
	const { userId, email: userCurrentEmail } = useSelector(
		(state) => state.auth
	);
	const isMobile = useMediaQuery((theme) => theme.breakpoints.down("tablet"));
	const user = useSelector((state) => state.user.data);
	const [privateFormData, setPrivateFormData] = useState({
		email: user.email || "",
		currentPassword: "",
		newPassword: "",
		confirmPassword: "",
	});

	// Public information form state
	const [publicFormData, setPublicFormData] = useState({
		firstName: user.firstName || "",
		lastName: user.lastName || "",
		phone: user.phone || "",
	});

	// Form error state
	const [formErrors, setFormErrors] = useState({});

	const handlePrivateInputChange = (e) => {
		setPrivateFormData({ ...privateFormData, [e.target.name]: e.target.value });
		setFormErrors({ ...formErrors, [e.target.name]: "" });
	};

	const handlePublicInputChange = (e) => {
		setPublicFormData({ ...publicFormData, [e.target.name]: e.target.value });
	};

	// Validate private information form
	const validatePrivateForm = () => {
		let errors = {};

		if (!privateFormData.email.trim()) {
			errors.email = "Email is required";
		} else if (!/\S+@\S+\.\S+/.test(privateFormData.email)) {
			errors.email = "Email address is invalid";
		}

		if (
			privateFormData.newPassword.trim() !==
			privateFormData.confirmPassword.trim()
		) {
			errors.confirmPassword = "Passwords do not match";
		}

		setFormErrors(errors);

		return Object.keys(errors).length === 0;
	};

	// Validate public information form
	const validatePublicForm = () => {
		let errors = {};

		if (!publicFormData.firstName.trim()) {
			errors.firstName = "First Name is required";
		}

		if (!publicFormData.lastName.trim()) {
			errors.lastName = "Last Name is required";
		}

		setFormErrors(errors);

		return Object.keys(errors).length === 0;
	};

	const handlePrivateFormSubmit = (e) => {
		e.preventDefault();
		try {
			if (validatePrivateForm()) {
				dispatch(
					updateCredentials({
						userId,
						oldPassword: privateFormData.currentPassword,
						oldEmail: user.email,
						newEmail:
							privateFormData.email === userCurrentEmail
								? null
								: privateFormData.email,
						newPassword: privateFormData.newPassword,
					})
				);
			} else {
				console.log(formErrors);
			}
		} catch (err) {
			console.error(err);
			alert("Contraseña no pudo ser actualizada" + err);
		} finally {
			alert("Contraseña actualizada exitosamente");
			setPrivateFormData({
				email: user.email || "",
				currentPassword: "",
				newPassword: "",
				confirmPassword: "",
			});
		}
	};
	const handlePublicFormSubmit = (e) => {
		e.preventDefault();
		try {
			if (validatePublicForm()) {
				dispatch(updateUser({ userId, newData: publicFormData }));
			}
		} catch (err) {
			console.log(err);
		} finally {
			setPublicFormData({
				firstName: user.firstName || "",
				lastName: user.lastName || "",
				phone: user.phone || "",
			});
		}
	};

	return (
		<Box
			sx={{
				display: "flex",
				justifyContent: "start",
				flexDirection: isMobile ? "column" : "",
				gap: 4,
				alignItems: isMobile ? "center" : "start",
				p: 4,
				borderRadius: 2,
				boxShadow: 3,
				bgcolor: "background.paper",
				"& .MuiTextField-root": { m: 1, width: "auto" },
			}}
		>
			{/* Private Information Form */}
			<form
				onSubmit={handlePrivateFormSubmit}
				style={{
					display: "flex",
					alignItems: "center",
					flexDirection: "column",
					gap: "0.5rem",
				}}
			>
				<Typography variant="h4"> Email and Password </Typography>
				<TextField
					label="Email"
					name="email"
					autoComplete="username"
					type="email"
					value={privateFormData.email}
					onChange={handlePrivateInputChange}
					fullWidth
					margin="normal"
					error={!!formErrors.email}
					helperText={formErrors.email}
				/>
				<TextField
					label="Current Password"
					autoComplete="current-password"
					name="currentPassword"
					type="password"
					value={privateFormData.currentPassword}
					onChange={handlePrivateInputChange}
					fullWidth
					margin="normal"
				/>
				<TextField
					label="New Password"
					autoComplete="new-password"
					name="newPassword"
					type="password"
					value={privateFormData.newPassword}
					onChange={handlePrivateInputChange}
					fullWidth
					margin="normal"
				/>
				<TextField
					label="Confirm New Password"
					name="confirmPassword"
					autoComplete="new-password"
					type="password"
					value={privateFormData.confirmPassword}
					onChange={handlePrivateInputChange}
					fullWidth
					margin="normal"
					error={!!formErrors.confirmPassword}
					helperText={formErrors.confirmPassword}
				/>
				<Button type="submit" variant="contained" color="primary">
					Save Private Information
				</Button>
			</form>
			<form
				onSubmit={handlePublicFormSubmit}
				style={{
					display: "flex",
					alignItems: "center",
					flexDirection: "column",
					gap: "0.5rem",
				}}
			>
				<Typography variant="h4"> User Information </Typography>
				<TextField
					label="First Name"
					name="firstName"
					value={publicFormData.firstName}
					onChange={handlePublicInputChange}
					fullWidth
					margin="normal"
					error={!!formErrors.firstName}
					helperText={formErrors.firstName}
				/>
				<TextField
					label="Last Name"
					name="lastName"
					value={publicFormData.lastName}
					onChange={handlePublicInputChange}
					fullWidth
					margin="normal"
					error={!!formErrors.lastName}
					helperText={formErrors.lastName}
				/>
				<TextField
					label="Phone"
					name="phone"
					value={publicFormData.phone}
					onChange={handlePublicInputChange}
					fullWidth
					margin="normal"
				/>
				<Button type="submit" variant="contained" color="primary">
					Save Public Information
				</Button>
			</form>
		</Box>
	);
};

export default UserSettingsForm;
