import React, { useState, useEffect } from "react";
import {
	FormControl,
	TextField,
	Box,
	Switch,
	Button,
	Typography,
	Grid,
	createTheme,
	ThemeProvider,
} from "@mui/material";
import BranchSelect from "../../../components/form/BranchSelect";
import { doc, onSnapshot, updateDoc } from "@firebase/firestore";
import { db } from "../../../../config/firebase";
import { useTheme } from "@emotion/react";

const PromoSettingComponent = () => {
	const theme = useTheme();
	const [selectedBranch, setCurrentBranch] = useState("");
	const [promoData, setPromoData] = useState({
		lunes: { descuento: "", active: false },
		martes: { descuento: "", active: false },
		miercoles: { descuento: "", active: false },
		jueves: { descuento: "", active: false },
		viernes: { descuento: "", active: false },
		sabado: { descuento: "", active: false },
		domingo: { descuento: "", active: false },
	});
	const [isSubmitting, setIsSubmitting] = useState(false);

	useEffect(() => {
		if (selectedBranch) {
			const docRef = doc(db, "branches", selectedBranch.branchId);

			const unsubscribe = onSnapshot(docRef, (docSnapshot) => {
				if (docSnapshot.exists()) {
					const data = docSnapshot.data().promo || {};

					setPromoData({
						lunes: {
							descuento: data.lunes?.descuento || "",
							active: data.lunes?.active || false,
						},
						martes: {
							descuento: data.martes?.descuento || "",
							active: data.martes?.active || false,
						},
						miercoles: {
							descuento: data.miercoles?.descuento || "",
							active: data.miercoles?.active || false,
						},
						jueves: {
							descuento: data.jueves?.descuento || "",
							active: data.jueves?.active || false,
						},
						viernes: {
							descuento: data.viernes?.descuento || "",
							active: data.viernes?.active || false,
						},
						sabado: {
							descuento: data.sabado?.descuento || "",
							active: data.sabado?.active || false,
						},
						domingo: {
							descuento: data.domingo?.descuento || "",
							active: data.domingo?.active || false,
						},
					});
				}
			});

			return () => unsubscribe();
		}
	}, [selectedBranch]);

	const handleInputChange = (day, key, value) => {
		setPromoData((prevData) => ({
			...prevData,
			[day]: { ...prevData[day], [key]: value },
		}));
	};

	const handleToggleChange = (day) => {
		setPromoData((prevData) => ({
			...prevData,
			[day]: { ...prevData[day], active: !prevData[day].active },
		}));
	};

	const handleSubmit = async () => {
		setIsSubmitting(true);
		try {
			const formattedData = Object.keys(promoData).reduce(
				(acc, day) => ({
					...acc,
					[day]: {
						descuento: promoData[day].descuento,
						active: promoData[day].active,
					},
				}),
				{}
			);

			await updateDoc(doc(db, "branches", selectedBranch.branchId), {
				promo: formattedData,
			});
		} catch (error) {
			console.error("Failed to update document:", error);
		} finally {
			setIsSubmitting(false);
		}
	};

	return (
		<ThemeProvider theme={theme}>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					gap: 4,
					alignItems: "start",
				}}
			>
				<Typography variant="h5" component="h1" gutterBottom>
					Escoge tus Promociones
				</Typography>
				<FormControl fullWidth margin="normal">
					<BranchSelect setCurrentBranch={setCurrentBranch} />
				</FormControl>
				<Grid container spacing={2}>
					{Object.keys(promoData).map((day) => (
						<Grid item phone={12} tablet={6} desktop={4} key={day}>
							<TextField
								label={`Promo para el ${day}`}
								variant="outlined"
								value={promoData[day].descuento}
								onChange={(e) =>
									handleInputChange(day, "descuento", e.target.value)
								}
								fullWidth
							/>
							<Switch
								checked={promoData[day].active}
								onChange={() => handleToggleChange(day)}
							/>
						</Grid>
					))}
				</Grid>
				<Button onClick={handleSubmit} disabled={isSubmitting} sx={{ mt: 2 }}>
					{isSubmitting ? "Actualizando..." : "Guardar"}
				</Button>
			</Box>
		</ThemeProvider>
	);
};

export default PromoSettingComponent;
