// src/components/Settings.js
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Box, Typography } from "@mui/material";
import { updateComercio } from "../../../../store/reducers/userReducer";
import PhoneInput from "../../../components/form/PhoneInput";

const ComercioSettings = () => {
	const dispatch = useDispatch();
	// const { company, companyId } = useSelector((state) => state.user);
	const [formData, setFormData] = useState({});
	const [error, setError] = useState(null);

	const handleInputChange = (e) => {
		setFormData({ ...formData, [e.target.name]: e.target.value });
	};

	const handlePhoneNumberChange = (formattedNumber) => {
		setFormData({ ...formData, comercioPhone: formattedNumber });
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		const res = await dispatch(
			updateComercio({ companyId: "4BfakBpshq2c0lSWXlQu", newData: formData })
		);
		if (updateComercio.fulfilled.match(res)) {
			setFormData({});
		} else {
			setError("Wrong Input");
		}
	};

	return (
		<Box
			sx={{
				display: "flex",
				gap: "5rem",
				justifyContent: "start",
			}}
		>
			<form
				onSubmit={handleSubmit}
				style={{
					display: "flex",
					alignItems: "center",
					flexDirection: "column",
					gap: "0.5rem",
				}}
			>
				<Typography variant="h3">Ajustes de tu Comercio</Typography>
				<TextField
					label="Name"
					name="comercioName"
					value={formData.comercioName || ""}
					onChange={handleInputChange}
					fullWidth
					margin="normal"
				/>
				<TextField
					label="Description"
					name="description"
					multiline
					rows={4}
					value={formData.description || ""}
					onChange={handleInputChange}
					fullWidth
					margin="normal"
				/>
				<TextField
					label="Email"
					name="comercioEmail"
					value={formData.comercioEmail || ""}
					onChange={handleInputChange}
					fullWidth
					margin="normal"
					type="email"
				/>

				<PhoneInput onPhoneNumberChange={handlePhoneNumberChange} />
				<Button type="submit" variant="contained" color="primary">
					Save Comercio Settings
				</Button>

				{error && (
					<Typography variant="p" color="error">
						{error}
					</Typography>
				)}
			</form>
		</Box>
	);
};

export default ComercioSettings;
