import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
	collection,
	query,
	where,
	getDocs,
	updateDoc,
	arrayUnion,
	doc,
	addDoc,
	deleteDoc,
	getDoc,
	arrayRemove,
} from "firebase/firestore";

import { db } from "../../config/firebase";

const initialState = {
	isFetching: false,
	error: null,
	data: {},
};

export const addBranch = createAsyncThunk(
	"comercio/addBranch",
	async ({ companyId, branchData }, { rejectWithValue }) => {
		try {
			const branchesRef = collection(db, "branches");
			const newBranchRef = await addDoc(branchesRef, branchData);
			if (!newBranchRef) {
				return rejectWithValue({ message: "Error adding it" });
			}

			const companyRef = doc(db, "comercios", companyId);
			updateDoc(companyRef, {
				branches: arrayUnion({
					id: newBranchRef.id,
					name: branchData.branchName,
				}),
			});
			const bRef = doc(db, "branches", newBranchRef.id);

			updateDoc(bRef, {
				branchId: newBranchRef.id,
			});

			return newBranchRef.id;
		} catch (error) {
			console.error("Error adding branch:", error);
			return rejectWithValue(error);
		}
	}
);

export const fetchBranches = createAsyncThunk(
	"braches/fetch",
	async (companyId, { rejectWithValue }) => {
		try {
			const branchesCollection = collection(db, "branches");
			const branchesQuery = query(
				branchesCollection,
				where("companyId", "==", companyId)
			);
			const snapshot = await getDocs(branchesQuery);
			const branchesData = snapshot.docs.map((doc) => ({
				id: doc.id,
				...doc.data(),
			}));
			return branchesData;
		} catch (error) {
			rejectWithValue(error);
		}
	}
);

export const updateBranch = createAsyncThunk(
	"comercio/updateBranch",
	async (
		{ companyId, branchId, branchData },
		{ dispatch, rejectWithValue }
	) => {
		try {
			const branchRef = doc(db, "branches", branchId);
			await updateDoc(branchRef, { ...branchData });

			return { companyId, branchId, branchData };
		} catch (error) {
			console.log(error);
			rejectWithValue(["Error updating branch:", error]);
		}
	}
);

const branchesSlice = createSlice({
	name: "branches",
	initialState,
	reducers: {
		clearbranchess: (state) => {
			Object.assign(state, {
				isFetching: false,
				error: null,
				data: {},
			});
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchBranches.pending, (state) => {
				state.isFetching = true;
				state.error = null;
			})
			.addCase(fetchBranches.fulfilled, (state, action) => {
				state.isFetching = false;
				state.data = action.payload;
			})
			.addCase(fetchBranches.rejected, (state, action) => {
				state.isFetching = false;
				state.error = action.error.message;
			})
			.addCase(addBranch.fulfilled, (state, action) => {
				// Add a new branch to the state
				state.isFetching = false;
				state.error = null;
				// Add your logic to update the state with the new branch data
			})
			.addCase(addBranch.rejected, (state, action) => {
				// Update the branch in the state
				state.error = action.error;
				// Add your logic to update the state with the new branch data
			})
			.addCase(updateBranch.pending, (state, action) => {
				state.isFetching = true;
			})
			.addCase(updateBranch.fulfilled, (state, action) => {
				state.isFetching = false;
				state.error = null;
			})
			.addCase(updateBranch.rejected, (state, action) => {
				// Update the branch in the state
				state.isFetching = false;
				state.error = action.payload;
			});
	},
});

export const { clearbranches } = branchesSlice.actions;
export default branchesSlice.reducer;
