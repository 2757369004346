import React, { useState } from "react";
import {
	Grid,
	Select,
	MenuItem,
	InputLabel,
	FormControl,
	Chip,
	OutlinedInput,
} from "@mui/material";
import { useSelector } from "react-redux";

const SubCategroyInput = ({
	selectedSubcategories,
	setSelectedSubcategories,
	category,
}) => {
	const subCategories = useSelector(
		(state) => state.user.settings.subcategories
	);
	const [open, setOpen] = useState(false); // Estado para controlar la apertura del Select

	const handleChange = (event) => {
		const value = event.target.value;
		// Since `value` is always an array with the `multiple` attribute, we can set it directly
		if (value.length <= 5) {
			setSelectedSubcategories(value);
		} else {
			alert("No puede agregar mas de 5 categorias");
		}
		setOpen(false);
	};
	const handleDelete = (chipToDelete) => (event) => {
		event.stopPropagation();
		setOpen(false);
		setSelectedSubcategories((chips) =>
			chips.filter((chip) => chip !== chipToDelete)
		);
	};

	const handleClick = (event) => {
		setOpen(!open); // Abrir el Select cuando se hace clic en cualquier área que no sea un Chip
	};

	const handleClose = () => {
		setOpen(false); // Cerrar el Select
	};

	return (
		<FormControl sx={{ minWidth: "20rem" }} fullWidth>
			<InputLabel id="subcategories-label">Subcategorías</InputLabel>
			<Select
				labelId="subcategories-label"
				id="subcategories"
				multiple
				open={open}
				onClick={handleClick}
				fullWidth
				onClose={handleClose}
				value={selectedSubcategories}
				onChange={handleChange}
				input={
					<OutlinedInput id="select-multiple-chip" label="Subcategorías" />
				}
				renderValue={(selected) => (
					<div style={{ display: "flex", flexWrap: "wrap", gap: "5px" }}>
						{selected.map((id) => {
							let subcategory = subCategories[category][id];
							return (
								<Chip
									key={id}
									label={subcategory.nombre}
									onDelete={handleDelete(id)}
									style={{
										backgroundColor: subcategory.color,
										color: "white",
									}}
								/>
							);
						})}
					</div>
				)}
				name="subcategories"
			>
				{Object.values(subCategories[category]).map((subcategory) => (
					<MenuItem key={subcategory.id} value={subcategory.id}>
						<div
							style={{
								backgroundColor: subcategory.color,
								color: "white",
								padding: "5px",
								borderRadius: "4px",
							}}
						>
							{subcategory.nombre}
						</div>
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
};

export default SubCategroyInput;
