import { connect } from "react-redux";

function useSpreadCredits({ userData }) {
  return (
    <div>
      <p>User Data:</p>
      <pre>{JSON.stringify(userData, null, 2)}</pre>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    userData: state.auth.userData,
  };
};

export default connect(mapStateToProps)(useSpreadCredits);
