import {
	getDownloadURL,
	ref as storageRef,
	uploadBytesResumable,
} from "firebase/storage";
import { storage } from "../../../config/firebase";

export const handleImageUpload = async ({ images, path }) => {
	try {
		const imageUrls = await Promise.all(
			images.map(async (image) => {
				const imageRef = storageRef(
					storage,
					`${path + (image.name || image.title) + Date.now()}`
				);
				const uploadTask = uploadBytesResumable(imageRef, image);

				return new Promise((resolve, reject) => {
					uploadTask.on(
						"state_changed",
						(snapshot) => {
							const progress =
								(snapshot.bytesTransferred / snapshot.totalBytes) * 100;
						},
						(error) => {
							console.error("Error uploading logo:", error.message);
							reject(error);
						},
						async () => {
							const downloadURL = await getDownloadURL(imageRef);
							resolve(downloadURL);
						}
					);
				});
			})
		);

		return imageUrls;
	} catch (error) {
		console.error("Error uploading images:", error);
		throw error;
	}
};
