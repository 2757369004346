import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";

const PhoneInput = ({ onPhoneNumberChange }) => {
	const [phoneNumber, setPhoneNumber] = useState("");

	const handlePhoneNumberChange = (e) => {
		// Remove non-numeric characters
		let formattedNumber = e.target.value.replace(/[^0-9]/g, "");

		// Apply custom formatting for Panamanian numbers (+507 8 digits)
		if (formattedNumber.length <= 8) {
			formattedNumber = formattedNumber.replace(/(\d{4})(\d{4})/, "+507 $1-$2");
		} else {
			// Limit the length to 12 characters (country code, space, and 8 digits)
			formattedNumber = formattedNumber.slice(0, 12);
		}

		setPhoneNumber(formattedNumber);
		onPhoneNumberChange(formattedNumber);
	};

	return (
		<TextField
			label="Phone Number"
			value={phoneNumber || ""}
			onChange={handlePhoneNumberChange}
			fullWidth
			InputProps={{
				startAdornment: <InputAdornment position="start">+507</InputAdornment>,
			}}
		/>
	);
};

export default PhoneInput;
