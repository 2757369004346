import React, { useState } from "react";
import {
	Modal,
	Button,
	TextField,
	Typography,
	Box,
	List,
	ListItem,
	Stack,
	Divider,
} from "@mui/material";
import axios from "axios";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../../../config/firebase";
import { useSelector } from "react-redux";

export const ModalACH = ({ closeModal, handleClose, open }) => {
	const [step, setStep] = useState(1);
	const [accountData, setAccountData] = useState({
		accountNumber: "",
		amount: "",
		customerEmail: "",
		achConfirmation: "",
	});
	const {
		companyName,
		totalCredits,
		id: companyId,
	} = useSelector((state) => state.user.company);

	const formatter = new Intl.NumberFormat("en-US", {
		style: "currency",
		currency: "USD",
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	});
	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setAccountData((prevData) => ({ ...prevData, [name]: value }));
	};

	const handleSubmitStepOne = (e) => {
		e.preventDefault();
		// Aquí puedes validar los datos ingresados antes de pasar al siguiente paso
		setStep(2);
	};
	const emailHtml = `
<html>
<head>
  <style>
    body {
      font-family: Arial, sans-serif;
      line-height: 1.6;
    }
    .container {
      width: 80%;
      margin: 20px auto;
      padding: 20px;
      border: 1px solid #ddd;
      border-radius: 5px;
      background-color: #f9f9f9;
    }
    .header {
      text-align: center;
      border-bottom: 1px solid #ddd;
      padding-bottom: 10px;
    }
    .content {
      margin-top: 20px;
    }
		.logo {
      display: block;
      margin: 0 auto;
    }
  </style>
</head>
<body>
  <div class="container">
    <div class="header">
		<img src="cid:x203051" alt="Company Logo" class="logo" width="100%" height="auto" />
      <h2>Confirmacion de Transferencia</h2>
			<p>Debera ver este monto reflejado en su cuenta entre 1 a 2 dias hábiles</p>
    </div>
    <div class="content">
      <p>Account Number: ${accountData.accountNumber}</p>
      <p>Amount: ${formatter.format(accountData.amount)}</p>
      <p>ACH Confirmation Number: ${accountData.achConfirmation}</p>
      <p>Customer Email: ${accountData.customerEmail}</p>
    </div>
  </div>
</body>
</html>
`;

	const handleSubmitStepTwo = async (e) => {
		e.preventDefault();
		const res = await axios.post(
			"https://api.nexo.com.pa/sendTransactionConfirmationEmail/v1/",
			accountData, // Pasando el objeto directamente. Axios lo convertirá a JSON.
			{
				headers: {
					"Content-Type": "application/json",
				},
			}
		);

		if (res.status === 200) {
			await addDoc(collection(db, "transferencias"), {
				...accountData,
				state: "espera",
				companyName,
				companyId,
				totalCredits,
			});

			setStep(3);
			setAccountData({
				accountNumber: "",
				amount: "",
				customerEmail: "",
				achConfirmation: "",
			});
		}
	};

	return (
		<Modal open={open} onClose={handleClose}>
			<Box
				sx={{
					position: "absolute",
					top: "50%",
					left: "50%",
					transform: "translate(-50%, -50%)",
					width: 400,
					bgcolor: "background.paper",
					boxShadow: 24,
					p: 4,
					borderRadius: 2,
				}}
			>
				<form
					onSubmit={step === 1 ? handleSubmitStepOne : handleSubmitStepTwo}
					noValidate
					autoComplete="off"
				>
					{step === 1 ? (
						<Stack spacing={2} alignItems="center">
							<Typography variant="h5" gutterBottom>
								1. Datos de Transferencia
							</Typography>
							<Typography variant="subtitle" gutterBottom>
								Escribe el monto y la informacion necesaria que incluiras en la
								transferencia
							</Typography>
							<TextField
								fullWidth
								label="Número de Cuenta Proveniente"
								name="accountNumber"
								value={accountData.accountNumber}
								onChange={handleInputChange}
							/>
							<TextField
								fullWidth
								label="Monto de Creditos Deseados"
								name="amount"
								type="number"
								value={accountData.amount}
								onChange={handleInputChange}
							/>
							<TextField
								fullWidth
								label="Email"
								name="customerEmail"
								type="email"
								value={accountData.customerEmail}
								onChange={handleInputChange}
							/>
							<Divider sx={{ stoke: "black 2px" }} />
							<Typography variant="h5" gutterBottom>
								2. Informacion del Destinario
							</Typography>
							<Typography variant="subtitle" gutterBottom>
								Envia el monto deseado a esta cuenta
							</Typography>
							<List>
								<ListItem>1. Numero de Cuenta: 82736491287</ListItem>
								<ListItem>2. Nombre de Cuenta: NEXO TECHNOLOGIES, INC</ListItem>
								<ListItem>3. Banco Destinaro: BANCO GENERAL, S.A.</ListItem>
							</List>
							<Typography variant="h5" gutterBottom>
								3. Enviar la transferencia
							</Typography>
							<Typography variant="subtitle" gutterBottom>
								Una vez confirmada la transferencia presione "Proximo"
							</Typography>
							<Button variant="contained" type="submit">
								Próximo
							</Button>
						</Stack>
					) : step === 3 ? (
						<Stack spacing={2} alignItems="center">
							<Typography variant="h5" gutterBottom>
								Tu solicitud ha sido recibida
							</Typography>
							<Stack direction="row" spacing={1} justifyContent="center">
								<Button
									onClick={() => {
										handleClose();
										setStep(1);
									}}
								>
									Terminar
								</Button>
							</Stack>
						</Stack>
					) : (
						<Stack spacing={2} alignItems="center">
							<Typography variant="h5" gutterBottom>
								Confirmacion de la transferencia
							</Typography>
							<TextField
								fullWidth
								label="ACH Confirmation Number"
								name="achConfirmation"
								value={accountData.achConfirmation}
								onChange={handleInputChange}
							/>
							<Stack direction="row" spacing={1} justifyContent="center">
								<Button onClick={() => setStep(1)}>Atras</Button>
								<Button variant="contained" type="submit">
									Enviar
								</Button>
							</Stack>
						</Stack>
					)}
				</form>
			</Box>
		</Modal>
	);
};
