import React from "react";
import { useSelector } from "react-redux";
import SettingsCompanies from "../companies/settingcomponents/SettingsCompanies";
import SettingsComercio from "../comercios/SettingsComercio";

const Settings = () => {
	const { type } = useSelector((state) => state.user.data.worksAt);

	return (
		<>{type === "company" ? <SettingsCompanies /> : <SettingsComercio />}</>
	);
};

export default Settings;
