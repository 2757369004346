import {
	Box,
	Button,
	Grid,
	InputAdornment,
	TextField,
	Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import BranchSelector from "../../../components/form/BranchSelect";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../../../config/firebase";
import { useDispatch, useSelector } from "react-redux";
import { fetchBranches } from "../../../../store/reducers/branchReducer";

const HorasDeApertura = () => {
	const dispatch = useDispatch();
	const [selectedBranch, setSelectedBranch] = useState(null);
	const { companyId } = useSelector((state) => state.user);
	const [branchHours, setBranchHours] = useState({
		hours: {
			lunes: { cierre: "", apertura: "" },
			martes: { cierre: "", apertura: "" },
			miercoles: { cierre: "", apertura: "" },
			jueves: { cierre: "", apertura: "" },
			viernes: { cierre: "", apertura: "" },
			sabado: { cierre: "", apertura: "" },
			domingo: { cierre: "", apertura: "" },
		},
	});

	const handleOpeningTimeChange = (day, key, value) => {
		setBranchHours((prevData) => ({
			hours: {
				...(prevData.hours || {}),
				[day]: {
					...prevData.hours?.[day],
					[key]: value,
				},
			},
		}));
	};

	useEffect(() => {
		if (selectedBranch) {
			setBranchHours((prevData) => ({
				...prevData,
				hours: { ...prevData.hours, ...(selectedBranch.hours || {}) },
			}));
		}
	}, [selectedBranch]);

	const handleSubmit = async (e) => {
		e.preventDefault();
		const docRef = doc(db, "branches", selectedBranch.branchId);
		await updateDoc(docRef, { hours: branchHours.hours });
		dispatch(fetchBranches(companyId));
	};
	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				gap: 4,
				alignItems: "start",
			}}
		>
			<form
				style={{
					display: "flex",
					flexDirection: "column",
					alignContent: "center",
					alignItems: "center",
				}}
				onSubmit={handleSubmit}
			>
				<Typography variant="h5" mb="1rem">
					Horas de Operacion
				</Typography>

				<Grid
					container
					alignContent={"center"}
					justifyContent={"center"}
					mb="2rem"
					spacing={3}
				>
					<Grid item phone={12}>
						<BranchSelector setCurrentBranch={setSelectedBranch} />
					</Grid>
					{[
						"lunes",
						"martes",
						"miercoles",
						"jueves",
						"viernes",
						"sabado",
						"domingo",
					].map((day) => (
						<Grid item key={day}>
							<Typography variant="h5">{day}</Typography>
							<Box display="flex" alignItems="center">
								<TextField
									type="time"
									value={branchHours?.hours?.[day]?.apertura}
									onChange={(e) =>
										handleOpeningTimeChange(day, "apertura", e.target.value)
									}
									InputLabelProps={{
										shrink: true,
									}}
									InputProps={{
										startAdornment: (
											<InputAdornment position="start">Abre</InputAdornment>
										),
									}}
									sx={{ mr: 1 }}
								/>

								<TextField
									type="time"
									value={branchHours?.hours?.[day]?.cierre}
									onChange={(e) =>
										handleOpeningTimeChange(day, "cierre", e.target.value)
									}
									InputLabelProps={{
										shrink: true,
									}}
									InputProps={{
										startAdornment: (
											<InputAdornment position="start">Cierra</InputAdornment>
										),
									}}
								/>
							</Box>
						</Grid>
					))}
				</Grid>
				<Button onClick={handleSubmit} variant="contained" color="primary">
					Save Branch Settings
				</Button>
			</form>
		</Box>
	);
};

export default HorasDeApertura;
