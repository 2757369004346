import { collection, addDoc, Timestamp, GeoPoint } from "firebase/firestore";
import { db } from "../../../config/firebase";

export const submitBlogToFirebase = async ({
	title = "",
	subtitle = "",
	content = "",
	imageUrls = "",
}) => {
	const blogsCollection = collection(db, "blogs");
	try {
		const timestamp = Timestamp.now();
		const position = await new Promise((resolve, reject) => {
			navigator.geolocation.getCurrentPosition(
				(position) => resolve(position.coords),
				(error) => reject(error)
			);
		});
		const { latitude: lat, longitude: lng } = position;
		const location = new GeoPoint(lat, lng);

		const docRef = await addDoc(blogsCollection, {
			title,
			subtitle,
			content,
			imageUrls,
			timestamp,
			location,
		});
	} catch (error) {
		console.error("Error submitting blog to Firebase:", error);
		throw error;
	}
};
