import React, { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import { persistor, store } from "./store/reducers/rootReducer";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import "./styles.css";
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
	<StrictMode>
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<App />
			</PersistGate>
		</Provider>
	</StrictMode>
);
