import React from "react";
import { useTheme, useMediaQuery } from "@mui/material";
import {
	LineChart,
	Line,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
	ResponsiveContainer,
	AreaChart,
	Area,
} from "recharts";

const DailyExpensesChart = ({ dailyExpensesData, percentageChange = 10 }) => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("tablet"));
	const colorArea = percentageChange > 0 ? "green" : "red";
	return (
		<ResponsiveContainer width="100%" aspect={2 / 1}>
			<AreaChart
				margin={{ left: 30, right: 30, bottom: 20 }}
				width={750}
				data={dailyExpensesData}
			>
				<defs>
					<linearGradient id="total" x1="0" y1="0" x2="0" y2="1">
						<stop offset="5%" stopColor={colorArea} stopOpacity={0.9} />
						<stop offset="95%" stopColor={colorArea} stopOpacity={0.0} />
					</linearGradient>
				</defs>
				<XAxis
					tickLine={false}
					axisLine={false}
					tick={{
						fill: "#000",
						fontWeight: "bold",
						strokeWidth: 2,
					}}
					mirror={true}
					dy={20}
					dataKey="day"
					stroke="gray"
					padding={{ left: 30, right: 30, bottom: 20 }}
				/>
				<Tooltip />
				<Area
					type="linear"
					dataKey="Expenses"
					stroke={colorArea}
					fillOpacity={1}
					fill="url(#total)"
					dot={{
						stroke: colorArea,
						strokeWidth: 2,
						fill: "#ffffff",
						fontSize: 10,
						r: 5,
					}}
					activeDot={{ fill: "red", strokeWidth: 2, r: 5 }}
				/>
			</AreaChart>
		</ResponsiveContainer>
	);
};

export default DailyExpensesChart;
