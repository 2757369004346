import React, { useState } from "react";
import { tokens } from "../../../../theme";
import { LoadingState } from "../../../components";
import { useImagePreloader } from "../../../hooks";
import {
	Box,
	Button,
	Container,
	TextField,
	MenuItem,
	useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const types = [
	{
		type: "company",
		label: "Gestionar fondos de beneficios para colaboradores",
	},
	{
		type: "comercio",
		label: "Unirse como comercio afiliado",
	},
];

const SignUp = () => {
	const [type, setType] = useState("comercio");
	const navigate = useNavigate();
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);
	const imageUrls = ["/assets/signupimage.jpeg", "/assets/logonexo.png"];
	const imagesLoaded = useImagePreloader(imageUrls);

	const handleTypeChange = (e) => {
		setType(e.target.value);
	};

	const handleSubmit = (e) => {
		navigate(e.target[0].value);
	};

	return (
		<Box
			sx={{
				display: "flex",
				height: "auto",
				backgroundColor: colors.redAccent[900],
				alignItems: "center",
				justifyContent: "space-between",
			}}
		>
			{!imagesLoaded && <LoadingState size="lg" />}
			{imagesLoaded && (
				<Box
					sx={{
						display: "flex",
						height: "100vh",
						width: "100%",
						backgroundColor: colors.redAccent[900],
						alignItems: "center",
					}}
				>
					<Box
						sx={{
							height: "100%",
							width: "50%",
							display: {
								desktoplg: "block",
								desktop: "block",
								tablet: "none",
								phone: "none",
							},
						}}
					>
						<img
							src="/assets/signupimage.jpeg"
							alt="logo"
							style={{
								height: "100%",
								width: "100%",
								objectFit: "cover",
							}}
							loading="lazy"
						/>
					</Box>
					<Container
						sx={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							justifyContent: "center",
							width: "50%",
							gap: "2rem",
						}}
					>
						<img
							src="/assets/logonexo.png"
							alt="logo"
							style={{
								height: "auto",
								maxWidth: "250px",
							}}
						/>
						<form
							style={{
								display: "flex",
								flexDirection: "column",
								alignItems: "center",
								justifyContent: "center",
								gap: "2rem",
							}}
							onSubmit={handleSubmit}
						>
							<TextField
								id="select-type"
								select
								label="Quien Eres?"
								value={type} // Set the value to match the state
								onChange={handleTypeChange}
								helperText="Por favor seleccione una opción"
							>
								{types.map((item) => (
									<MenuItem key={item.type} value={item.type}>
										{item.label}
									</MenuItem>
								))}
							</TextField>
							<Button
								sx={{
									display: "flex",
									justifyContent: "center",
									gap: "10px",
								}}
								variant="contained"
								color="primary"
								type="submit"
							>
								Next
							</Button>
						</form>
					</Container>
				</Box>
			)}
		</Box>
	);
};

export default SignUp;
