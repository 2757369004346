import React from "react";
import {
	Box,
	Card,
	CardHeader,
	CardContent,
	Typography,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Avatar,
} from "@mui/material";
import { GridArrowUpwardIcon } from "@mui/x-data-grid";
import { useTheme } from "@emotion/react";
import { useSelector } from "react-redux";

const InfoRankCard = ({ users, title, subtitle }) => {
	const theme = useTheme();

	const positionStyles = {
		1: { backgroundColor: "gold" },
		2: { backgroundColor: "silver" },
		3: { backgroundColor: "bronze" },
	};
	const { topUsers } = useSelector((state) => state.transactions.data);
	return (
		<Card
			raised
			sx={{
				background:
					"radial-gradient(circle, rgba(249,232,235,0.5) 14%, rgba(255,160,176,0) 98%)",
				borderRadius: 2,
				p: 0,
				height: "auto",
				maxheight: "15rem",
				boxShadow: "0 3px 10px rgba(0, 0, 0, 0.2)",
			}}
		>
			<CardContent>
				<List>
					<Typography variant="h6" component="div">
						Usuarios Mas Activos
					</Typography>
					{topUsers.length === 0 ? (
						<ListItem sx={{ py: 0.1, px: 1 }}>
							<ListItemText
								primary={"No hay suficiente data"}
								// Asumiendo que 'transactions' puede representar puntos
							/>
						</ListItem>
					) : (
						topUsers?.map((user, index) => (
							<ListItem sx={{ py: 0.1, px: 1 }} key={user.userId}>
								<ListItemAvatar>
									<Avatar
										sx={{ width: "12", height: 24 }}
										style={positionStyles[index + 1]}
									>
										{index + 1}
									</Avatar>
								</ListItemAvatar>
								<ListItemText
									primary={user.userName}
									secondary={`${user.transactions} Puntos`} // Asumiendo que 'transactions' puede representar puntos
								/>
							</ListItem>
						))
					)}
				</List>
			</CardContent>
		</Card>
	);
};

export default InfoRankCard;
