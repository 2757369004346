import React, { useState, useEffect } from "react";
import { Box, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { Container } from "@mui/system";

const SettingComercio = () => {
	const navigate = useNavigate();
	const location = useLocation(); // Use useLocation hook to access the current path

	// Initialize activeTab state with the last stored value or default to "userSettings"
	const [activeTab, setActiveTab] = useState(
		localStorage.getItem("activeTab") || "userSettings"
	);

	useEffect(() => {
		// Extract the part of the path that represents the active tab
		const pathSections = location.pathname.split("/");
		const currentTab = pathSections[pathSections.length - 1];

		// Update the activeTab state and localStorage if the path section changes
		if (currentTab && currentTab !== activeTab) {
			setActiveTab(currentTab);
			localStorage.setItem("activeTab", currentTab);
		}
	}, [location]); // Re-run this effect when the location changes

	const handleTabChange = (event, newTab) => {
		if (newTab) {
			setActiveTab(newTab);
			localStorage.setItem("activeTab", newTab);
			navigate(`/home/settings/${newTab.toLowerCase()}`);
		}
	};

	return (
		<Container
			sx={{
				display: "flex",
				flexDirection: "column",
				height: "100vh",
				overflow: "hidden", // Ensure the container doesn't overflow
			}}
		>
			<Box
				sx={{
					position: "sticky",
					top: 0,
					zIndex: 200,
					margin: "2rem 0",
					display: "flex",
					width: "100%",
					justifyContent: "center",
				}}
			>
				<ToggleButtonGroup
					orientation="horizontal"
					value={activeTab}
					exclusive
					onChange={handleTabChange}
				>
					<ToggleButton value="company">Comercio</ToggleButton>
					<ToggleButton value="branches">Establecimientos</ToggleButton>
					<ToggleButton value="user">Usuario</ToggleButton>
					<ToggleButton value="imagenes">Imagenes</ToggleButton>{" "}
					{/* Make sure this matches your routing */}
				</ToggleButtonGroup>
			</Box>
			<Box
				sx={{
					flex: 1,
					overflowY: "auto", // Enable vertical scrolling
					padding: "2rem 3rem",
				}}
			>
				<Outlet />
			</Box>
		</Container>
	);
};

export default SettingComercio;
