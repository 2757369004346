import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import {
	setPersistence,
	browserSessionPersistence,
	getAuth,
} from "firebase/auth";
import { getFunctions } from "firebase/functions";
import { getStorage } from "firebase/storage";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
	apiKey: "AIzaSyBiKdZfPvF5DSbg-75fvL8XrxdERBucffQ",
	authDomain: "nexo-pty.firebaseapp.com",
	projectId: "nexo-pty",
	storageBucket: "nexo-pty.appspot.com",
	messagingSenderId: "805439953352",
	appId: "1:805439953352:web:0a32e0e32324117a9048fd",
	measurementId: "G-MRFJLRV3SR",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

setPersistence(auth, browserSessionPersistence);
const db = getFirestore(app);
const functions = getFunctions(app);
const storage = getStorage(app);

export { auth, db, functions, storage };
