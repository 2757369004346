import {
	FormControl,
	FormHelperText,
	InputAdornment,
	InputLabel,
	OutlinedInput,
} from "@mui/material";
import { useController } from "react-hook-form";

export const PanamaPhoneNumberInput = ({
	control,
	name,
	label,
	width = "15rem",
}) => {
	const {
		field: { ref, ...inputProps },
		fieldState: { invalid, isTouched, isDirty, error: fieldError },
	} = useController({
		name,
		control,
		rules: {
			required: "Phone number is required",
		},
	});

	return (
		<FormControl variant="outlined" fullWidth error={invalid && isTouched}>
			<InputLabel htmlFor={name}>{label}</InputLabel>
			<OutlinedInput
				{...inputProps}
				id={name}
				inputRef={ref}
				sx={{ width }}
				startAdornment={<InputAdornment position="start">+507</InputAdornment>}
				label={label}
			/>
			{invalid && isTouched && (
				<FormHelperText>{fieldError?.message}</FormHelperText>
			)}
		</FormControl>
	);
};
