import { db } from "../../../config/firebase";
import { doc, deleteDoc } from "firebase/firestore";

const useDeleteDocument = () => {
	const deleteDocument = (d, c) => {
		d.map(async (id) => {
			const ref = doc(db, c, id);
			await deleteDoc(ref);
		});
	};

	return { deleteDocument };
};

export default useDeleteDocument;
