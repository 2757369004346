import {
	Box,
	Button,
	Grid,
	IconButton,
	InputAdornment,
	MenuItem,
	Modal,
	Select,
	TextField,
	Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import CloseIcon from "@mui/icons-material/Close";
import { geohashForLocation } from "geofire-common";
import { useDispatch, useSelector } from "react-redux";
import { signupUser } from "../../../store/reducers/authReducer";
import { addBranch, updateBranch } from "../../../store/reducers/branchReducer";
import Input from "../form/Input";
import MapWithAutocomplete from "../form/LocationInput";
import { PanamaPhoneNumberInput } from "../form/PanamaPhoneInput";
import SubCategroyInput from "../../../admin/components/inputs/SubcategoryInput";

export const UpdateBranchModal = ({
	onSave,
	selectedBranch,
	onClose,
	isOpen,
}) => {
	const { control, handleSubmit, reset, setValue } = useForm();
	const dispatch = useDispatch();
	const { comercioName, comercioCurrency, comercioLogo } = useSelector(
		(state) => state.user.company
	);
	console.log(selectedBranch);
	const formattedName = comercioName.toLowerCase().replace(/\s+/g, "");
	const { categories, subcategories } = useSelector(
		(state) => state.user.settings
	);
	const {
		error,
		companyId,
		settings,
		data: userData,
	} = useSelector((state) => state.user);
	const [coordinates, setCoordinates] = useState({
		array: "",
		geopoint: "",
		direction: "'Nombre de Direccion'",
	});

	const [selectedCategory, setSelectedCategory] = useState("");
	const [selectedSubcategories, setselectedSubcategories] = useState([]);

	const onSubmit = async (data) => {
		try {
			const branchData = {
				...selectedBranch,
				...data,
			};
			const actionResult = await dispatch(
				updateBranch({ companyId, branchData })
			);
			const formattedEmail = data.email + "@" + formattedName + ".pa";
			if (addBranch.fulfilled.match(actionResult)) {
				const dataUser = {
					...userData,
					email: formattedEmail,
					password: data.password,
					worksAt: {
						branchId: actionResult.payload,
					},
				};
				const addUser = await dispatch(
					signupUser({ dataUser, employee: false })
				);
			}
		} catch (error) {
			console.error("Error adding branch:", error);
		}
	};

	return (
		<Modal
			open={isOpen}
			onClose={onClose}
			sx={{
				display: "flex",
				alignItems: "center",
				alignContent: "center",
				justifyContent: "center",
			}}
		>
			<Box
				sx={{
					position: "absolute",
					top: "50%",
					left: "50%",
					transform: "translate(-50%, -50%)",
					width: "80vw",
					height: "90vh",
					bgcolor: "background.paper",
					boxShadow: 24,
					p: 4,
					overflow: "auto",
					borderRadius: 3,
				}}
			>
				<IconButton
					aria-label="close"
					onClick={onClose}
					sx={{
						position: "absolute",
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
					}}
				>
					<CloseIcon />
				</IconButton>
				<Typography variant="h4" component="h2" sx={{ mb: 4 }}>
					Actualizar Establecimiento: {selectedBranch?.branchName}
				</Typography>
				<form onSubmit={handleSubmit(onSubmit)}>
					<Grid container justifyContent="start" alignItems="start" spacing={3}>
						<Grid item phone={12} desktop={6}>
							<TextField
								fullWidth
								label="Branch Name"
								sx={{ width: "20rem" }}
								defaultValue={selectedBranch?.branchName}
								variant="outlined"
								{...control.register("branchName")}
								InputProps={{
									readOnly: false,
								}}
							/>
						</Grid>
						<Grid item phone={12} desktop={6} tablet={12}>
							<Input
								width="20rem"
								title="Nombre del Gerente"
								defaultValue={selectedBranch?.managerName}
								type="text"
								name="managerName"
								control={control}
							/>
						</Grid>
						<Grid item phone={12} desktop={6} tablet={12}>
							<TextField
								label="Correo del comercio"
								variant="outlined"
								defaultValue={selectedBranch?.email}
								{...control.register("email")}
								InputProps={{
									// Esta propiedad añade adornos al componente de entrada; en este caso, el sufijo fijo.
									endAdornment: (
										<InputAdornment position="end">
											@{formattedName}.pa
										</InputAdornment>
									),
								}}
								// Propiedad para hacer el TextField de ancho completo
								fullWidth
							/>
						</Grid>
						<Grid item phone={12} desktop={6} tablet={12}>
							<PanamaPhoneNumberInput
								control={control}
								value={selectedBranch?.comercioPhone}
								width="20rem"
								name="comercioPhone"
								label="Phone Number"
							/>
						</Grid>
						<Grid item phone={12} desktop={6}>
							<Select
								fullWidth
								defaultValue={selectedBranch?.category}
								{...control.register("category", {
									onChange: (e) => {
										setSelectedCategory(e.target.value);
									},
								})}
								displayEmpty
								inputProps={{ "aria-label": "Without label" }}
							>
								<MenuItem disabled value="">
									<em>Select Business Type</em>
								</MenuItem>
								{settings.categories.map((category) => (
									<MenuItem key={category.id} value={category.id - 1}>
										{category.nombre}
									</MenuItem>
								))}
							</Select>
						</Grid>
						{subcategories[selectedCategory] && (
							<Grid item phone={12} desktop={6}>
								<SubCategroyInput
									category={selectedCategory}
									setSelectedSubcategories={setselectedSubcategories}
									selectedSubcategories={selectedSubcategories}
								/>
							</Grid>
						)}
						<Grid item phone={12} desktop={6}>
							<TextField
								fullWidth
								label="URL de Reservas"
								sx={{ width: "20rem" }}
								defaultValue={selectedBranch?.reserveURL}
								variant="outlined"
								{...control.register("reserveURL")}
							/>
						</Grid>
						<Grid item phone={12} desktop={6} sx={{ display: "flex", gap: 2 }}>
							<Button type="submit" variant="contained">
								Add Branch
							</Button>
							<Button onClick={onClose} variant="outlined">
								Cancel
							</Button>
						</Grid>
					</Grid>
				</form>
			</Box>
		</Modal>
	);
};
