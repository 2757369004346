import React from "react";
import {
	BarChart,
	Bar,
	XAxis,
	YAxis,
	Tooltip,
	CartesianGrid,
	ResponsiveContainer,
	Legend,
} from "recharts";
import { Typography, Paper } from "@mui/material";

const CustomBarChart = ({
	title,
	subtitle = " ",
	data,
	dataKeyX,
	dataKeyY,
	key = "1",
}) => {
	return (
		<Paper
			sx={{
				padding: "20px",
				backgroundColor: "background: rgb(249,232,235)",
				background:
					"radial-gradient(circle, rgba(249,232,235,0.5) 14%, rgba(255,160,176,0) 98%)",
				borderRadius: "12px",
				boxShadow: "0 3px 10px rgba(0, 0, 0, 0.2)",
			}}
		>
			<Typography
				sx={{ fontSize: 16, fontWeight: "bold", color: "main" }}
				gutterBottom
			>
				{title}
			</Typography>
			<Typography sx={{ fontSize: 14, color: "main" }}>{subtitle}</Typography>

			<ResponsiveContainer width="100%" height={300}>
				<BarChart
					data={data}
					margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
				>
					<defs>
						<linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
							<stop offset="50%" stopColor="#ff3357" stopOpacity={0.8} />
							<stop offset="95%" stopColor="#ff3357" stopOpacity={0.4} />
						</linearGradient>
						<filter id="shadow" height="130%">
							<feDropShadow
								dx="0"
								dy="2"
								stdDeviation="3"
								floodColor="#000"
								floodOpacity=".2"
							/>
						</filter>
					</defs>
					<XAxis dataKey={dataKeyX} />
					<YAxis dataKey={dataKeyY} />

					<Bar
						dataKey={dataKeyY}
						fill="url(#colorUv)"
						style={{ filter: "url(#shadow)" }}
						radius={[10, 10, 10, 10]}
					/>
					{/* ...define other bars */}
				</BarChart>
			</ResponsiveContainer>
		</Paper>
	);
};

export default CustomBarChart;
