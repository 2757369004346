import React, { useState, useCallback, useEffect } from "react";
import { Box, Button, useMediaQuery, useTheme } from "@mui/material";
import {
	DataGrid,
	GridToolbarContainer,
	GridToolbarExport,
} from "@mui/x-data-grid";
import { tokens } from "../../../theme";
import { CustomizedSnackbar, Header, LoadingState } from "../../components";
import { AddEmployeesModal } from "../../components/modals/AddEmployeesModal";
import { useDeleteDocument } from "../../hooks";
import { EmployeeImport } from "../../components/form/Importer";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import {
	collection,
	doc,
	increment,
	onSnapshot,
	query,
	updateDoc,
	where,
} from "firebase/firestore";
import { db } from "../../../config/firebase";
import { useLoaderData } from "react-router-dom";
import { fetchCompany } from "../../../store/reducers/userReducer";

const formatter = new Intl.NumberFormat("en-US", {
	style: "currency",
	currency: "USD",
	minimumFractionDigits: 2,
	maximumFractionDigits: 2,
});
const columns = [
	{ field: "firstName", headerName: "Nombre", width: 150 },
	{ field: "lastName", headerName: "Apellido", width: 150 },
	{ field: "email", headerName: "Email", width: 250 },

	{
		field: "credits",
		headerName: "Creditos Disponibles",
		width: 150,
		editable: true,
		renderCell: (params) => formatter.format(params.value), // Use the formatter here
	},
];

function computeMutation(newRow, oldRow) {
	if (newRow.credits !== oldRow.credits) {
		return `credits from '${oldRow.credits}' to '${newRow.credits}'`;
	}

	return null;
}

function CustomToolbar() {
	return (
		<GridToolbarContainer>
			<GridToolbarExport />
		</GridToolbarContainer>
	);
}

export default function Employees() {
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);
	const dispatch = useDispatch();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isModal2Open, setIsModal2Open] = useState(false);
	const [rowSelectionModel, setRowSelectionModel] = useState([]);
	const { data, companyId, company } = useSelector((state) => state.user);
	const isMobile = useMediaQuery((theme) => theme.breakpoints.down("tablet"));
	const [isLoading, setIsLoading] = useState(false);
	const { deleteDocument } = useDeleteDocument();
	const employeesData = useLoaderData();
	const [employees, setEmployees] = useState(employeesData);
	const [alertOpen, setAlertOpen] = useState({
		type: "",
		message: "",
		open: false,
	});

	// Suponiendo que 'setEmployees' actualiza el estado local de los empleados
	// y 'newEmployee' es el empleado recién añadido

	useEffect(() => {
		// Crea una referencia a la colección con un query que filtre por companyId
		const q = query(
			collection(db, "employees"),
			where("companyId", "==", companyId)
		);

		// Establece la suscripción
		const unsubscribe = onSnapshot(q, (snapshot) => {
			const employeesList = snapshot.docs.map((doc) => ({
				...doc.data(),
				id: doc.id,
			}));
			setEmployees(employeesList); // Actualiza el estado con los nuevos empleados
		});

		// Limpia la suscripción al desmontar el componente
		return () => unsubscribe();
	}, [companyId]); // Dependencias del useEffect

	const handleOpenAlert = ({ type, message, open }) => {
		setAlertOpen({ type, message, open });
	};

	const handleCloseAlert = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}
		handleOpenAlert({
			type: "",
			message: "",
			open: false,
		});
	};

	const openModal = () => {
		setIsModalOpen(true);
	};

	const openModal2 = () => {
		setIsModal2Open(true);
	};

	const closeModal = () => {
		setIsModalOpen(false);
		setIsModal2Open(false);
	};

	const handleDelete = async () => {
		setIsLoading(true);
		const confirmDelete = window.confirm(
			"Are you sure you want to delete the selected employees?"
		);
		const users = rowSelectionModel;
		if (confirmDelete) {
			deleteDocument(rowSelectionModel, "employees");
			const response = await axios.post(
				"http://127.0.0.1:5001/nexo-pty/us-central1/app/deleteUser",
				users
			);
		}
		setIsLoading(false);
	};

	const processRowUpdate = useCallback(
		(newRow, oldRow) =>
			new Promise((resolve, reject) => {
				const mutation = computeMutation(newRow, oldRow);
				// Calculate the difference in credits to adjust the company's total accordingly
				const creditDifference = newRow.credits - oldRow.credits;

				// Validate the new credit amount is not negative and does not cause company totalCredits to go below zero
				if (newRow.credits < 0) {
					handleOpenAlert({
						type: "error",
						message: "No puede agregar creditos negativos",
						open: true,
					});
					reject(oldRow);
				} else if (company.totalCredits - creditDifference < 0) {
					handleOpenAlert({
						type: "error",
						message: `No dispone de suficientes creditos: ${formatter.format(
							company.totalCredits
						)}, por favor haga una recarga`,
						open: true,
					});
					reject(oldRow);
				} else if (mutation) {
					const employeeRef = doc(db, "employees", newRow.id);
					const companyRef = doc(db, "companies", companyId);

					// Execute updates only if validations pass
					updateDoc(employeeRef, { credits: newRow.credits });
					updateDoc(companyRef, {
						// Adjust the company's total credits based on the change
						totalCredits: increment(-1 * creditDifference),
					})
						.then(() => {
							dispatch(
								fetchCompany({
									companyId,
									type: data.worksAt.type,
								})
							);
						})
						.then(() => {
							resolve(newRow);
						})
						.catch((error) => {
							console.error("Failed to update credits:", error);
							alert("Failed to update credits.");
							reject(oldRow);
						});
				} else {
					// If there's no change in credits, just resolve with oldRow to avoid unnecessary updates
					resolve(oldRow);
				}
			}),
		[companyId, company.totalCredits]
	);

	return (
		<Box m={"1rem"}>
			<Box
				display="flex"
				mb={"2rem"}
				justifyContent="space-between"
				alignItems="center"
			>
				<Header
					title="Colaboradores"
					subtitle={`Hechale un vistazo a ${employees?.length === 1 ? "" : "todos"} tus ${
						employees?.length
					} colaboradores${(employees?.length === 1) === 1 ? "" : "s"}`}
				/>
				<Box
					sx={{
						display: {
							phone: "none",
							tablet: "flex",
							desktop: "flex",
							desktoplg: "flex",
						},
						alignItems: "center",
						gap: "5px",
					}}
				>
					<Button onClick={openModal}>Agregar Usuario +</Button>
					{/* <Button onClick={openModal2}>Importar CSV</Button> */}
					<Button onClick={handleDelete}>Borrar Usuario</Button>
				</Box>
			</Box>
			{employees && (
				<DataGrid
					rows={employees}
					columns={columns}
					editMode="cell"
					pageSize={10}
					checkboxSelection
					disableRowSelectionOnClick
					onRowSelectionModelChange={(newRowSelectionModel) => {
						setRowSelectionModel(newRowSelectionModel);
					}}
					rowSelectionModel={rowSelectionModel}
					sx={{
						"& .MuiDataGrid-cellCheckbox": {
							color: "secondary.main", // Change as needed
						},
						"& .MuiCheckbox-root.Mui-checked": {
							color: "secondary.main", // Use theme color or specific color
						},
						"& .MuiDataGrid-cellCheckbox svg": {
							fill: "secondary.main", // For the checkbox icon itself
						},
					}}
					processRowUpdate={processRowUpdate}
					slots={{
						toolbar: CustomToolbar,
					}}
				/>
			)}
			{!employees && <div>PORFAVOR AÑADA COLABORADORES</div>}
			{isLoading && <LoadingState />}
			{isModalOpen && (
				<AddEmployeesModal closeModal={closeModal} companyId={data.companyId} />
			)}
			{isModal2Open && <EmployeeImport closeModal={closeModal} />}
			<CustomizedSnackbar
				open={alertOpen.open}
				handleClose={handleCloseAlert}
				type={alertOpen.type}
				message={alertOpen.message}
			/>
		</Box>
	);
}
