import React from "react";
import { useSelector } from "react-redux";
import DashboardCompanies from "../companies/DashboardCompanies";
import { Container, useMediaQuery } from "@mui/material";

const Dashboard = () => {
	const { type } = useSelector((state) => state.user.data.worksAt);
	const isMobile = useMediaQuery((theme) => theme.breakpoints.down("tablet"));

	return (
		<Container
			sx={{
				display: "flex",
				justifyContent: "center",
				alignContent: "center",
				margin: isMobile ? "1rem" : 0,
			}}
		>
			<DashboardCompanies />
		</Container>
	);
};

export default Dashboard;
