import React, { useState } from "react";
import { Modal } from "@mui/material";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import CustomizedSnackbar from "../state/SnackBar";
import { updateCompany } from "../../../store/reducers/userReducer";
import { Importer, ImporterField } from "react-csv-importer";
import "react-csv-importer/dist/index.css";

export function EmployeeImport({ closeModal }) {
	const [showSnackbar, setShowSnackbar] = useState(false);
	const dispatch = useDispatch();

	const companyId = useSelector((state) => state.user.companyId);

	return (
		<div style={{ height: "100vh" }}>
			<Modal
				open
				onClose={closeModal}
				sx={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					minWidth: "50rem",
					height: "100vh",
				}}
			>
				<Importer
					style={{
						color: "white",
						display: "flex",
						flexDirection: "column",
						gap: "2rem",
						margin: "200px 400px",
						alignItems: "center",
						alignContent: "center",
						justifyContent: "center",
						backgroundColor: "white",
						borderRadius: "12px",
						padding: "2rem",
					}}
					dataHandler={async (rows) => {
						const formData = new FormData();
						formData.append("companyId", companyId);
						formData.append("employees", rows);
						await axios.post(
							"http://127.0.0.1:5001/nexo-pty/us-central1/app/employeesimport",
							{ employees: rows, companyId }
						);
						dispatch(updateCompany(companyId));
					}}
					chunkSize={10000}
					defaultNoHeader={false}
					restartable={false}
					// onStart={({ file, fields }) => {
					// 	// optional, invoked when user has mapped columns and started import
					// }}
					onComplete={({ file, fields }) => {
						setShowSnackbar(true);
					}}
					onClose={() => {
						closeModal();
					}}
				>
					<ImporterField name="name" label="Name" />
					<ImporterField name="email" label="Email" />
					<ImporterField name="branch" label="Branch" optional />
					<ImporterField name="sector" label="Sector" optional />
				</Importer>
			</Modal>
			{showSnackbar && <CustomizedSnackbar type="success" />}
		</div>
	);
}
