import { Timestamp } from "firebase/firestore";
const today = new Date();
const currentDayOfWeek = today.getDay(); // Domingo = 0, Lunes = 1, ..., Sábado = 6

let initialExpensesData = {
	domingo: currentDayOfWeek >= 0 ? 0 : null,
	lunes: currentDayOfWeek >= 1 ? 0 : null,
	martes: currentDayOfWeek >= 2 ? 0 : null,
	miércoles: currentDayOfWeek >= 3 ? 0 : null,
	jueves: currentDayOfWeek >= 4 ? 0 : null,
	viernes: currentDayOfWeek >= 5 ? 0 : null,
	sábado: currentDayOfWeek >= 6 ? 0 : null,
};
export const filterCurrentWeek = (transactions) => {
	// Verifica si transactions es un arreglo
	if (!Array.isArray(transactions)) {
		return []; // Retorna un arreglo vacío si transactions no es un arreglo
	}

	const today = new Date();
	const s = new Date(
		today.getFullYear(),
		today.getMonth(),
		today.getDate() - today.getDay()
	);
	const startOfWeek = Timestamp.fromDate(s).toMillis();
	const e = new Date(
		today.getFullYear(),
		today.getMonth(),
		today.getDate() + (6 - today.getDay())
	);
	const endOfWeek = Timestamp.fromDate(e).toMillis();

	return transactions.filter(
		(transaction) =>
			transaction.createdAt.seconds * 1000 >= startOfWeek &&
			transaction.createdAt.seconds * 1000 <= endOfWeek
	);
};

export const groupTransactionsByDay = (transactions) => {
	const groupedData = new Map();

	transactions.forEach((transaction) => {
		const timestampMillis = transaction.createdAt.seconds * 1000;
		const day = new Date(timestampMillis).toLocaleDateString("es", {
			weekday: "long",
		});

		if (!groupedData.has(day)) {
			groupedData.set(day, []);
		}

		groupedData.get(day).push(transaction);
	});

	return groupedData;
};

export const calculateDailyExpenses = (groupedData) => {
	// Llena el objeto con los gastos reales
	groupedData.forEach((transactions, day) => {
		const totalExpenses = transactions.reduce(
			(acc, transaction) => acc + parseFloat(transaction.amount),
			0
		);
		initialExpensesData[day] = totalExpenses;
	});

	// Convierte el objeto a un array manteniendo el orden de los días
	const dailyExpensesData = Object.entries(initialExpensesData).map(
		([day, Expenses]) => ({
			day,
			Expenses,
		})
	);

	return dailyExpensesData;
};

export const calculateWeeklyExpenses = (transactions) => {
	const currentWeekTransactions = filterCurrentWeek(transactions);
	const groupedData = groupTransactionsByDay(currentWeekTransactions);
	const dailyExpensesData = calculateDailyExpenses(groupedData);

	const weeklyTotalExpenses = dailyExpensesData.reduce(
		(acc, dailyData) => acc + dailyData.Expenses,
		0
	);

	const highestPayingDay = dailyExpensesData.reduce((maxDay, dailyData) => {
		return dailyData.Expenses > maxDay.Expenses ? dailyData : maxDay;
	}, dailyExpensesData[0]);

	const dayOfTheWeek = new Date().getDay();

	const difference =
		dailyExpensesData[dayOfTheWeek].Expenses - dailyExpensesData[0].Expenses;

	const percentageDifference =
		dailyExpensesData[0].Expenses === 0
			? 0
			: (difference / dailyExpensesData[0].Expenses) * 100;

	return {
		weeklyTotalExpenses: isNaN(weeklyTotalExpenses)
			? "$0.00"
			: weeklyTotalExpenses,
		highestPayingDay: highestPayingDay || {
			Expenses: "$0.00",
			day: "Comenzando La Semana, Buena Suerte!",
		},
		dailyExpensesData,
		percentageDifference: percentageDifference?.toFixed(2),
	};
};

export const filterTransactionsByMonth = (transactions, month, year) => {
	return transactions.filter((transaction) => {
		const date = new Date(transaction.createdAt.seconds * 1000);
		return date.getMonth() === month && date.getFullYear() === year;
	});
};

export const calculateMonthlySummary = (transactions) => {
	const today = new Date();
	const currentMonth = today.getMonth();
	const currentYear = today.getFullYear();

	// Filtra las transacciones para el mes actual
	const currentMonthTransactions = filterTransactionsByMonth(
		transactions,
		currentMonth,
		currentYear
	);
	// Calcula la suma total para el mes actual
	const currentMonthTotal = currentMonthTransactions.reduce(
		(acc, transaction) => acc + parseFloat(transaction.amount),
		0
	);

	// Filtra las transacciones para el mes anterior
	const previousMonth = currentMonth - 1;
	const previousYear = currentMonth === 0 ? currentYear - 1 : currentYear; // Ajuste para enero
	const previousMonthTransactions = filterTransactionsByMonth(
		transactions,
		previousMonth,
		previousYear
	);
	// Calcula la suma total para el mes anterior
	const previousMonthTotal = previousMonthTransactions.reduce(
		(acc, transaction) => acc + parseFloat(transaction.amount),
		0
	);

	// Calcula el porcentaje de diferencia
	const difference = currentMonthTotal - previousMonthTotal;
	const percentageDifference =
		previousMonthTotal === 0 ? 0 : (difference / previousMonthTotal) * 100;
	const countDifference =
		((transactions.length - previousMonthTransactions.length) /
			previousMonthTransactions.length) *
		100;
	return {
		currentMonthTotal,
		percentageDifference,
		count: transactions.length,
		countDifference: isNaN(countDifference) ? 0 : countDifference,
	};
};

// Suponiendo que `transactions` es un array de objetos de transacción.
function getTopThreeActiveUsers(transactions, type) {
	const currentMonth = new Date().getMonth();
	const currentYear = new Date().getFullYear();

	// Filtra las transacciones por el mes actual.
	const transactionsThisMonth = transactions.filter((transaction) => {
		const transactionDate = new Date(transaction.createdAt.seconds * 1000);
		return (
			transactionDate.getMonth() === currentMonth &&
			transactionDate.getFullYear() === currentYear
		);
	});

	// Cuenta las transacciones por usuario y guarda el nombre del usuario.
	const userTransactionCounts = transactionsThisMonth.reduce(
		(acc, transaction) => {
			const userId =
				type === "company"
					? transaction.paymentInfo.userId
					: transaction.paymentInfo.companyId;
			const userName =
				type === "company"
					? transaction.paymentInfo.userPayment
					: transaction.paymentInfo.companyName; // Captura el nombre del usuario.
			if (acc[userId]) {
				acc[userId].transactions++;
			} else {
				acc[userId] = { transactions: 1, userName }; // Incluye el nombre del usuario.
			}
			return acc;
		},
		{}
	);

	// Convierte el objeto a un array, lo ordena por la cantidad de transacciones, y selecciona los primeros 3 usuarios.
	const sortedUsers = Object.entries(userTransactionCounts)
		.sort((a, b) => b[1].transactions - a[1].transactions)
		.slice(0, 3) // Selecciona los primeros 3 usuarios.
		.map((item) => ({
			userId: item[0],
			transactions: item[1].transactions,
			userName: item[1].userName, // Incluye el nombre del usuario en los resultados.
		}));

	return sortedUsers;
}

export const getTransactionSummary = (transactions, type) => {
	const monthlySummary = calculateMonthlySummary(transactions);
	const weeklySummary = calculateWeeklyExpenses(transactions);
	const topUsers = getTopThreeActiveUsers(transactions, type);

	// Group transactions by branch ID
	const transactionsByBranch = transactions.reduce((acc, transaction) => {
		const branchId = transaction.comercioDetails.branchId;
		if (!acc[branchId]) {
			acc[branchId] = [];
		}
		acc[branchId].push(transaction);
		return acc;
	}, {});

	const branchSummaries = {};
	if (Object.keys(transactionsByBranch).length > 0) {
		for (const branchId in transactionsByBranch) {
			const branchTransactions = transactionsByBranch[branchId];
			const branchWeeklySummary = calculateWeeklyExpenses(branchTransactions);
			const branchMonthlySummary = calculateMonthlySummary(branchTransactions);
			branchSummaries[branchId] = { branchWeeklySummary, branchMonthlySummary };
		}
	}

	return { monthlySummary, weeklySummary, topUsers, branchSummaries };
};
