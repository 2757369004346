import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
	addDoc,
	arrayUnion,
	collection,
	deleteDoc,
	doc,
	getDoc,
	updateDoc,
} from "firebase/firestore";
import { generateRandomPassword } from "../../app/utils/generatePassword";
import { db } from "../../config/firebase";
import { signupUser } from "./authReducer";
import axios from "axios";

const initialState = {
	data: {},
	isFetching: false,
	error: null,
	company: {},
	companyId: "",
	settings: {},
};

export const fetchUserData = createAsyncThunk(
	"user/fetch",
	async (userId, { rejectWithValue }) => {
		try {
			const userRef = doc(db, "users", userId);
			const userDoc = await getDoc(userRef);

			if (userDoc.exists()) {
				const userData = userDoc.data();
				let companyId;
				companyId = userData.companyId;

				return { userData, companyId };
			} else {
				return rejectWithValue("User not found");
			}
		} catch (error) {
			return rejectWithValue("User not found 5");
		}
	}
);

export const updateUser = createAsyncThunk(
	"user/update",
	async ({ userId, newData }, { dispatch, rejectWithValue }) => {
		try {
			const userRef = doc(db, "users", userId);
			await updateDoc(userRef, { ...newData });
			const res = await dispatch(fetchUserData(userId));
			if (!fetchUserData.fulfilled.match(res)) {
				rejectWithValue("Not being updated");
			}
			return;
		} catch (error) {
			rejectWithValue(error);
		}
	}
);

export const addEmployeeAsync = createAsyncThunk(
	"employees/addEmployee",
	async (employeeData, { dispatch }) => {
		const password = generateRandomPassword(12);
		try {
			const newEmployee = {
				...employeeData,
				credits: 0,
				password,
			};
			dispatch(signupUser({ dataUser: newEmployee, employee: true }));

			await axios.post(
				"https://api.nexo.com.pa/sendAccessUser/v1/",
				{ email: employeeData.email, password }, // Pasando el objeto directamente. Axios lo convertirá a JSON.
				{
					headers: {
						"Content-Type": "application/json",
					},
				}
			);
			return password;
		} catch (error) {
			return error;
		}
	}
);

export const updateEmployeeCredits = createAsyncThunk(
	"employees/updateEmployeeCredits",
	async ({ amount, employeeId }) => {
		try {
			const ref = collection(db, "employees");
			const newEmployee = {
				credits: amount,
			};
			await updateDoc(ref, employeeId, newEmployee);
		} catch (error) {
			return error;
		}
	}
);

export const fetchCompany = createAsyncThunk(
	"user/company/get",
	async ({ companyId, type }, { rejectWithValue }) => {
		try {
			const locType = type === "comercio" ? "comercios" : "companies";
			const companyRef = doc(db, locType, companyId);
			const companySnap = await getDoc(companyRef);
			const companyData = companySnap.data();
			return companyData;
		} catch (error) {
			rejectWithValue(error);
			return error;
		}
	}
);

export const fetchSettings = createAsyncThunk(
	"user/settings/get",
	async (props, { rejectWithValue }) => {
		try {
			const settingsRef = doc(db, "AppSettings", "InterfaceElements");
			const settingsDoc = await getDoc(settingsRef);
			const settingsData = settingsDoc.data();

			return settingsData;
		} catch (error) {
			rejectWithValue(error);
			return error;
		}
	}
);

export const updateCompany = createAsyncThunk(
	"company/update",
	async ({ companyId, newData }, { dispatch, rejectWithValue }) => {
		try {
			if (newData) {
				const companyRef = doc(db, "companies", companyId);
				await updateDoc(companyRef, { ...newData });
			}

			const updatedCompanyData = dispatch(
				fetchCompany({
					companyId,
					type: "company",
				})
			);

			return { companyId, company: { ...updatedCompanyData } };
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const updateComercio = createAsyncThunk(
	"comercio/update",
	async ({ companyId, newData }, { dispatch, rejectWithValue }) => {
		try {
			if (newData) {
				const companyRef = doc(db, "comercios", companyId);
				await updateDoc(companyRef, { ...newData });
			} else {
				rejectWithValue("Hubo un error porfavor intente denuevo!");
			}
			const updatedCompanyData = dispatch(
				fetchCompany({
					companyId,
					type: "comercio",
				})
			);
			return { companyId, company: { ...updatedCompanyData } };
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const deleteUser = createAsyncThunk("user/delete", async (userId) => {
	try {
		const userRef = doc(db, "users", userId);
		await deleteDoc(userRef);
		return userId;
	} catch (error) {
		throw error;
	}
});

const userSlice = createSlice({
	name: "user",
	initialState,
	reducers: {
		clearUserData: (state) => {
			Object.assign(state, {
				data: null,
				isFetching: false,
				error: null,
				company: {},
				settings: {},
				companyId: null,
			});
		},
		updateLogoUrl: (state, action) => {
			if (state.company) {
				state.company.logoUrl = action.payload;
			}
		},
	},

	extraReducers: (builder) => {
		builder
			.addCase(fetchUserData.pending, (state) => {
				state.isFetching = true;
				state.error = null;
			})
			.addCase(fetchUserData.fulfilled, (state, action) => {
				state.isFetching = false;
				state.data = action.payload.userData || state.data;
				state.companyId = state.companyId || action.payload.companyId;
				state.error = null;
			})
			.addCase(fetchUserData.rejected, (state, action) => {
				state.isFetching = false;
				state.error = action.payload;
			})
			.addCase(updateUser.pending, (state) => {
				state.isFetching = true;
				state.error = null;
			})
			.addCase(updateUser.fulfilled, (state, action) => {
				state.isFetching = false;
				state.error = null;
			})
			.addCase(updateUser.rejected, (state, action) => {
				state.isFetching = false;
				state.error = action.payload;
			})
			.addCase(fetchCompany.pending, (state) => {
				state.isFetching = true;
				state.error = null;
			})
			.addCase(fetchCompany.fulfilled, (state, action) => {
				state.isFetching = false;
				state.company = action.payload;
				state.error = null;
			})
			.addCase(fetchCompany.rejected, (state, action) => {
				state.isFetching = false;
				state.error = action.payload;
			})
			.addCase(fetchSettings.pending, (state) => {
				state.isFetching = true;
				state.error = null;
			})
			.addCase(fetchSettings.fulfilled, (state, action) => {
				state.isFetching = false;
				state.error = null;
				state.settings = action.payload;
			})
			.addCase(fetchSettings.rejected, (state, action) => {
				state.isFetching = false;
				state.error = action.payload;
			})
			.addCase(deleteUser.pending, (state) => {
				state.isFetching = true;
				state.error = null;
			})
			.addCase(deleteUser.fulfilled, (state, action) => {
				state.isFetching = false;
				state.data = null;
				state.error = null;
			})
			.addCase(deleteUser.rejected, (state, action) => {
				state.isFetching = false;
				state.error = action.payload;
			})
			.addCase(updateCompany.pending, (state) => {
				state.isFetching = true;
				state.error = null;
			})
			.addCase(updateCompany.fulfilled, (state, action) => {
				state.isFetching = false;
				state.company = action.payload.company;
				state.error = null;
			})
			.addCase(updateCompany.rejected, (state, action) => {
				state.isFetching = false;
				state.error = action.payload;
			})
			.addCase(updateComercio.pending, (state) => {
				state.isFetching = true;
				state.error = null;
			})
			.addCase(updateComercio.fulfilled, (state, action) => {
				state.isFetching = false;
				state.company = action.payload.company;
				state.error = null;
			})
			.addCase(updateComercio.rejected, (state, action) => {
				state.isFetching = false;
				state.error = action.payload;
			})
			.addCase(addEmployeeAsync.pending, (state) => {
				state.isFetching = true;
				state.error = null;
			})
			.addCase(addEmployeeAsync.fulfilled, (state, action) => {
				state.isFetching = false;
				state.error = null;
			})
			.addCase(addEmployeeAsync.rejected, (state, action) => {
				state.isFetching = false;
				state.error = action.payload;
			})
			.addCase(updateEmployeeCredits.pending, (state) => {
				state.isFetching = true;
				state.error = null;
			})
			.addCase(updateEmployeeCredits.fulfilled, (state, action) => {
				state.isFetching = false;
				state.error = null;
			})
			.addCase(updateEmployeeCredits.rejected, (state, action) => {
				state.isFetching = false;
				state.error = action.payload;
			});
	},
});

export const { clearUserData, updateLogoUrl } = userSlice.actions;
export default userSlice.reducer;

export const getCompany = (state) => state.user.company;
