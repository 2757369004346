import React from "react";
import { Box, Typography, useTheme } from "@mui/material";
// import CreditPayment from "./CreditPayment";
import { tokens } from "../../../theme";
import { useSelector } from "react-redux";

const Credits = () => {
	const { company } = useSelector((state) => state.user);
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);

	return (
		<Box
			sx={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				alignContent: "center",
				flexDirection: "column",
				boxShadow: "0px 0px 4px 2px rgba(0,0,0,0.3)",
				backgroundColor: colors.redAccent[500],
				color: "white",
				padding: "2rem",
				width: "auto",
				borderRadius: "2rem",
				margin: {
					phone: "1rem 2rem",
					tablet: "50px 100px",
					desktop: "5rem 20rem",
					desktoplg: "5rem 20rem",
				},
			}}
		>
			<span style={{ marginTop: "2rem" }}>
				<Typography variant="h3" weight="700">
					Amount To Pay{" "}
					{company.totalCredits
						? company?.totalCredits?.toLocaleString("en-US", {
								style: "currency",
								currency: "USD",
								minimumFractionDigits: 0,
							})
						: 0}
				</Typography>
			</span>
			<Box
				sx={{
					display: "flex",
					justifyContent: "center",
					height: "100%",
				}}
			>
				{/* <CreditAllocation /> */}
			</Box>
		</Box>
	);
};

export default Credits;
