import React, { useState, useRef } from "react";
import {
	GoogleMap,
	MarkerF,
	useJsApiLoader,
	Autocomplete,
} from "@react-google-maps/api";
import { TextField, Box, Typography, IconButton } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { GeoPoint } from "firebase/firestore";

const containerStyle = {
	width: "100%",
	height: "400px",
};
const libraries = ["places"];
const center = {
	lat: 8.9823792,
	lng: -79.5198696,
};

const MapWithAutocomplete = ({ setCoordinates }) => {
	const { isLoaded } = useJsApiLoader({
		googleMapsApiKey: "AIzaSyA0Uix8cYdxVkrshs00gbEK0T5cNR77IGM",
		libraries,
	});
	const [autocomplete, setAutocomplete] = useState(null);
	const [location, setLocation] = useState(center);
	const [showMap, setShowMap] = useState(false);
	const mapRef = useRef(null);
	const [address, setAddress] = useState(""); // State to hold the formatted address

	const onPlaceChanged = () => {
		if (autocomplete !== null) {
			const place = autocomplete.getPlace();
			setLocation({
				lat: place.geometry.location.lat(),
				lng: place.geometry.location.lng(),
			});
			const geoPoint = new GeoPoint(
				place.geometry.location.lat(),
				place.geometry.location.lng()
			);
			setAddress(place.formatted_address); // Update address state
			setCoordinates({
				array: [place.geometry.location.lat(), place.geometry.location.lng()],
				geoPoint,
				direction: place.formatted_address || "",
			});
		} else {
			console.log("Autocomplete is not loaded yet!");
		}
	};

	const handleMapClick = async (event) => {
		const lat = event.latLng.lat();
		const lng = event.latLng.lng();
		const updatedLocation = { lat, lng };

		const geocoder = new window.google.maps.Geocoder();
		geocoder.geocode({ location: updatedLocation }, (results, status) => {
			if (status === "OK" && results[0]) {
				setLocation(updatedLocation);
				setAddress(results[0].formatted_address); // Update address with the formatted address from the first result
				setCoordinates({
					array: [lat, lng],
					geoPoint: new GeoPoint(lat, lng),
					...updatedLocation,
					direction: results[0].formatted_address,
				});
			}
		});
	};

	const toggleMap = () => {
		setShowMap(!showMap);
	};

	if (!isLoaded) {
		return <div>Loading...</div>;
	}

	return (
		<Box>
			<Typography variant="h6" sx={{ mb: 2 }}>
				Enter Address or Choose on Map
			</Typography>
			<Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
				<Autocomplete
					onLoad={(autocomplete) => setAutocomplete(autocomplete)}
					onPlaceChanged={onPlaceChanged}
					fields={["place_id", "geometry", "formatted_address"]}
				>
					<TextField
						sx={{ width: "15rem" }}
						fullWidth
						label="Search for an address"
						value={address} // Bind the address state to the TextField
						onChange={(e) => setAddress(e.target.value)} // Handle manual address input changes
					/>
				</Autocomplete>
				<IconButton color="secondary" onClick={toggleMap}>
					<LocationOnIcon />
				</IconButton>
			</Box>
			{showMap && (
				<Box sx={{ mt: 2, position: "relative" }}>
					<GoogleMap
						mapContainerStyle={containerStyle}
						center={location || center}
						zoom={15}
						onClick={handleMapClick}
						ref={mapRef}
					>
						<MarkerF position={location} />
					</GoogleMap>
					<IconButton
						sx={{
							position: "absolute",
							top: 8,
							right: 8,
							color: "primary.main",
						}}
						onClick={() => setShowMap(false)}
						color="inherit"
					>
						<ExpandMoreIcon />
					</IconButton>
				</Box>
			)}
		</Box>
	);
};

export default MapWithAutocomplete;
