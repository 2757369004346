import React from "react";
import { useMediaQuery } from "@mui/material";
import SidebarDesktop from "./SidebarDesktop";
import SidebarMobile from "./SidebarMobile";
import { links } from "../../data/navbarLinks";

function SidebarComponent({ type }) {
	const isMobile = useMediaQuery((theme) => theme.breakpoints.down("tablet"));

	return (
		<>
			{isMobile ? (
				<SidebarMobile links={links} type={type} />
			) : (
				<SidebarDesktop links={links} type={type} />
			)}
		</>
	);
}

export default SidebarComponent;
