import React, { useEffect, useState } from "react";
import {
	Box,
	Typography,
	useMediaQuery,
	useTheme,
	ToggleButton,
	ToggleButtonGroup,
	Container,
} from "@mui/material";
import { Menu, MenuItem, sidebarClasses, Sidebar } from "react-pro-sidebar";
import { tokens } from "../../../../theme";
import { Outlet, useNavigate } from "react-router-dom";

const SettingsCompanies = () => {
	const navigate = useNavigate();
	const [activeTab, setActiveTab] = useState(
		localStorage.getItem("activeTab") || "userSettings"
	);
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);
	const isMobile = useMediaQuery((theme) => theme.breakpoints.down("tablet"));

	const handleTabChange = (event, newTab) => {
		if (newTab) {
			setActiveTab(newTab);
			localStorage.setItem("activeTab", newTab);
			navigate(`/home/settings/${newTab.toLowerCase()}`);
		}
	};

	return (
		<Container
			sx={{
				display: "flex",
				flexDirection: "column",
				height: "100vh",
				overflow: "hidden", // Ensure the container doesn't overflow
			}}
		>
			<Box
				sx={{
					position: "sticky",
					top: 0,
					zIndex: 200,
					margin: "2rem 0",
					display: "flex",
					width: "100%",
					justifyContent: "center",
				}}
			>
				<ToggleButtonGroup
					orientation="horizontal"
					value={activeTab}
					exclusive
					onChange={handleTabChange}
				>
					<ToggleButton value="company">Company Settings</ToggleButton>
					<ToggleButton value="user">User Settings</ToggleButton>
				</ToggleButtonGroup>
			</Box>
			<Box
				sx={{
					flex: 1,
					overflowY: "auto", // Enable vertical scrolling
					padding: "2rem 3rem",
				}}
			>
				<Outlet />
			</Box>
		</Container>
	);
};

export default SettingsCompanies;
