import { useState, useEffect } from "react";
import { db } from "../../../config/firebase";
import { collection, query, where, getDocs } from "firebase/firestore";

export const useSimpleCollection = (c, q) => {
	const [loading, setLoading] = useState(true);
	const [documents, setDocuments] = useState(null);

	useEffect(() => {
		const fetchData = async () => {
			try {
				let queryRef = collection(db, c);

				if (q) {
					queryRef = query(queryRef, where(...q));
				}

				const querySnapshot = await getDocs(queryRef);
				const data = querySnapshot.docs.map((doc) => ({
					id: doc.id,
					...doc.data(),
				}));

				setDocuments(data);
			} catch (err) {
				console.error("ERROR RETRIEVING DOCS:", err);
			} finally {
				setLoading(false);
			}
		};

		fetchData();
	}, [c, q]);

	return { loading, documents };
};

const useCollection = (collectionName, queryConditions, additionalQuery) => {
	const [employeeData, setEmployeeData] = useState([]);
	const [sectorCounts, setSectorCounts] = useState({});

	useEffect(() => {
		const fetchData = async () => {
			try {
				let queryRef = collection(db, collectionName);

				if (queryConditions && queryConditions.length > 0) {
					queryRef = query(queryRef, queryConditions[0]);

					for (let i = 1; i < queryConditions.length; i++) {
						queryRef = where(queryRef, ...queryConditions[i]);
					}
				}

				if (additionalQuery) {
					queryRef = additionalQuery(queryRef);
				}

				const querySnapshot = await getDocs(queryRef);
				const data = querySnapshot.docs.map((doc) => ({
					id: doc.id,
					...doc.data(),
				}));

				setEmployeeData(data);

				const updatedSectorCounts = data.reduce((counts, employee) => {
					const { sector } = employee;
					counts[sector] = (counts[sector] || 0) + 1;
					return counts;
				}, {});

				setSectorCounts(updatedSectorCounts);
			} catch (error) {
				console.error("Error fetching employee data:", error.message);
			}
		};

		fetchData();
	}, [collectionName, additionalQuery, queryConditions]);
	return { employeeData, sectorCounts };
};

export default useCollection;
