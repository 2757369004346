import React, { useState } from "react";
import MenuTwoToneIcon from "@mui/icons-material/MenuTwoTone";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import { Item } from "../../components";
import { Menu } from "react-pro-sidebar";

const SidebarMobile = ({ links, type }) => {
	const [selected, setSelected] = useState("");
	const { linksCompany, linksComercio, linksAdmin } = links;
	const { data, companyId } = useSelector((state) => state.user);
	const [menuToggle, handleMenuToggle] = useState(false);

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				height: !menuToggle ? "auto" : "100vh",
				width: !menuToggle ? "auto" : "40vw",
				background: "#fff",
				boxShadow: menuToggle
					? "0 0 5rem 100vh rgba(0,0,0,0.5)"
					: "0 0 5rem 0 rgba(0,0,0,0.5)", // Fix boxShadow transition
				padding: "2rem",
				borderRadius: !menuToggle ? "50px" : "0 50px 50px 0px",
				zIndex: 5,
				position: "fixed",
				top: "0",
				left: "0%",
				marginBottom: "220px",
				transition:
					"height 0.3s ease, border-radius 0.1s ease, box-shadow 0.1s ease-in-out", // Add boxShadow transition
			}}
		>
			{!menuToggle ? (
				<MenuTwoToneIcon
					color="#fff"
					size={27}
					onClick={() => handleMenuToggle(true)}
				/>
			) : (
				<MenuTwoToneIcon
					color="#fff"
					size={27}
					onClick={() => handleMenuToggle(false)}
					sx={{ marginBottom: "3rem" }}
				/>
			)}
			<Menu iconShape="square">
				{menuToggle && (
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							gap: "2rem",
						}}
					>
						{(data.worksAt.type === "admin"
							? linksAdmin
							: data?.worksAt?.type === "comercio"
								? linksComercio
								: linksCompany
						).map((link, index) => {
							return (
								<span key={index} onClick={() => handleMenuToggle(false)}>
									<Item
										name={link.name}
										path={
											link.name === "Employees" || link.name === "Dashboard"
												? `${link.path}/${companyId}`
												: link.path
										}
										icon={link.icon}
										selected={selected}
										setSelected={setSelected}
									/>
								</span>
							);
						})}
					</div>
				)}
			</Menu>
		</Box>
	);
};

export default SidebarMobile;
