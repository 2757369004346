import React from "react";
import { Box, Card, CardHeader, CardContent, Typography } from "@mui/material";
import {
	LineChart,
	Line,
	XAxis,
	Tooltip,
	ResponsiveContainer,
	AreaChart,
	Area,
} from "recharts";
import { GridArrowDownwardIcon, GridArrowUpwardIcon } from "@mui/x-data-grid";
import { useTheme } from "@emotion/react";

const InfoCard = ({
	gradientKey,
	data,
	totalSales,
	percentageChange,
	title,
	subtitle,
}) => {
	const theme = useTheme();

	return (
		<Card
			raised
			sx={{
				background:
					"radial-gradient(circle, rgba(249,232,235,0.5) 14%, rgba(255,160,176,0) 98%)",
				borderRadius: 2,
				p: 0,
				height: "12rem",
				boxShadow: "0 3px 10px rgba(0, 0, 0, 0.2)",
			}}
		>
			<CardContent>
				<Typography color="text.secondary" gutterBottom>
					{title}
				</Typography>
				<Typography variant="h5" component="div">
					{totalSales}
				</Typography>
				{subtitle ? (
					<Box display="flex" alignItems="center" color="main">
						{percentageChange > 0 ? (
							<GridArrowUpwardIcon fontSize="small" htmlColor="green" />
						) : (
							<GridArrowDownwardIcon fontSize="small" htmlColor="red" />
						)}
						<Typography variant="body2">{subtitle}</Typography>
					</Box>
				) : (
					<div style={{ marginBottom: "1rem" }}></div>
				)}
			</CardContent>
			<CardContent sx={{ p: 0, "&:last-child": { paddingBottom: 0 } }}>
				<ResponsiveContainer width="100%" height={100}>
					<AreaChart
						data={data}
						margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
					>
						<defs>
							<linearGradient id={"colorUv1"} x1="0" y1="0" x2="0" y2="1">
								<stop offset="50%" stopColor="#db33ff" stopOpacity={0.8} />
								<stop offset="95%" stopColor="#db33ff" stopOpacity={0.4} />
							</linearGradient>
							<linearGradient id={"colorUv2"} x1="0" y1="0" x2="0" y2="1">
								<stop offset="50%" stopColor="#FF3357" stopOpacity={0.8} />
								<stop offset="95%" stopColor="#FF3357" stopOpacity={0.4} />
							</linearGradient>
							<linearGradient id={"colorUv3"} x1="0" y1="0" x2="0" y2="1">
								<stop offset="50%" stopColor="#7533FF" stopOpacity={0.8} />
								<stop offset="95%" stopColor="#7533FF" stopOpacity={0.4} />
							</linearGradient>
						</defs>
						<XAxis dataKey="name" hide />
						<Area
							type="monotoneX"
							dataKey="value"
							stroke={`url(#colorUv${String(gradientKey)})`}
							fillOpacity={1}
							fill={`url(#colorUv${String(gradientKey)})`}
						/>
					</AreaChart>
				</ResponsiveContainer>
			</CardContent>
		</Card>
	);
};

export default InfoCard;
