// blogSlice.js

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { submitBlogToFirebase } from "../../app/utils/firebase/addingDocument";
import { handleImageUpload } from "../../app/utils/firebase/addingImages";

export const writeBlogAsync = createAsyncThunk(
	"blog/writeBlogAsync",
	async (blogData, { rejectWithValue }) => {
		try {
			const imageUrls = await handleImageUpload({
				images: [blogData.image],
				path: "/blog/",
			});

			await submitBlogToFirebase({
				title: blogData.title,
				subtitle: blogData.subtitle,
				content: blogData.content,
				imageUrls,
			});

			return "Blog submitted successfully";
		} catch (error) {
			return rejectWithValue("Error submitting blog");
		}
	}
);

const blogSlice = createSlice({
	name: "blog",
	initialState: {},
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(writeBlogAsync.fulfilled, (state, action) => {});
		builder.addCase(writeBlogAsync.rejected, (state, action) => {
			console.error(action.payload); // You can handle errors here
		});
	},
});

export default blogSlice.reducer;
