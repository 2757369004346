import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import React from "react";
import { useDispatch } from "react-redux";
import { fetchTransactions } from "../../../store/reducers/transactionsReducer";

const StyledButton = styled(Button)({
	color: "white",
	padding: "10px 20px",
	fontSize: "16px",
	"&:hover": {
		backgroundColor: "#secondary",
	},
	"&:active": {
		boxShadow: "none",
		backgroundColor: "primary.main",
	},
	boxShadow: "0 3px 5px 2px rgba(233, 0, 24, .3)",
});

const FetchTransactionsButton = ({
	companyId,
	createdAtStart,
	createdAtEnd,
	type,
}) => {
	const dispatch = useDispatch();

	const cas = createdAtStart + " 00:00:00";
	const cae = createdAtEnd + " 00:00:00";

	const handleClick = () => {
		if (cas && cae) {
			dispatch(
				fetchTransactions({
					companyId,
					createdAtStart: cas,
					createdAtEnd: cae,
					type,
				})
			);
		}
	};

	return (
		<StyledButton onClick={handleClick} variant="contained">
			Actualizar Transacciones
		</StyledButton>
	);
};

export default FetchTransactionsButton;
