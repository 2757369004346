import React from "react";
import { Box } from "@mui/material";

const Fallback = () => {
	return (
		<Box
			sx={{
				display: "flex",
				height: "100vh",
				width: "100%",
				justifyContent: "center",
				alignContent: "center",
				alignItems: "center",
			}}
		>
			<img
				src="./assets/fallback.gif"
				width={"50rem"}
				height={"auto"}
				alt="fallback gif"
			/>
		</Box>
	);
};

export default Fallback;
