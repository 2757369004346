import React, { useEffect, useState } from "react";
import { TextField, Button, Grid, Card, CardMedia, Box } from "@mui/material";
import { useSelector } from "react-redux";
import { getDownloadURL, ref as storageRef } from "firebase/storage";
import { db, storage } from "../../../../config/firebase";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { handleImageUpload } from "../../../utils/firebase/addingImages";

const ImageGallery = ({ companyId }) => {
	const [imageUrls, setImageUrls] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const fetchImages = async () => {
			try {
				const companyDoc = await getDoc(doc(db, "comercios", companyId));
				const data = companyDoc.data();

				if (data && data.imagesURLs) {
					const urls = await Promise.all(
						data.imagesURLs.map(async (url) => {
							const downloadURL = await getDownloadURL(
								storageRef(storage, url)
							);
							return downloadURL;
						})
					);

					setImageUrls(urls);
				}
			} catch (error) {
				console.error("Error fetching images:", error.message);
			} finally {
				setLoading(false);
			}
		};

		fetchImages();
	}, [companyId]);

	if (loading) {
		return <div>Loading...</div>;
	}

	return (
		<Grid container spacing={2}>
			{imageUrls.map((imageUrl, index) => (
				<Grid item key={index}>
					<Card>
						<CardMedia
							component="img"
							alt={`Image ${index}`}
							height="140"
							image={imageUrl}
						/>
					</Card>
				</Grid>
			))}
		</Grid>
	);
};

const ImageImporter = ({ closeModal, comercioId }) => {
	const [images, setImages] = useState([]);
	const { companyId } = useSelector((state) => state.user);

	const handleFileChange = async (event) => {
		const files = event.target.files;
		const imagesArray = [];

		for (const file of files) {
			const imageUrl = await new Promise((resolve) => {
				const reader = new FileReader();
				reader.onloadend = () => resolve(reader.result);
				reader.readAsDataURL(file);
			});

			imagesArray.push(imageUrl);
		}

		setImages((prevImages) => [...prevImages, ...files]);
	};

	const handleUpload = async () => {
		try {
			const imageUrls = await handleImageUpload({
				images,
				path: `images/${companyId}/`,
			});

			await updateDoc(doc(db, "comercios", companyId), {
				imagesURLs: imageUrls,
			});
		} catch (err) {
			console.log(err);
		}
	};

	return (
		<Box
			sx={{
				color: "black", // Change text color to black
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				gap: "2rem",
				padding: "2rem",
				backgroundColor: "white",
				borderRadius: "12px",
			}}
		>
			<form>
				<TextField
					type="file"
					multiple
					onChange={(event) => handleFileChange(event)}
				/>
				<Button variant="contained" onClick={handleUpload}>
					Upload Images
				</Button>
			</form>
			<div>
				<h2>Image Gallery</h2>
				<ImageGallery companyId={companyId} />
			</div>
		</Box>
	);
};

export default ImageImporter;
