import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	Button,
	Grid,
	Typography,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import {
	updateBranch,
	deleteBranch,
	fetchBranches,
} from "../../../../store/reducers/branchReducer";
import { AddBranchModal } from "../../../components/modals/AddBranchModal";
import {
	collection,
	deleteDoc,
	getDocs,
	query,
	where,
} from "firebase/firestore";
import { db, auth } from "../../../../config/firebase";
import { UpdateBranchModal } from "../../../components/modals/UpdateBranchModal";
import { deleteUser } from "firebase/auth";

const BranchGeneral = () => {
	const dispatch = useDispatch();
	const companyId = useSelector((state) => state.user.data.companyId);
	const branches = useSelector((state) => state.branches.data || []); // Assuming branches data is available in the state
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isModalUpdateOpen, setIsModalUpdateOpen] = useState(false);
	const [selectedBranch, setSelectedBranch] = useState(null);

	useEffect(() => {
		// Load branches here if not already loaded
	}, []);

	const columns = [
		{ field: "id", headerName: "ID", width: 90 },
		{ field: "branchName", headerName: "Nombre de sucursal", width: 150 },
		{ field: "branchPhone", headerName: "Teléfono", width: 150 },
		{ field: "branchDirection", headerName: "Dirección", width: 200 },
		{ field: "description", headerName: "Descripción", width: 250 },
		{
			field: "actions",
			headerName: "Acciones",
			sortable: false,
			width: 150,
			renderCell: (params) => (
				<React.Fragment>
					<Button
						color="primary"
						onClick={() => handleOpenEditDialog(params.row)}
					>
						Editar
					</Button>
					<Button color="error" onClick={() => handleDelete(params.id)}>
						Eliminar
					</Button>
				</React.Fragment>
			),
		},
	];

	const handleOpenEditDialog = (branch) => {
		setSelectedBranch(branch);
		setIsModalUpdateOpen(true);
	};

	const handleCloseModal = () => {
		setIsModalOpen(false);
		setIsModalUpdateOpen(false);
		setSelectedBranch(null);
	};

	const handleDelete = async (branchId) => {
		const branchesCollection = collection(db, "branches");
		// Query to find documents in the branches collection with the matching branchId
		const branchesQuery = query(
			branchesCollection,
			where("branchId", "==", branchId)
		);
		await deleteUsersByBranchId(branchId);
		// Execute the query
		const querySnapshot = await getDocs(branchesQuery);
		querySnapshot.forEach(async (doc) => {
			await deleteDoc(doc.ref); // Delete each document found
		});

		dispatch(fetchBranches(companyId));
	};

	const deleteUsersByBranchId = async (branchId) => {
		const usersRef = collection(db, "users");
		const branchQuery = query(
			usersRef,
			where("worksAt.branchId", "==", branchId)
		);

		try {
			const querySnapshot = await getDocs(branchQuery);
			const deletePromises = [];

			querySnapshot.forEach((doc) => {
				// Assuming the UID is stored in the document under a 'uid' field
				const uid = doc.data().id; // Correctly reference the UID

				// Promise to delete the user's document from Firestore
				deletePromises.push(deleteDoc(doc.ref));
				console.log(doc.data()); // Logging the document data for debugging

				// Promise to delete the user from Firebase Authentication
				deletePromises.push(deleteUser(auth, uid));
			});

			// Wait for all deletions to complete
			await Promise.all(deletePromises);
			console.log(
				`Successfully deleted all users and their auth records for branchId ${branchId}`
			);
		} catch (error) {
			console.error("Error deleting users by branchId:", error);
		}
	};

	const handleUpdate = (branchData) => {
		if (companyId && selectedBranch) {
			dispatch(
				updateBranch({ companyId, branchId: selectedBranch.id, branchData })
			);
			handleCloseModal();
		}
	};

	return (
		<div style={{ height: 400, width: "100%" }}>
			<Typography variant="h6" gutterBottom>
				Información General de la Sucursal
			</Typography>
			<Button variant="outlined" onClick={() => setIsModalOpen(true)}>
				Añadir nueva sucursal
			</Button>
			<Button onClick={() => dispatch(fetchBranches(companyId))}>
				Actualizar Sucursales Activas
			</Button>
			<DataGrid
				rows={branches}
				columns={columns}
				pageSize={5}
				checkboxSelection
				sx={{
					"& .MuiDataGrid-cellCheckbox": {
						color: "secondary.main", // Change as needed
					},
					"& .MuiCheckbox-root.Mui-checked": {
						color: "secondary.main", // Use theme color or specific color
					},
					"& .MuiDataGrid-cellCheckbox svg": {
						fill: "secondary.main", // For the checkbox icon itself
					},
				}}
			/>
			<AddBranchModal isOpen={isModalOpen} onClose={handleCloseModal} />
			<UpdateBranchModal
				isOpen={isModalUpdateOpen}
				onClose={handleCloseModal}
				selectedBranch={selectedBranch}
				onSave={handleUpdate}
			/>
		</div>
	);
};

export default BranchGeneral;
