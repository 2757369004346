import React from "react";
import { Typography, Box, useTheme, useMediaQuery } from "@mui/material";
import { tokens } from "../../../theme";

const Header = ({ title, subtitle }) => {
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);
	const isMobile = useMediaQuery((theme) => theme.breakpoints.down("tablet"));
	return (
		<Box
			sx={
				isMobile
					? {
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							justifyContent: "center",
					  }
					: {}
			}
		>
			<Typography
				variant="h2"
				color={colors.grey[100]}
				fontWeight="bold"
				sx={{ m: "0 0 5px 0" }}
			>
				{title}
			</Typography>
			<Typography variant="h5" color={colors.redAccent[500]}>
				{subtitle}
			</Typography>
		</Box>
	);
};

export default Header;
