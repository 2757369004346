import React from "react";
import CircularProgress from "@mui/joy/CircularProgress";
import { CssVarsProvider } from "@mui/joy/styles";
import { Box } from "@mui/material";

const LoadingState = ({ size = "lg" }) => {
	return (
		<CssVarsProvider>
			<Box
				sx={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					alignContent: "center",
					width: "100%",
					height: "100%",
				}}
			>
				<CircularProgress
					variant="solid"
					color="primary"
					size={size.toString()}
				/>
			</Box>
		</CssVarsProvider>
	);
};

export default LoadingState;
