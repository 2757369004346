import React, { lazy, Suspense } from "react";
import { ColorModeContext, useMode } from "./theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { Error } from "./app/containers";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Fallback from "./app/components/state/Fallback";
import { getEmployees } from "./app/utils/getEmployees";
import { getAllEmployees } from "./admin/utils/getAllEmployees";
import { getAllCompanies } from "./admin/utils/getAllCompanies";
import { getAllComercios } from "./admin/utils/getAllComercios";
import TestSettings from "./app/containers/global/TestSettings";
import SettingsAdmin from "./admin/containers/SettingsAdmin";

///////////// Lazy-loaded components*////////////////////////////////
const HomeLayout = lazy(
	() => import(/* webpackChunkName: "Land" */ "./home/HomeLayout")
);

const Home = lazy(
	() => import(/* webpackChunkName: "Landing" */ "./home/Home")
);
const BlogGrid = lazy(
	() =>
		import(/* webpackChunkName: "BlogGrid" */ "./home/containers/blog/BlogGrid")
);
const BlogPage = lazy(
	() =>
		import(/* webpackChunkName: "BlogPage" */ "./home/containers/blog/BlogPage")
);
const Login = lazy(
	() => import(/* webpackChunkName: "Login" */ "./app/containers/auth/Login")
);
const SignUp = lazy(
	() =>
		import(
			/* webpackChunkName: "SignUp" */ "./app/containers/auth/signup/SignUp"
		)
);
const SignUpLayout = lazy(
	() =>
		import(
			/* webpackChunkName: "SignUpLayout" */ "./app/containers/auth/signup/SignUpLayout"
		)
);
const AdminAppLayout = lazy(
	() => import(/* webpackChunkName: "Admin" */ "./admin/AdminAppLayout")
);
const AdminDashboard = lazy(
	() =>
		import(
			/* webpackChunkName: "AdminDashboard" */ "./admin/containers/AdminDashboard"
		)
);
const CompanyInformation = lazy(
	() =>
		import(
			/* webpackChunkName: "AdminCompanies" */ "./admin/containers/CompanyInformation"
		)
);
const ComercioInformation = lazy(
	() =>
		import(
			/* webpackChunkName: "AdminComercios" */ "./admin/containers/ComercioInformation"
		)
);
const UserInformation = lazy(
	() =>
		import(
			/* webpackChunkName: "AdminUsers" */ "./admin/containers/UserInformation"
		)
);
const WriteBlogs = lazy(
	() =>
		import(/* webpackChunkName: "AdminBlogs" */ "./admin/containers/WriteBlogs")
);
const AppLayout = lazy(
	() => import(/* webpackChunkName: "Home" */ "./app/AppLayout")
);
const Dashboard = lazy(
	() =>
		import(
			/* webpackChunkName: "homedash" */
			/* webpackMode: "lazy" */
			/* webpackFetchPriority: "high" */ "./app/containers/global/Dashboard"
		)
);
const Employees = lazy(
	() =>
		import(
			/* webpackChunkName: "employees" */
			/* webpackMode: "lazy" */
			/* webpackFetchPriority: "high" */ "./app/containers/companies/Employees"
		)
);
const Credits = lazy(
	() =>
		import(
			"./app/containers/companies/Credits" /* webpackChunkName: "AppCredits" */
			/* webpackMode: "lazy" */
			/* webpackFetchPriority: "high" */
		)
);
const Settings = lazy(
	() =>
		import(
			/* webpackChunkName: "AppSettings" */
			/* webpackMode: "lazy" */
			/* webpackFetchPriority: "high" */ "./app/containers/global/Settings"
		)
);
const GeneralSettings = lazy(
	() =>
		import(
			/* webpackChunkName: "AppSettingsGeneral" */
			/* webpackMode: "lazy" */
			/* webpackFetchPriority: "high" */ "./app/containers/companies/settingcomponents/GeneralSettings"
		)
);
const ManageSectors = lazy(
	() =>
		import(
			/* webpackChunkName: "AppSettingsSectors" */
			/* webpackMode: "lazy" */
			/* webpackFetchPriority: "high" */ "./app/containers/companies/settingcomponents/ManageSectors"
		)
);
const UserSettings = lazy(
	() =>
		import(
			/* webpackChunkName: "AppSettingsUsers" */
			/* webpackMode: "lazy" */
			/* webpackFetchPriority: "high" */ "./app/containers/global/UserSettings"
		)
);

const ImageImporter = lazy(
	() =>
		import(
			/* webpackChunkName: "AppSettingImages" */ "./app/containers/comercios/settings/ImageImporter"
		)
);
const BranchSettings = lazy(
	() =>
		import(
			/* webpackChunkName: "AppSettingBranches" */ "./app/containers/comercios/settings/BranchSettings"
		)
);
const ComercioSettings = lazy(
	() =>
		import(
			/* webpackChunkName: "AppSettingGeneral" */ "./app/containers/comercios/settings/Settings"
		)
);
const ImportReviews = lazy(
	() =>
		import(
			/* webpackChunkName: "AppSettingReviews" */ "./app/containers/comercios/settings/ImportReviews"
		)
);
const PromoSettingComponent = lazy(
	() =>
		import(
			/* webpackChunkName: "AppSettingReviews" */ "./app/containers/comercios/settings/AddPromosSettings"
		)
);
const Transactions = lazy(
	() =>
		import(
			/* webpackChunkName: "AppTransactions" */ "./app/containers/comercios/Transactions"
		)
);
const Test = lazy(() => import("./app/containers/Test"));

function App() {
	const [theme, colorMode] = useMode();
	const router = createBrowserRouter([
		{
			path: "/",
			element: (
				<Suspense fallback={<Fallback />}>
					<HomeLayout />
				</Suspense>
			),
			errorElement: <Error />,
			children: [
				{
					path: "/",
					element: (
						<Suspense fallback={<Fallback />}>
							<Home />
						</Suspense>
					),
				},
				{
					path: "/blogs",
					element: (
						<Suspense fallback={<Fallback />}>
							<BlogGrid />
						</Suspense>
					),
				},
				{
					path: "/blogs/:id",
					element: (
						<Suspense fallback={<Fallback />}>
							<BlogPage />
						</Suspense>
					),
				},
			],
		},
		{
			path: "/login",
			element: (
				<Suspense fallback={<Fallback />}>
					<Login />
				</Suspense>
			),
			errorElement: <Error />,
		},
		{
			path: "/signup",
			element: (
				<Suspense fallback={<Fallback />}>
					<SignUp />
				</Suspense>
			),
			errorElement: <Error />,
		},
		{
			path: "/signup/:type",
			element: (
				<Suspense fallback={<Fallback />}>
					<SignUpLayout />
				</Suspense>
			),
			errorElement: <Error />,
		},
		{
			path: "/admin",
			element: (
				<Suspense fallback={<Fallback />}>
					<AdminAppLayout />
				</Suspense>
			),
			errorElement: <Error />,
			children: [
				{
					path: "/admin/dashboard",
					element: (
						<Suspense fallback={<Fallback />}>
							<AdminDashboard />
						</Suspense>
					),
					errorElement: <Error />,
				},
				{
					path: "/admin/companies",
					element: (
						<Suspense fallback={<Fallback />}>
							<CompanyInformation />
						</Suspense>
					),
					errorElement: <Error />,
					loader: getAllCompanies,
				},
				{
					path: "/admin/Comercios",
					element: (
						<Suspense fallback={<Fallback />}>
							<ComercioInformation />
						</Suspense>
					),
					errorElement: <Error />,
					loader: getAllComercios,
				},
				{
					path: "/admin/users",
					element: (
						<Suspense fallback={<Fallback />}>
							<UserInformation />
						</Suspense>
					),
					errorElement: <Error />,
					loader: getAllEmployees,
				},
				{
					path: "/admin/blogedit",
					element: (
						<Suspense fallback={<Fallback />}>
							<SettingsAdmin />
						</Suspense>
					),
					errorElement: <Error />,
				},
			],
		},
		{
			path: "/home",
			element: (
				<Suspense fallback={<Fallback />}>
					<AppLayout />
				</Suspense>
			),
			errorElement: <Error />,
			children: [
				{
					path: "/home/dashboard/:id",
					element: (
						<Suspense fallback={<Fallback />}>
							<Dashboard />
						</Suspense>
					),
					errorElement: <Error />,
				},
				{
					path: "/home/employees/:id",
					element: (
						<Suspense fallback={<Fallback />}>
							<Employees />
						</Suspense>
					),
					errorElement: <Error />,
					loader: getEmployees,
				},
				{
					path: "/home/credits",
					element: (
						<Suspense fallback={<Fallback />}>
							<Credits />
						</Suspense>
					),
					errorElement: <Error />,
				},
				{
					path: "/home/settings",
					element: (
						<Suspense fallback={<Fallback />}>
							<Settings />
						</Suspense>
					),
					errorElement: <Error />,
					children: [
						{
							path: "/home/settings/company",
							element: (
								<Suspense fallback={<Fallback />}>
									<GeneralSettings />
								</Suspense>
							),
						},
						{
							path: "/home/settings/sectors",
							element: (
								<Suspense fallback={<Fallback />}>
									<ManageSectors />
								</Suspense>
							),
						},
						{
							path: "/home/settings/promos",
							element: (
								<Suspense fallback={<Fallback />}>
									<PromoSettingComponent />
								</Suspense>
							),
						},
						{
							path: "/home/settings/imagenes",
							element: (
								<Suspense fallback={<Fallback />}>
									<TestSettings />
								</Suspense>
							),
						},
						{
							path: "/home/settings/user",
							element: (
								<Suspense fallback={<Fallback />}>
									<UserSettings />
								</Suspense>
							),
						},

						{
							path: "/home/settings/branches",
							element: (
								<Suspense fallback={<Fallback />}>
									<BranchSettings />
								</Suspense>
							),
						},
						{
							path: "/home/settings/general",
							element: (
								<Suspense fallback={<Fallback />}>
									<ComercioSettings />
								</Suspense>
							),
						},
						{
							path: "/home/settings/reviews",
							element: (
								<Suspense fallback={<Fallback />}>
									<ImportReviews />
								</Suspense>
							),
						},
					],
				},
				{
					path: "/home/transactions",
					element: (
						<Suspense fallback={<Fallback />}>
							<Transactions />
						</Suspense>
					),
					errorElement: <Error />,
				},
			],
		},
		{
			path: "/test",
			element: (
				<Suspense fallback={<Fallback />}>
					<Test />
				</Suspense>
			),
			errorElement: <Error />,
		},
	]);

	return (
		<ColorModeContext.Provider value={colorMode}>
			<ThemeProvider theme={theme}>
				<CssBaseline />
				<RouterProvider router={router} fallbackElement={<Fallback />} />
			</ThemeProvider>
		</ColorModeContext.Provider>
	);
}

export default App;
