import { collection, getDocs } from "firebase/firestore";
import { db } from "../../config/firebase";

async function fetchAllEmployees() {
	let queryRef = collection(db, "employees");

	const querySnapshot = await getDocs(queryRef);
	const employees = querySnapshot.docs.map((doc) => ({
		id: doc.id,
		...doc.data(),
	}));

	return employees;
}

export const getAllEmployees = async () => {
	try {
		return fetchAllEmployees();
	} catch (err) {
		console.error("ERROR RETRIEVING DOCS:", err);
	}
};
