import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { composeWithDevTools } from "redux-devtools-extension";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import authSlice from "./authReducer";
import userSlice from "./userReducer";
import transactionsSlice from "./transactionsReducer";
import blogSlice from "./blogReducer";
import branchesSlice from "./branchReducer";

const persistConfig = {
	key: "root",
	storage,
};

const rootReducer = combineReducers({
	auth: authSlice,
	user: userSlice,
	transactions: transactionsSlice,
	blog: blogSlice,
	branches: branchesSlice,
});

const store = configureStore({
	reducer: persistReducer(persistConfig, rootReducer),
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false,
		}),
	devTools: composeWithDevTools(),
});

const persistor = persistStore(store);

export { store, persistor };
