import {
	Box,
	Button,
	InputAdornment,
	InputLabel,
	TextField,
	Typography,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import axios from "axios";
import {
	collection,
	doc,
	getDocs,
	query,
	updateDoc,
	where,
} from "firebase/firestore";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { db } from "../../../../config/firebase";
import { updateLogoUrl } from "../../../../store/reducers/userReducer";

const GeneralSettings = () => {
	const {
		companyId,
		worksAt: { type },
	} = useSelector((state) => state.user.data);
	const { comercioName, companyName } = useSelector(
		(state) => state.user.company
	);
	const [newCompanyName, setNewCompanyName] = useState("");
	const [newCeoName, setNewCeoName] = useState("");
	const [newCompanyPhone, setNewCompanyPhone] = useState("");
	const [newLogoFile, setNewLogoFile] = useState(null);
	const dispatch = useDispatch();

	const handleUpdate = async (e) => {
		e.preventDefault();
		try {
			const updates = {};

			if (newCompanyName) {
				updates.companyName = newCompanyName;
			}

			if (newCeoName) {
				updates.ceoName = newCeoName;
			}

			if (newCompanyPhone) {
				updates.companyPhone = newCompanyPhone;
			}

			if (newLogoFile) {
				await handleImageUpload();
			}
			// Add selected tags to updates

			await updateDoc(
				doc(db, type === "comercio" ? "comercios" : type, companyId),
				updates
			);

			alert("Settings updated successfully!");
		} catch (error) {
			console.error("Error updating settings:", error.message);
		}
	};

	const handleLogoChange = (event) => {
		const file = event.target.files[0];
		setNewLogoFile(file);
	};

	const handleImageUpload = async () => {
		const formData = new FormData();

		formData.append("imagenes[]", newLogoFile);

		const n = type === "comercio" ? comercioName : companyName;

		// Update to match backend processing
		formData.append("comercio", n.trim().replace(/\W/g, "").toLowerCase());
		formData.append("type", "logo");

		try {
			const res = await axios.post(
				"https://api.nexo.com.pa/addslider/v1/",
				formData,
				{
					headers: {
						"Content-Type": "multipart/form-data",
					},
				}
			);

			const logoUrl = res.data[0].url;
			await updateDoc(
				doc(db, type === "comercio" ? "comercios" : type, companyId),
				{ logoUrl }
			);

			const branchesQuery = query(
				collection(db, "branches"),
				where("companyID", "==", companyId)
			);

			try {
				const querySnapshot = await getDocs(branchesQuery);

				querySnapshot.forEach(async (doc) => {
					const docRef = doc.ref;
					await updateDoc(docRef, { logoUrl });
				});

				console.log("All matching branches updated successfully.");
			} catch (error) {
				console.error("Error updating branches:", error);
			}

			dispatch(updateLogoUrl(logoUrl));
		} catch (err) {
			console.log(err);
		}
	};

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				gap: 4,
				alignItems: "start",
				p: 4,
				borderRadius: 2,
				boxShadow: 3,
				bgcolor: "background.paper",
				"& .MuiTextField-root": { m: 1, width: "25ch" },
			}}
		>
			<Typography variant="h5">General Settings</Typography>
			<form
				onSubmit={handleUpdate}
				style={{ display: "flex", flexDirection: "column ", gap: "1rem" }}
			>
				<div
					style={{ display: "flex", flexDirection: "column", gap: "0.3rem" }}
				>
					<TextField
						sx={{ width: "20rem" }}
						label="Nuevo nombre de compañia"
						value={newCompanyName}
						onChange={(e) => setNewCompanyName(e.target.value)}
					/>
				</div>

				<div
					style={{ display: "flex", flexDirection: "column", gap: "0.3rem" }}
				>
					<TextField
						sx={{ width: "20rem" }}
						label="Nuevo nombre de CEO"
						value={newCeoName}
						onChange={(e) => setNewCeoName(e.target.value)}
					/>
				</div>

				<div
					style={{ display: "flex", flexDirection: "column", gap: "0.3rem" }}
				>
					<TextField
						sx={{ width: "20rem" }}
						label="Nuevo número de telefono"
						value={newCompanyPhone}
						onChange={(e) => setNewCompanyPhone(e.target.value)}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">+507</InputAdornment>
							),
						}}
					/>
				</div>

				<div
					style={{ display: "flex", flexDirection: "column", gap: "0.3rem" }}
				>
					<InputLabel>Foto de Perfil</InputLabel>
					<TextField
						sx={{ width: "20rem" }}
						type="file"
						onChange={handleLogoChange}
					/>
				</div>

				<Button type="submit">Update Settings</Button>
			</form>
		</Box>
	);
};

export default GeneralSettings;
