export const links = {
	linksCompany: [
		{
			name: "Dashboard",
			icon: "HomeIcon",
			path: "/home/dashboard",
		},
		{
			name: "Employees",
			icon: "PersonIcon",
			path: "/home/employees",
		},
		{
			name: "Transactions",
			icon: "AccountBalanceWalletIcon",
			path: "/home/transactions",
		},
		{
			name: "Settings",
			icon: "SettingsIcon",
			path: "/home/settings/user",
		},
	],
	linksComercio: [
		{
			name: "Dashboard",
			icon: "HomeIcon",
			path: "/home/dashboard",
		},
		{
			name: "Transactions",
			icon: "AccountBalanceWalletIcon",
			path: "/home/transactions",
		},
		// {
		//   name: "QR Scanning",
		//   icon: "QRicon",
		//   path: "/home/qr",
		// },
		{
			name: "Settings",
			icon: "SettingsIcon",
			path: "/home/settings/user",
		},
	],
	linksAdmin: [
		{
			name: "Admin",
			icon: "HomeIcon",
			path: "/admin/dashboard",
		},
		{
			name: "Companies",
			icon: "AccountBalanceWalletIcon",
			path: "/admin/companies",
		},
		{
			name: "Comercios",
			icon: "AccountBalanceWalletIcon",
			path: "/admin/comercios",
		},
		{
			name: "Users",
			icon: "PersonIcon",
			path: "/admin/users",
		},
		{
			name: "Blog",
			icon: "SettingsIcon",
			path: "/admin/blogedit",
		},
	],
};
