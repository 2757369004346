import React from "react";
import { Typography, Paper, Container, useTheme } from "@mui/material";
import HorasDeApertura from "./HorasDeApertura";
import PromoSettingComponent from "./AddPromosSettings";
import BranchGeneral from "./BranchGeneral";

const BranchSettings = () => {
	const theme = useTheme();

	return (
		<Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
			<Typography variant="h4" gutterBottom>
				Ajustes de Sucursal
			</Typography>
			<Paper
				elevation={3}
				sx={{
					p: theme.spacing(4),
					gap: theme.spacing(3),
					display: "flex",
					flexDirection: "column",
				}}
			>
				<BranchGeneral />
				<hr style={{ margin: theme.spacing(3, 0) }} />
				<PromoSettingComponent />
				<hr style={{ margin: theme.spacing(3, 0) }} />
				<HorasDeApertura />
			</Paper>
		</Container>
	);
};

export default BranchSettings;
