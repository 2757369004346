import React from "react";
import { Box, Card, CardContent, Typography } from "@mui/material";
import {
	LineChart,
	Line,
	XAxis,
	Tooltip,
	ResponsiveContainer,
	AreaChart,
	Area,
	PieChart,
	Pie,
	Cell,
	Legend,
} from "recharts";
import { GridArrowUpwardIcon } from "@mui/x-data-grid";
import { useTheme } from "@emotion/react";

const BiggerInfoCard = ({
	data,
	totalSales,
	percentageChange,
	title,
	subtitle,
}) => {
	const theme = useTheme();

	const COLORS = ["#0088FE", "#00C49F", "#7533ff", "#FF3357"];

	return (
		<Card
			raised
			sx={{
				background:
					"radial-gradient(circle, rgba(249,232,235,0.5) 14%, rgba(255,160,176,0) 98%)",
				borderRadius: 2,

				boxShadow: "0 3px 10px rgba(0, 0, 0, 0.2)",
				width: "100%",
				height: "100%",
				minHeight: 250,
			}}
		>
			<CardContent sx={{ display: "flex", flexDirection: "column" }}>
				<Typography color="text.secondary" gutterBottom>
					{title}
				</Typography>
				<Typography variant="h5" component="div">
					{totalSales.toLocaleString()}
				</Typography>
				<ResponsiveContainer aspect={1.13 / 1}>
					<PieChart>
						<Pie
							data={data}
							fill="#8884d8"
							outerRadius="50%"
							dataKey="value"
							labelLine={false}
							label={(entry) => entry.name}
						>
							{data.map((entry, index) => (
								<Cell
									key={`cell-${index}`}
									fill={COLORS[index % COLORS.length]}
								/>
							))}
						</Pie>
						<Tooltip />
					</PieChart>
				</ResponsiveContainer>
			</CardContent>
		</Card>
	);
};

export default BiggerInfoCard;
