import React, { useState } from "react";
import { useForm } from "react-hook-form";
import {
	Button,
	Typography,
	Container,
	Grid,
	useMediaQuery,
	Select,
	FormControl,
	InputLabel,
	Menu,
	MenuItem,
	CircularProgress,
} from "@mui/material";
import { Input } from "../../../components";
import LocationInput from "../../../components/form/LocationInput";
import { useNavigate } from "react-router-dom";
import { signupComercio } from "../../../../store/reducers/authReducer";
import { useDispatch, useSelector } from "react-redux";

const StepTwoComercio = ({ nextStep, prevStep, setCompanySignUp }) => {
	const [coordinates, setCoordinates] = useState({});
	const { control, handleSubmit } = useForm();
	const navigate = useNavigate();
	const { userId } = useSelector((state) => state.auth);
	const dispatch = useDispatch();
	const [errorMessage, setErrorMessage] = useState(null);
	const [loading, setLoading] = useState(false);
	const isMobile = useMediaQuery((theme) => theme.breakpoints.down("tablet"));

	const industryOptions = [
		{ value: "turismo", label: "Turismo" },
		{ value: "gastronomia", label: "Gatsronomía" },
		{ value: "salud", label: "Salud" },
		{ value: "educacion", label: "Educación" },
		{ value: "combustible", label: "Combustible" },
		{ value: "hospitalidad", label: "Hospitalidad" },
	];

	const onSubmit = async (data) => {
		try {
			setLoading(true);
			const comercioData = await {
				...data,
			};
			const response = await dispatch(signupComercio({ comercioData, userId }));
			const actionRes = await signupComercio.fulfilled.match(response);
			if (actionRes) {
				nextStep();
			} else {
				setErrorMessage(actionRes.payload);
			}
		} catch (err) {
			console.error(err);
		} finally {
			setLoading(false);
		}
	};

	return (
		<Container
			sx={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				justifyContent: "center",
				gap: "2rem",
			}}
		>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Grid container justifyContent="center" spacing={2}>
					<Grid item>
						<Input
							width="15rem"
							title="Nombre del Comercio"
							type="text"
							name="comercioName"
							control={control}
						/>
					</Grid>
					<Grid item>
						<Input
							width="15rem"
							title="RUC"
							type="text"
							name="ruc"
							control={control}
						/>
					</Grid>
					<Grid item>
						<FormControl fullWidth>
							<InputLabel>Sector o Industria</InputLabel>
							<Select
								label="Sector o Industria"
								name="industry"
								defaultValue=""
								control={control}
								renderValue={(value) =>
									industryOptions.find((option) => option.value === value)
										?.label || ""
								}
								sx={{ width: "15rem" }}
							>
								{industryOptions.map((option) => (
									<MenuItem key={option.value} value={option.value}>
										{option.label}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Grid>
					<Grid item>
						<Input
							width="15rem"
							title="Nombre del Dueño"
							type="text"
							name="ceoName"
							control={control}
						/>
					</Grid>
					<Grid item>
						<Input
							width="15rem"
							title="Email del Comercio"
							type="email"
							name="comercioEmail"
							control={control}
						/>
					</Grid>
					<Grid item>
						<Input
							width="15rem"
							title="Telefono del Comercio "
							type="tel"
							name="comercioPhone"
							control={control}
						/>
					</Grid>
					{/* <Grid item justifySelf="start">
						<LocationInput
							width="15rem"
							setCoordinates={setCoordinates}
							name="address"
						/>
					</Grid> */}
				</Grid>
				<Container
					sx={{
						display: "flex",
						flexDirection: isMobile ? "column" : "row",
						alignItems: "center",
						justifyContent: "center",
						gap: "2rem",
						marginTop: "2rem",
					}}
				>
					<Button
						variant="contained"
						color="primary"
						onClick={() => navigate("/signup")}
					>
						Previous
					</Button>
					<Button type="submit" variant="contained" color="primary">
						{loading ? <CircularProgress size="md" color="primary" /> : "Next"}
					</Button>
				</Container>
				{errorMessage && <Typography color="error">{errorMessage}</Typography>}
			</form>
		</Container>
	);
};

export default StepTwoComercio;
