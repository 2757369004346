import React from "react";
import { Box, Container, Typography, Button } from "@mui/material";
import { useTheme } from "@mui/system";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import { useSelector } from "react-redux";

const ErrorPage = ({
	errorCode = "400",
	errorMessage = "An Error Occured",
	button = "Go Back",
}) => {
	const theme = useTheme();
	const navigate = useNavigate(); // Initialize useNavigate
	const { companyId, data } = useSelector((state) => state.user);

	return (
		<Box
			sx={{
				backgroundColor: theme.palette.primary.main,
				color: theme.palette.primary.contrastText,
				minHeight: "100vh",
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				textAlign: "center",
			}}
		>
			<Container maxWidth="sm">
				<Typography
					variant="h1"
					sx={{ fontSize: "5rem", marginBottom: "1rem" }}
				>
					{errorCode}
				</Typography>
				<Typography variant="h5" sx={{ marginBottom: "2rem" }}>
					{errorMessage}
				</Typography>
				<Button variant="contained" onClick={() => navigate(-1)}>
					{button}
				</Button>
			</Container>
		</Box>
	);
};

export default ErrorPage;
