import React, { useState } from "react";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import {
	Box,
	Typography,
	// useMediaQuery,
	IconButton,
	useTheme,
	Avatar,
	Divider,
} from "@mui/material";
import { Sidebar, Menu, MenuItem, sidebarClasses } from "react-pro-sidebar";
import { Item } from "../../components";
import { tokens } from "../../../theme";
import { useSelector } from "react-redux";
import LogoutIcon from "@mui/icons-material/Logout";
import { setLogoutState } from "../../../store/reducers/authReducer";
import { useDispatch } from "react-redux";
import { clearUserData } from "../../../store/reducers/userReducer";
import { signOut } from "firebase/auth";
import { auth } from "../../../config/firebase";
import { useNavigate } from "react-router-dom";

function SidebarDesktop({ links }) {
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [isCollapsed, setIsCollapsed] = useState(true);
	const [selected, setSelected] = useState("");
	const { linksCompany, linksComercio, linksAdmin } = links;
	const { data, company, companyId } = useSelector((state) => state.user);

	const handleLogout = async () => {
		signOut(auth)
			.then(() => {
				dispatch(setLogoutState());
				dispatch(clearUserData());
			})
			.finally(() => {
				navigate("/login");
			});
	};

	return (
		<Box>
			{data.worksAt && (
				<>
					{!isCollapsed && (
						<div
							style={{
								position: "fixed",
								top: 0,
								left: 0,
								width: "100%",
								height: "100vh",
								background:
									"linear-gradient(45deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0.8) 30%, rgba(255, 255, 255, 0.5) 60%, rgba(255, 255, 255, 0) 100%), linear-gradient(135deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0.5) 30%, rgba(255, 255, 255, 0.5) 60%, rgba(255, 255, 255, 0.2) 100%)",
								opacity: 0.3,
								zIndex: 10,
							}}
						/>
					)}

					{/* Sidebar */}
					<Sidebar
						rootStyles={{
							[`.${sidebarClasses.container}`]: {
								background:
									"linear-gradient(45deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0.8) 30%, rgba(255, 255, 255, 0.5) 60%, rgba(255, 255, 255, 0) 100%), linear-gradient(135deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0.5) 30%, rgba(255, 255, 255, 0.5) 60%, rgba(255, 255, 255, 0.2) 100%)",
								boxShadow: "0 0 2px 1px rgba(0,0,0,0.2)",
								height: "100vh",
								width: isCollapsed ? "80px" : "250px",
								position: "fixed",
								top: 0,
								left: 0,
								zIndex: 10,
								transition: "width 0.3s ease-in-out",
							},
						}}
						collapsed={isCollapsed}
					>
						<Menu iconShape="square">
							<MenuItem
								onClick={() => setIsCollapsed(!isCollapsed)}
								icon={
									isCollapsed ? (
										<img
											src="/assets/logon.png"
											alt="logo nexo"
											width={"50px"}
											height="70px"
											style={{ objectFit: "cover" }}
											onClick={() => setIsCollapsed(!isCollapsed)}
										/>
									) : undefined
								}
								style={{
									margin: "10px 0 20px 0",
									color: colors.grey[100],
								}}
							>
								{!isCollapsed && (
									<Box
										display="flex"
										justifyContent="center"
										alignItems="center"
									>
										<img
											src="/assets/logon.png"
											alt="logo nexo"
											width={"75px"}
											height="100px"
											style={{ objectFit: "cover" }}
											onClick={() => setIsCollapsed(!isCollapsed)}
										/>
									</Box>
								)}
							</MenuItem>
							{!isCollapsed && (
								<Box mb="25px">
									<Box
										display="flex"
										justifyContent="center"
										alignItems="center"
										style={{
											cursor: "pointer",
											borderRadius: "50%",
											height: "10rem",
											width: "auto",
											padding: "1rem",
										}}
									>
										{company.logoUrl ? (
											<img
												src={company.logoUrl} // Replace with your image URL
												alt="Profile"
												style={{
													height: "100%",
													border: "1px #000 solid",
													objectFit: "cover",
													borderRadius: "50%",
												}}
											/>
										) : (
											<Avatar sx={{ height: "5rem", width: "5rem" }} />
										)}
									</Box>
									<Box textAlign="center">
										<Typography
											variant="h2"
											color={colors.grey[100]}
											fontWeight="bold"
											sx={{ margin: "10px 0 0 0" }}
										>
											{data.firstName} {data.lastName}
										</Typography>
										<Typography variant="h5" color={colors.redAccent[500]}>
											Admin
										</Typography>
									</Box>
								</Box>
							)}
							<Box paddingLeft={isCollapsed ? undefined : ""}>
								<Menu
									menuItemStyles={{
										button: {
											[`&.hover`]: {
												backgroundColor: colors.redAccent[500],
												color: colors.grey[200],
											},
											[`&.active`]: {
												backgroundColor: colors.redAccent[500],
												color: colors.grey[200],
											},
										},
										label: ({ active }) => ({
											color: active ? colors.redAccent[500] : colors.grey[200],
											fontWeight: active ? "700" : "400",
										}),
										icon: ({ active }) => ({
											color: active ? colors.redAccent[500] : colors.grey[200],
											fontWeight: active ? "700" : "400",
										}),
									}}
								>
									{(data.worksAt.type === "admin"
										? linksAdmin
										: data.worksAt.type === "comercio"
											? linksComercio
											: linksCompany
									).map((link, index) => {
										return (
											<span onClick={() => setIsCollapsed(true)} key={index}>
												{!isCollapsed ? (
													<Item
														name={link.name}
														path={
															link.name === "Employees" ||
															link.name === "Dashboard"
																? `${link.path}/${companyId}`
																: link.path
														}
														icon={link.icon}
														selected={selected}
														setSelected={setSelected}
													/>
												) : (
													<Item
														path={
															link.name === "Employees" ||
															link.name === "Dashboard"
																? `${link.path}/${companyId}`
																: link.path
														}
														icon={link.icon}
														selected={selected}
														name={link.name}
														setSelected={setSelected}
													/>
												)}
											</span>
										);
									})}
									<MenuItem
										sx={{
											color: colors.grey[200],
										}}
										icon={<LogoutIcon />}
										onClick={handleLogout}
									>
										<Typography>Logout</Typography>
									</MenuItem>
									<Divider sx={{ marginTop: "2rem" }} />
								</Menu>
							</Box>
						</Menu>
					</Sidebar>
				</>
			)}
		</Box>
	);
}

export default SidebarDesktop;
