import React, { useState } from "react";
import { Grid, TextField } from "@mui/material";
import Select from "react-select";
import { Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { updateCompany } from "../../../store/reducers/userReducer";
import CreatableSelect from "react-select/creatable";

const Input = ({
	title,
	type,
	name,
	control,
	value,
	width = "10rem",
	label,
	validation,
	rules,
	placeholder,
}) => (
	<Grid item phone={6} tablet={8} desktop={6}>
		<Controller
			name={name}
			control={control}
			defaultValue={value ? value : ""}
			render={({ field }) => (
				<TextField
					autoComplete={name === "password" ? "current-password" : ""}
					placeholder={placeholder}
					label={title}
					type={type}
					sx={{ width: width }}
					{...field}
				/>
			)}
			rules={rules}
		/>
	</Grid>
);

export const SelectInputField = ({ name, control, options }) => {
	const formOptions = options;

	return (
		<Grid item phone={6} tablet={8} desktop={6}>
			{formOptions ? (
				<Controller
					name={name}
					control={control}
					render={({ field }) => (
						<Select
							options={formOptions}
							value={formOptions.find((c) => c.label === field.value)}
							onChange={(val) => {
								field.onChange(val.label);
							}}
							styles={{
								control: (base, state) => ({
									...base,
									color: "#000",
									padding: "0.5rem",
									alignItems: "center",
									backgroundColor: "#F6F6F5",
									borderColor: "#6F7E8C",
								}),
								option: (baseStyles, state) => ({
									...baseStyles,
									color: "#000",
								}),
							}}
						/>
					)}
					rules={{ required: true }}
				/>
			) : (
				<p>No Form </p>
			)}
		</Grid>
	);
};

export const SelectCreateField = ({ name, control }) => {
	const [isLoading, setIsLoading] = useState(false);
	const dispatch = useDispatch();
	const { company, companyId } = useSelector((state) => state.user);
	const [options, setOptions] = useState([...company.sectors]);

	const handleCreate = (inputValue) => {
		setIsLoading(true);
		const newOption = { label: inputValue, value: inputValue.toLowerCase() };

		// Create a new allocationData object with initial values
		const allocationData = {};
		company.sectors.forEach((sector) => {
			allocationData[sector.value] = 0; // Initialize to 0
		});

		setOptions((prevState) =>
			prevState ? [...prevState, newOption] : [newOption]
		);

		const updatedCompany = {
			...company,
			sectors: options ? [...options, newOption] : [newOption],
			allocationData: allocationData, // Set the allocationData
		};

		setTimeout(() => {
			setIsLoading(false);

			dispatch(
				updateCompany({
					companyId,
					newData: updatedCompany,
				})
			);
		}, 1000);
	};

	return (
		<Grid item phone={6} tablet={8} desktop={6}>
			<Controller
				name={name}
				control={control}
				render={({ field }) => (
					<CreatableSelect
						isClearable
						isDisabled={isLoading}
						isLoading={isLoading}
						onChange={(newValue) => field.onChange(newValue.label)}
						onCreateOption={handleCreate}
						options={company.sectors}
						value={company.sectors.find((c) => c.label === field.value)}
						styles={{
							control: (base, state) => ({
								...base,
								color: "#000",
								padding: "0.5rem",
								alignItems: "center",
								backgroundColor: "#F6F6F5",
								borderColor: "#6F7E8C",
							}),
							option: (baseStyles, state) => ({
								...baseStyles,
								color: "#000",
							}),
						}}
					/>
				)}
				rules={{ required: true }}
			/>
		</Grid>
	);
};

export default Input;
