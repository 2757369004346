import {
	Box,
	FormControl,
	FormControlLabel,
	FormLabel,
	Radio,
	RadioGroup,
} from "@mui/material";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";

const Test = () => {
	const handleChange = (e) => {
		const s = JSON.parse(e.target.value);
	};

	return (
		<Box mt="3rem">
			<FormControl component="fieldset">
				<FormLabel id="demo-radio-buttons-group-label">Gender</FormLabel>
				<RadioGroup
					aria-labelledby="demo-radio-buttons-group-label"
					name="radio-buttons-group"
					onChange={handleChange}
				>
					<FormControlLabel
						control={<Radio data-description="female" />}
						value={JSON.stringify({ label: 0 })}
						label="Female"
					/>
					<FormControlLabel
						value="male"
						onChange={handleChange}
						control={
							<Radio
								inputProps={{ "aria-label": { label: 0 } }}
								data-descripcion="female"
							/>
						}
						label="Male"
					/>
					<FormControlLabel
						value="other"
						onChange={handleChange}
						control={
							<Radio
								inputProps={{ "aria-label": { label: 0 } }}
								data-descripcion="female"
							/>
						}
						label="Other"
					/>
				</RadioGroup>
			</FormControl>
		</Box>
	);
};

export default Test;
