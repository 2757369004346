import React, { useState, useEffect } from "react";
import {
	Container,
	Box,
	TextField,
	Button,
	Autocomplete,
	Select,
	MenuItem,
	Typography,
} from "@mui/material";
import { db } from "../../config/firebase";
import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";

const SettingsAdmin = () => {
	const [selectedCategory, setSelectedCategory] = useState("");
	const [categories, setCategories] = useState({});
	const [subCategoryInput, setSubCategoryInput] = useState("");
	const [subCategories, setSubCategories] = useState([]);
	const [subCategoriesOptions, setSubCategoriesOptions] = useState([]);
	const [errorMessage, setErrorMessage] = useState("");
	useEffect(() => {
		const fetchCategories = async () => {
			const docRef = doc(db, "AppSettings", "InterfaceElements");
			const docSnap = await getDoc(docRef);
			if (docSnap.exists()) {
				setCategories(docSnap.data().categories || {});
				setSubCategories(docSnap.data().subcategories || {});
			} else {
				console.log("No such document!");
			}
		};

		fetchCategories();
	}, []);

	useEffect(() => {
		if (selectedCategory) {
			setSubCategoriesOptions(
				subCategories[parseFloat(selectedCategory)] || {}
			);
		}
	}, [selectedCategory, categories, subCategories]);

	const handleCategoryChange = (event) => {
		setSelectedCategory(event.target.value);
	};

	const handleSubCategoryChange = (_, newValue) => {
		setSubCategoryInput(newValue);
	};

	const generateHexColor = () =>
		"#" + Math.floor(Math.random() * 16777215).toString(16);

	const handleAddSubCategory = async () => {
		if (!subCategoryInput || !selectedCategory) return;

		const found = Object.values(subCategoriesOptions).some(
			(option) => option.nombre.toLowerCase() === subCategoryInput.toLowerCase()
		);
		if (found) {
			setErrorMessage("Subcategory already exists."); // Set error message
			return;
		} else {
			setErrorMessage(""); // Reset error message if no duplicate is found
		}

		const hexColor = generateHexColor();
		const newSubCategoryId =
			Object.keys(subCategoriesOptions).length.toString();
		const newSubCategory = {
			nombre: subCategoryInput,
			color: hexColor,
			id: newSubCategoryId,
		};

		// Update Firestore with the new subcategory under the selected category
		await updateDoc(doc(db, "AppSettings", "InterfaceElements"), {
			[`subcategories.${selectedCategory}.${newSubCategoryId}`]: newSubCategory,
		});

		setSubCategoryInput("");
		// Refresh subcategories to reflect the update
		setSubCategoriesOptions({
			...subCategoriesOptions,
			[newSubCategoryId]: newSubCategory,
		});
	};

	return (
		<Container sx={{ margin: "5rem" }}>
			<Box sx={{ mb: 2 }}>
				<Select
					fullWidth
					value={selectedCategory}
					onChange={handleCategoryChange}
					displayEmpty
				>
					<MenuItem value="">
						<em>None</em>
					</MenuItem>
					{Object.keys(categories).map((key) => (
						<MenuItem key={key} value={key}>
							{categories[key]?.nombre || "Unnamed Category"}
						</MenuItem>
					))}
				</Select>
			</Box>
			{!selectedCategory && (
				<Typography color="error">Porfavor seleccione una categoria</Typography>
			)}
			<Box sx={{ mb: 2 }}>
				<Autocomplete
					freeSolo
					options={Object.keys(subCategoriesOptions).map(
						(key) => subCategoriesOptions[key]?.nombre
					)}
					value={subCategoryInput}
					onChange={handleSubCategoryChange}
					renderInput={(params) => (
						<TextField {...params} label="Subcategory" />
					)}
					disabled={!selectedCategory}
				/>
			</Box>
			<Button variant="contained" onClick={handleAddSubCategory}>
				Add Subcategory
			</Button>
			{errorMessage && <Typography color="error">{errorMessage}</Typography>}
		</Container>
	);
};

export default SettingsAdmin;
